import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Gender, currency, formatDash, formatDate, formatDateUpdate, generateBillNo, getStaffLogin } from "../../utils";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { ParentPath } from "../../routes/Path";
import { BiPrinter } from "react-icons/bi";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { BsFileEarmarkExcel } from "react-icons/bs";
import Logo from "../../assets/images/ans-logo-red.svg";
import { useLazyQuery } from "@apollo/client";
import Schema from "./Schema";
import _ from "lodash";
import { Image, Modal } from "react-bootstrap";
import SignaturePayment from "./signaturePayment/signatureSummary";
import HRSignaturePayment from "./signaturePayment/HRpayment";
import HikingSignaturePayment from "./signaturePayment/signatureDeductionPayment";
import { url_upload_Profile } from "../../configs/Environment";

export default function ExtraPaymentTwo() {
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const consolidateID = params.get("ConsolidateID");
  const [extraItems, setExtraItems] = useState<any[]>();
  const [FetchData, { data }] = useLazyQuery(Schema.EXTRA_PAYMENT_CONSOLIDATE, { fetchPolicy: "cache-and-network" });

  useEffect(() => {
    FetchData({
      variables: {
        where: {
          exTraCheck: Number(consolidateID) ? Number(consolidateID) : undefined,
        },
      },
    });
  }, [FetchData, consolidateID]);
  useEffect(() => {
    setExtraItems(data?.extraPayments?.data || []);
  }, [data]);

  const NoData = data?.extraPayments?.data[0]?.exTraAmount;
  const TotalExtraPayments = _.sumBy(extraItems, "exTraAmount");

  return (
    <React.Fragment>
      <div className="page-header">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">ໜ້າຫຼັກ</li>
          <li className="breadcrumb-item">ລາຍງານຂໍ້ມູນການສະຫລຸບທັງໝົດ</li>
          <li className="breadcrumb-item">ລາຍງານຂໍ້ມູນເພີ່ມເງິນທີ່ສະຫລຸບແລ້ວ</li>
        </ol>
      </div>
      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col-sm-4">
              <div className="flex-control-group">
                <button className="btn btn-primary" onClick={() => navigate(`${ParentPath.summarizeDestinationVehicleTwo}`)}>
                  <i className="fas fa-arrow-left"></i> ກັບຄືນ
                </button>
                <ExportExtraPayments />
              </div>
            </div>
            <div className="col-sm-8">
              <h4 style={{ marginInlineStart: "8%" }}>ລາຍງານຂໍ້ມູນເພີ່ມເງິນທີ່ສະຫລຸບແລ້ວ</h4>
            </div>
          </div>
        </div>
        <div className="card-body">
          <h4 className="mt-3">ລາຍງານຂໍ້ມູນເພີ່ມເງິນທີ່ສະຫລຸບແລ້ວ</h4>
          <div className="container-print">
            <table className="table" border={1}>
              <thead className="text-white">
                <tr>
                  <th>ລຳດັບ</th>
                  <th>ຊື່ແລະນາມສະກຸນ</th>
                  <th>ສະຖານະການຊຳລະ</th>
                  <th>ສ້າງໂດຍ</th>
                  <th>ຈຳນວນເງິນ(ກີບ)</th>
                  <th>ສະຖານະ</th>
                  <th>ວັນທີເພີ່ມ</th>
                  <th>ວັນທີ່ສ້າງຂື້ນ</th>
                  <th>ໝາຍເຫດ</th>
                </tr>
              </thead>
              <tbody>
                {NoData > 0 ? (
                  <>
                    {extraItems?.map((value, index) => (
                      <>
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            {value?.extraDriverID?.first_name || "---"} {value?.extraDriverID?.last_name || "----"}
                          </td>
                          <td className={value?.exTraStatus === "PAID" ? "text-success" : "text-danger"}>
                            {value?.exTraStatus === "PAID" ? "ຊຳລະແລ້ວ" : "ຍັງບໍ່ທັນຊຳລະ"}
                          </td>
                          <td>
                            {value?.exTraCreatedBy?.gender ? Gender(value?.exTraCreatedBy?.gender) : ""}&nbsp;{value?.exTraCreatedBy?.first_name}{" "}
                            {value?.exTraCreatedBy?.last_name}
                          </td>
                          <td>{currency(value?.exTraAmount || 0)} ກີບ</td>
                          <td className={value?.isExtra === 1 ? "text-success" : "text-danger"}>
                            {value?.isExtra === 1 ? "ເປັນເງິນພິເສດ" : "ເປັນເງິນທົ່ວໄປ"}
                          </td>
                          <td>{value?.exTraDate ? formatDate(value?.exTraDate) : "-----"}</td>
                          <td className="text-nowrap ">{value?.exTraCreatedAt ? formatDash(value?.exTraCreatedAt) : "-----"}</td>
                          <td>{value?.exTraNote || "---"}</td>
                        </tr>
                      </>
                    ))}
                    <tr>
                      <td colSpan={4} className="text-center">
                        ຍອດລວມທັງໝົດ
                      </td>
                      <td colSpan={5}>{currency(TotalExtraPayments)} ກີບ</td>
                    </tr>
                  </>
                ) : (
                  <tr>
                    <td colSpan={9} className="text-center text-danger">
                      ບໍ່ມີຂໍ້ມູນ
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

function ExportExtraPayments() {
  const [show, setShow] = useState(false);
  const elmRef = useRef<HTMLDivElement>(null);
  let today = moment().format("YYYY-MM-DD");
  let time = moment().format("HH:mm:ss");
  const getLogin = getStaffLogin();
  const handlePrint = useReactToPrint({
    content: () => elmRef.current,
  });
  const [params, setParams] = useSearchParams();
  const consolidateID = params.get("ConsolidateID");
  const getData: any = params.get("data");
  const Items = JSON.parse(getData);
  const [extraItems, setExtraItems] = useState<any[]>();
  let bilNoToday = moment().format("YYYYMMDD");
  const getGenerate = generateBillNo("");
  const [searchParams] = useSearchParams();
  const _startConsolidate = searchParams.get("_startConsolidate");
  const _endConsolidate = searchParams.get("_endConsolidate");

  const [signatureSummary, setSignatureSummary] = useState<any>();
  const [signatureHiking, setSignatureHiking] = useState<any>();
  const [signatureHr, setSignatureHr] = useState<any>();
  const [FetchData, { data, loading }] = useLazyQuery(Schema.EXTRA_PAYMENT_CONSOLIDATE, { fetchPolicy: "cache-and-network" });
  const [querySignatureSummary, { data: imageSignatureSummary }] = useLazyQuery(Schema.QUERY_SIGNATURE, { fetchPolicy: "cache-and-network" });
  const [querySignatureHiking, { data: imageSignatureHiking }] = useLazyQuery(Schema.QUERY_SIGNATURE, { fetchPolicy: "cache-and-network" });
  const [querySignatureHr, { data: imageSignatureHr }] = useLazyQuery(Schema.QUERY_SIGNATURE, { fetchPolicy: "cache-and-network" });

  const NewNoID = imageSignatureSummary?.signatures?.data[0]?.noID;
  const SignatureAdmin = imageSignatureHiking?.signatures?.data[0]?.signatureTwo;
  const SignatureManager = imageSignatureHr?.signatures?.data[0]?.signatureTwo;
  const SignatureSummary = imageSignatureSummary?.signatures?.data[0]?.signatureTwo;

  const vehicle = Items?.consolidateVehicleID?._id;

  useEffect(() => {
    if (vehicle === undefined) return;
    querySignatureSummary({
      variables: {
        where: {
          dateBetween: [_startConsolidate, _endConsolidate],
          vehicleID: Number(vehicle) ? Number(vehicle) : undefined,
          driverStatus: "PAYMENT",
        },
        limit: 1,
      },
    });
  }, [show, _startConsolidate, _endConsolidate, vehicle, querySignatureSummary]);

  useEffect(() => {
    setSignatureSummary(imageSignatureSummary?.signatures?.data || []);
  }, [imageSignatureSummary]);

  useEffect(() => {
    if (vehicle === undefined) return;
    querySignatureHiking({
      variables: {
        where: {
          dateBetween: [_startConsolidate, _endConsolidate],
          vehicleID: Number(vehicle) ? Number(vehicle) : undefined,
          driverStatus: "HIKING_PAYMENT",
        },
        limit: 1,
      },
    });
  }, [show, _startConsolidate, _endConsolidate, vehicle, querySignatureHiking]);

  useEffect(() => {
    setSignatureHiking(imageSignatureHiking?.signatures?.data || []);
  }, [imageSignatureHiking]);

  useEffect(() => {
    if (vehicle === undefined) return;
    querySignatureHr({
      variables: {
        where: {
          dateBetween: [_startConsolidate, _endConsolidate],
          vehicleID: Number(vehicle) ? Number(vehicle) : undefined,
          driverStatus: "HR_PAYMENT",
        },
        limit: 1,
      },
    });
  }, [querySignatureHr, show, _startConsolidate, _endConsolidate, vehicle]);

  useEffect(() => {
    setSignatureHr(imageSignatureHr?.signatures?.data || []);
  }, [imageSignatureHr]);

  useEffect(() => {
    if (!show) return;
    FetchData({
      variables: {
        where: {
          exTraCheck: Number(consolidateID) ? Number(consolidateID) : undefined,
        },
      },
    });
  }, [FetchData, consolidateID, show]);
  useEffect(() => {
    setExtraItems(data?.extraPayments?.data || []);
  }, [data]);

  const NoData = data?.extraPayments?.data[0]?.exTraAmount;
  const TotalExtraPayments = _.sumBy(extraItems, "exTraAmount");

  return (
    <>
      <button type="button" className="btn btn-secondary" onClick={() => setShow(true)} disabled={loading}>
        <i className="fas fa-print"></i>ພິມລາຍງານ
      </button>
      <Modal show={show} dialogClassName="modal-width-95" animation={false}>
        <Modal.Header onHide={() => setShow(false)} closeButton={!loading}>
          <h5>ລາຍງານຂໍ້ມູນເພີ່ມເງິນທີ່ສະຫລຸບແລ້ວ</h5>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-sm-4">
              <div className="flex-control-group">
                <button onClick={handlePrint} type="button" className="btn btn-dark ml-3 me-2">
                  <BiPrinter /> ພິມລາຍງານ
                </button>
                <DownloadTableExcel filename="ຂໍ້ມູນພະນັກງານ" sheet="users" currentTableRef={elmRef.current}>
                  <button className="btn btn-success">
                    <BsFileEarmarkExcel /> Export excel{" "}
                  </button>
                </DownloadTableExcel>
              </div>
            </div>
          </div>
          <div ref={elmRef} id="table-to-xls" className="container-print mt-5" style={{ fontFamily: "phetsarath OT" }}>
            <div>
              <img style={{ width: 150 }} src={Logo} alt="" />
            </div>
            &nbsp;
            <div>
              <div>
                <div>ບໍລິສັດ ອານຸສິດ ໂລຈິສຕິກ ຈຳກັດ.</div>
                <div>ບ້ານ ໂພນສະອາດ, ເມືອງ ຈັນທະບູລີ, ນະຄອນຫຼວງວຽງຈັນ</div>
                {NewNoID ? (
                  <>
                    <div className="text-end">ເລກທີ່: {NewNoID}</div>
                  </>
                ) : (
                  <>
                    <div className="text-end">
                      ເລກທີ່: ANS-E{bilNoToday}
                      {getGenerate}
                    </div>
                  </>
                )}
                <div className="text-end">
                  ພິມໂດຍ: {getLogin?.first_name} {getLogin?.last_name}
                </div>
                <div className="text-end">
                  ວັນທີພິມ: {time} , {today}
                </div>
              </div>
            </div>
            <h4 className="mt-3">ລາຍງານການຄົ້ນຫາຂໍ້ມູນເພີ່ມເງິນ ທີ່ສະຫລຸບ ແລ້ວ ໃນລາຍການສະຫລຸບຖ້ຽວລົດ</h4>
            <table className="table" border={1}>
              <thead>
                <tr>
                  <th className="text-nowrap">ລາຍການສະຫລຸບວັນທີ</th>
                  <th className="text-nowrap">ຖ້ຽວລົດວັນທີ</th>
                  <th className="text-nowrap"> ຊື່ໂຊເຟີລົດ</th>
                  <th className="text-nowrap">ບໍລິສັດ</th>
                  <th className="text-nowrap"> ການຊຳລະ</th>
                  <th className="text-nowrap"> ກະອອກລົດ</th>
                  <th className="text-nowrap"> ປະເພດສາຍ</th>
                  <th className="text-nowrap"> ເບີລົດ</th>
                  <th className="text-nowrap"> ຖ້ຽວລົດ</th>
                  <th className="text-nowrap"> ສູນ</th>
                </tr>
              </thead>
              <tbody>
                {NoData > 0 ? (
                  <>
                    <tr>
                      <td className="text-nowrap">
                        {_startConsolidate || "---"} ເຖິງ {_endConsolidate || "---"}
                      </td>
                      <td className="text-nowrap">{Items?.consolidateDate ? formatDateUpdate(Items?.consolidateDate) : "---"}</td>
                      <td className="text-nowrap">
                        <div>
                          {Items?.consolidateDriverID?.first_name} {Items?.consolidateDriverID?.last_name}
                        </div>
                        <br />
                        <div>
                          {Items?.driverTwo?.first_name} {Items?.driverTwo?.last_name}
                        </div>
                      </td>
                      <td className="text-nowrap">{Items?.consolidateDriverID?.stStatus?.cp_name || Items?.driverTwo?.stStatus?.cp_name}</td>
                      <td className="text-nowrap">{Items?.consolidatePaidStatus === "UNPAID" ? "ຍັງບໍ່ຊຳລະ" : "ຊຳລະແລ້ວ"}</td>
                      <td className="text-nowrap">{Items?.isDay === "1" ? "ພາກແລງ" : "ພາກເຊົ້າ"}</td>
                      <td className="text-nowrap">{Items?.checkRoute === "IN" ? "ສາຍໃນ" : Items?.checkRoute === "OUT" ? "ສາຍນອກ" : "ສາຍສຳຮອງ"}</td>
                      <td className="text-nowrap">{Items?.consolidateVehicleID?.vehicleName || ""}</td>
                      <td className="text-nowrap">{Items?.isDif === "TRUE" ? "ຖ້ຽວເສີມ" : "ຖ້ຽວປົກກະຕິ"}</td>
                      <td className="text-nowrap">{Items?.consolidateDriverID?.center?.centerName}</td>
                    </tr>
                  </>
                ) : null}
              </tbody>
            </table>
            &nbsp;&nbsp;
            <h4 className="mt-3">ລາຍງານຂໍ້ມູນເພີ່ມເງິນທີ່ສະຫລຸບແລ້ວ</h4>
            <table className="table" border={1}>
              <thead className="text-white">
                <tr>
                  <th>ລຳດັບ</th>
                  <th>ຊື່ແລະນາມສະກຸນ</th>
                  <th>ສະຖານະການຊຳລະ</th>
                  <th>ສ້າງໂດຍ</th>
                  <th>ຈຳນວນເງິນ(ກີບ)</th>
                  <th>ສະຖານະ</th>
                  <th>ວັນທີເພີ່ມ</th>
                  <th>ວັນທີ່ສ້າງຂື້ນ</th>
                  <th>ໝາຍເຫດ</th>
                </tr>
              </thead>
              <tbody>
                {NoData > 0 ? (
                  <>
                    {extraItems?.map((value, index) => (
                      <>
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            {value?.extraDriverID?.first_name || "---"} {value?.extraDriverID?.last_name || "----"}
                          </td>

                          <td className={value?.exTraStatus === "PAID" ? "text-success" : "text-danger"}>
                            {value?.exTraStatus === "PAID" ? "ຊຳລະແລ້ວ" : "ຍັງບໍ່ທັນຊຳລະ"}
                          </td>
                          <td>
                            {value?.exTraCreatedBy?.gender ? Gender(value?.exTraCreatedBy?.gender) : ""}&nbsp;{value?.exTraCreatedBy?.first_name}{" "}
                            {value?.exTraCreatedBy?.last_name}
                          </td>
                          <td>{currency(value?.exTraAmount || 0)} ກີບ</td>
                          <td className={value?.isExtra === 1 ? "text-success" : "text-danger"}>
                            {value?.isExtra === 1 ? "ເປັນເງິນພິເສດ" : "ເປັນເງິນທົ່ວໄປ"}
                          </td>
                          <td>{value?.exTraDate ? formatDate(value?.exTraDate) : "-----"}</td>
                          <td className="text-nowrap ">{value?.exTraCreatedAt ? formatDash(value?.exTraCreatedAt) : "-----"}</td>
                          <td>{value?.exTraNote || "---"}</td>
                        </tr>
                      </>
                    ))}
                    <tr>
                      <td colSpan={4} className="text-center">
                        ຍອດລວມທັງໝົດ
                      </td>
                      <td colSpan={5}>{currency(TotalExtraPayments)} ກີບ</td>
                    </tr>
                  </>
                ) : (
                  <tr>
                    <td colSpan={9} className="text-center text-danger">
                      ບໍ່ມີຂໍ້ມູນ
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="row" style={{ height: 200 }}>
              <div className="col-sm-4 mt-4 text-center">
                <h6>ຫົວໜ້າພະແນກຈັດຈ້າງ</h6>
              </div>
              <div className="col-sm-4  mt-4 text-center">
                <h6>ຫົວໜ້າພະແນກບຸກຄະລາກອນ</h6>
              </div>
              <div className="col-sm-4  mt-4 text-center">
                <h6>ຜູ້ສະຫຼຸບ</h6>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4" style={{ marginTop: -180 }}>
                {SignatureAdmin ? (
                  <>
                    <div className="text-center">
                      {signatureHiking?.map((value: any, index: number) => (
                        <>
                          <Image src={url_upload_Profile + value?.signatureTwo} width={280} height={180} />
                        </>
                      ))}
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    <div className="text-center mt-5">
                      <HikingSignaturePayment
                        bilNoToday={bilNoToday}
                        getGenerate={getGenerate}
                        startDate={_startConsolidate}
                        endDate={_endConsolidate}
                        vehicle={Items?.consolidateVehicleID?._id}
                        NewNoID={NewNoID}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="col-sm-4" style={{ marginTop: -180 }}>
                {SignatureManager ? (
                  <>
                    <div className="text-center">
                      {signatureHr?.map((value: any, index: number) => (
                        <>
                          <Image src={url_upload_Profile + value?.signatureTwo} width={280} height={180} />
                        </>
                      ))}
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    <div className="text-center mt-5">
                      <HRSignaturePayment
                        bilNoToday={bilNoToday}
                        getGenerate={getGenerate}
                        NewNoID={NewNoID}
                        startDate={_startConsolidate}
                        endDate={_endConsolidate}
                        vehicle={Items?.consolidateVehicleID?._id}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="col-sm-4" style={{ marginTop: -180 }}>
                {SignatureSummary ? (
                  <>
                    <div className="text-center">
                      {signatureSummary?.map((value: any, index: number) => (
                        <>
                          <Image src={url_upload_Profile + value?.signatureTwo} width={280} height={180} />
                        </>
                      ))}
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    <div className="text-center mt-5">
                      <SignaturePayment
                        bilNoToday={bilNoToday}
                        getGenerate={getGenerate}
                        startDate={_startConsolidate}
                        endDate={_endConsolidate}
                        vehicle={Items?.consolidateVehicleID?._id}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
