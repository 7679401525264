import { useMutation } from "@apollo/client";
import React from "react";
import Schema from "./Schema";
import {
  MessageConfirms,
  MessageErrors,
  MessageSuccess,
  confirmShow,
  graphQLErrors,
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../../utils";
import { Button, Spinner } from "react-bootstrap";

export const DeleteBack = ({ items, className, onSuccess }: { items: any; className?: string; onSuccess: () => void }) => {
  const [BackData, { loading }] = useMutation(Schema.UPDATE_ITEM_BACK);

  let basicSalaryOneConsol: any = "";
  if (items?.basicSalaryOne > 0) {
    basicSalaryOneConsol = 1;
  } else {
    basicSalaryOneConsol = 0;
  }
  let basicSalaryTwoConsol: any = "";
  if (items?.basicSalaryTwo > 0) {
    basicSalaryTwoConsol = 1;
  } else {
    basicSalaryTwoConsol = 0;
  }

  return (
    <React.Fragment>
      <Button
        style={{ marginTop: 20 }}
        variant="danger"
        className={className}
        disabled={loading}
        onClick={() => {
          if (!loading) {
            confirmShow(MessageConfirms.back, async () => {
              try {
                if (!Number(items?.consolidateID)) {
                  notifyError(MessageErrors.back);
                  return false;
                }
                // Prepare newData outside of the await call
                const newData = {
                  isDay: String(items?.isDay),
                  consolidateVehicleID: Number(items?.consolidateVehicleID?._id),
                  isDif: String(items?.isDif),
                  checkRoute: String(items?.checkRoute),
                  consolidateDriverID: Number(items?.consolidateDriverID?._id),
                  driverTwo: Number(items?.driverTwo?._id),
                  basicSalaryOne: Number(basicSalaryOneConsol),
                  basicSalaryTwo: Number(basicSalaryTwoConsol),
                  route_subline: String(items?.route_subline),
                };
                await BackData({
                  variables: {
                    data: newData,
                    where: {
                      consolidateID: Number(items?.consolidateID),
                    },
                  },
                });
                notifySuccess(MessageSuccess.back);
                onSuccess?.();
              } catch (e) {
                console.error("Caught error:", e);
                const error = graphQLErrors(e);

                if (error === "NO DATA TO CONSOLIDATE") {
                  notifyWarning("ບໍ່ມີຂໍ້ມູນທີຕ້ອງການສະຫລຸບ");
                } else if (error === "consolidateID and consolidateDriverID Not found") {
                  notifyWarning("ບໍ່ພົບລະຫັດທີ່່ສະຫລຸບ ແລະ ລະຫັດໂຊລົດລົດ");
                } else if (error === "deductionPayment and ExtraPayment are not updated") {
                  notifyWarning("ເງິນຫັກ ແລະ ເງິນເພີ່ມອັບເດບບໍ່ສຳເລັດ");
                } else if (error === "ALL processing are not updated") {
                  notifyWarning("ຂະບວນການດຳເນີນເກີດຂໍ້ຜິດພາດໃນລະບົບ");
                } else {
                  notifyError(MessageErrors.back);
                }
              }
            });
          }
        }}
        onDoubleClick={() => {
          return false;
        }}
      >
        {loading ? <Spinner animation="border" variant="white" size="sm" /> : <i className="fas fa-trash" />}
      </Button>
    </React.Fragment>
  );
};

export const ConsolidateDriver = ({
  driverID,
  className,
  onSuccess,
  _startDate,
  _endDate,
  _Route_SubLine,
}: {
  driverID: any;
  className?: string;
  _Route_SubLine: string;
  onSuccess: () => void;
  _startDate: any;
  _endDate: any;
}) => {
  const [summaryDriver, { loading }] = useMutation(Schema.CREATE_CONSOLIDATE);

  return (
    <React.Fragment>
      <Button
        variant="primary"
        className={className}
        disabled={loading}
        onClick={() => {
          if (!loading) {
            confirmShow(MessageConfirms.summaryDriver, async () => {
              try {
                if (!_startDate) {
                  notifyError("ກະລຸນາເລືອກວັນທີກ່ອນ");
                } else if (!_endDate) {
                  notifyError("ກະລຸນາເລືອກວັນທີກ່ອນ");
                } else if (!driverID) {
                  notifyError("ກະລຸນາເລືອກໂຊເຟີ້ລົດກ່ອນ");
                } else {
                  const data = {
                    consolidateDriverID: Number(driverID),
                    startDate: String(_startDate),
                    endDate: String(_endDate),
                    route_subline: String(_Route_SubLine),
                  };

                  await summaryDriver({
                    variables: {
                      data,
                    },
                  });

                  notifySuccess(MessageSuccess.summaryDriver);
                  onSuccess?.();
                }
              } catch (e) {
                const error = graphQLErrors(e);
                if (error.includes("ທ່ານໄດ້ສະຫຼຸບ")) {
                  notifyWarning(error);
                } else if (error === "ທ່ານໄດ້ສະຫຼຸບໄປແລ້ວ") {
                  notifyWarning("ທ່ານໄດ້ສະຫຼຸບໄປແລ້ວ");
                } else {
                  notifyError(MessageErrors.summaryDriver);
                }
              }
            });
          }
        }}
        onDoubleClick={() => {
          return false;
        }}
      >
        {loading ? <Spinner animation="border" variant="white" size="sm" /> : <i className="fas fa-repeat" />}
        &nbsp;
        {loading ? <>ກຳລັງສະຫຼຸບຂໍ້ມູນ..............</> : <>ສະຫຼຸບສະເພາະລາຍຊື່</>}
      </Button>{" "}
    </React.Fragment>
  );
};

// consolidate by center

export const ConsolidateCenter = ({
  centerID,
  className,
  onSuccess,
  _startDate,
  _endDate,
  _Route_SubLine,
}: {
  centerID: any;
  className?: string;
  _Route_SubLine: string;
  onSuccess: () => void;
  _startDate: any;
  _endDate: any;
}) => {
  const [summaryDriver, { loading }] = useMutation(Schema.CREATE_CONSOLIDATE);

  return (
    <React.Fragment>
      <Button
        variant="primary"
        className={className}
        disabled={loading}
        onClick={() => {
          if (!loading) {
            confirmShow(MessageConfirms.summaryCenter, async () => {
              try {
                if (!_startDate) {
                  notifyError("ກະລຸນາເລືອກວັນທີກ່ອນ");
                } else if (!_endDate) {
                  notifyError("ກະລຸນາເລືອກວັນທີກ່ອນ");
                } else if (!centerID) {
                  notifyError("ກະລຸນາເລືອກສູນກ່ອນ");
                } else {
                  await summaryDriver({
                    variables: {
                      data: {
                        centerID: Number(centerID),
                        startDate: String(_startDate),
                        endDate: String(_endDate),
                        route_subline: String(_Route_SubLine),
                      },
                    },
                  });
                  notifySuccess(MessageSuccess.summaryCenter);
                  onSuccess?.();
                }
              } catch (e) {
                const error = graphQLErrors(e);
                if (error.includes("ທ່ານໄດ້ສະຫຼຸບ:")) {
                  notifyWarning(error);
                } else if (error === "NO DATA TO CONSOLIDATE") {
                  notifyWarning("ບໍ່ມີຂໍ້ມູນທີຕ້ອງການສະຫລຸບ");
                } else {
                  notifyError(MessageErrors.summaryCenter);
                }
              }
            });
          }
        }}
        onDoubleClick={() => {
          return false;
        }}
      >
        {loading ? <Spinner animation="border" variant="white" size="sm" /> : <i className="fas fa-repeat" />}&nbsp;
        {loading ? <>ກຳລັງສະຫຼຸບຂໍ້ມູນ..............</> : <>ສະຫຼຸບຕາມສູນ</>}
      </Button>
    </React.Fragment>
  );
};

export const ConsolidateAll = ({
  className,
  onSuccess,
  _startDate,
  _endDate,
  _Route_SubLine,
}: {
  className?: string;
  _Route_SubLine: string;
  onSuccess: () => void;
  _startDate: any;
  _endDate: any;
}) => {
  const [summaryDriver, { loading }] = useMutation(Schema.CREATE_CONSOLIDATE);

  return (
    <React.Fragment>
      <Button
        variant="primary"
        className={className}
        disabled={loading}
        onClick={() => {
          if (!loading) {
            confirmShow(MessageConfirms.summaryCenter, async () => {
              try {
                if (!_startDate) {
                  notifyError("ກະລຸນາເລືອກວັນທີກ່ອນ");
                } else if (!_endDate) {
                  notifyError("ກະລຸນາເລືອກວັນທີກ່ອນ");
                } else {
                  await summaryDriver({
                    variables: {
                      data: {
                        startDate: String(_startDate),
                        endDate: String(_endDate),
                        route_subline: String(_Route_SubLine),
                      },
                    },
                  });
                  notifySuccess(MessageSuccess.summaryCenter);
                  onSuccess?.();
                }
              } catch (e) {
                const error = graphQLErrors(e);
                if (error.includes("ທ່ານໄດ້ສະຫຼຸບ")) {
                  notifyWarning(error);
                } else if (error === "NO DATA TO CONSOLIDATE") {
                  notifyWarning("ບໍ່ມີຂໍ້ມູນທີຕ້ອງການສະຫລຸບ");
                } else {
                  notifyError(MessageErrors.summaryCenter);
                }
              }
            });
          }
        }}
        onDoubleClick={() => {
          return false;
        }}
      >
        {loading ? <Spinner animation="border" variant="white" size="sm" /> : <i className="fas fa-repeat" />}
        &nbsp;
        {loading ? <>ກຳລັງສະຫຼຸບຂໍ້ມູນ..............</> : <>ສະຫຼຸບທັງໝົດ</>}
      </Button>{" "}
    </React.Fragment>
  );
};

// cancel all

export const CancelBackAll = ({ className, onSuccess, _data }: { className?: string; onSuccess: () => void; _data: any }) => {
  const [_CancelBackAll, { loading }] = useMutation(Schema.DELETE_ITEMS_ROUTES_CANCEL_ALL);
  return (
    <React.Fragment>
      <Button
        className="btn btn-success btn-md"
        disabled={loading}
        onClick={() => {
          if (!loading) {
            confirmShow(MessageConfirms.CancelSummary, async () => {
              try {
                await _CancelBackAll({
                  variables: {
                    data: {
                      bonusPayment: 0,
                    },
                    where: {
                      ids: _data?.map((value: any) => value?.consolidateID),
                    },
                  },
                });
                notifySuccess("ຍົກເລິກທັງໝົດສຳເລັດແລ້ວ");
                onSuccess?.();
              } catch (e) {
                const error = graphQLErrors(e);
                if (error === "consolidateID and consolidateDriverID Not found" || error === "ALL processing are not updated") {
                  notifyWarning("ບໍ່ມີຂໍ້ມູນທີຕ້ອງການຍົກເລິກ");
                } else {
                  notifyError("ຍົກເລິກບໍ່ສຳເລັດ");
                }
              }
            });
          }
        }}
        onDoubleClick={() => {
          return false;
        }}
      >
        {loading ? <Spinner animation="border" variant="white" size="sm" /> : <i className="fas fa-check-circle" />}
        {loading ? <>ກຳລັງຍົກເລິກຂໍ້ມູນທັງໝົດ.....</> : <> ຍົກເລິກທັງໝົດ</>}
      </Button>{" "}
    </React.Fragment>
  );
};
