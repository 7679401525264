import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { Gender, RootVariable, checkUserRole, currency, formatDateTime } from "../../../utils";
import { useSearchParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import Schema from "./Schema";
import TableData from "../../../utils/components/TableData";
import AddShippingRoute from "./addShippingRoute";
import UpdateShippingRoute from "./updateShippingRoute";
// import { TbArrowAutofitWidth } from "react-icons/tb";
// import { GiWeight } from "react-icons/gi";
import { Cancel, UpdateRoute, Verified } from "./component";
import SelectDriverRoute from "../../../utils/components/SelectDriverRoute";

export default function ShippingRoute() {
  const [total, setTotal] = useState(0);
  const [items, setItems] = useState<any[]>();
  const [searchValue, setSearchValue] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [numberRow, setNumberRow] = useState(RootVariable.limit);
  const [searchParams , setSearchParams] = useSearchParams();
  const [reloading, setReloading] = useState(false);
  // const [newWidth, setNewWidth] = useState();
  // const [newWeight, seNewtWeight] = useState();
  // const [newStartDate, setNewStartDate] = useState("");
  // const [NewEndDate, setNewEndDate] = useState("");
  const [selectVehicle, setSelectVehicle] = useState<any>();

  //  const [QuerySizeData, { data: SizeData }] = useLazyQuery(Schema.QUERY_SIZE, { fetchPolicy: "cache-and-network" });
  const [fetchData, { data, loading }] = useLazyQuery(Schema.QUERY_SHIPPING_ROUTE, { fetchPolicy: "cache-and-network" });

  useEffect(() => {
    const vehicleID = selectVehicle === 0 ? undefined : Number(selectVehicle);
    fetchData({
      variables: {
        where: {
          vehicleID: vehicleID ? vehicleID : undefined,
          newLocation: "NO",
          isDif: ["0", "1"],
        },
        limit: searchValue ? 0 : numberRow,
        skip: searchValue ? 0 : numberRow * (pageNumber - 1),
      },
    });
    // let shippingRoutesData = data?.shippingRoutes?.data;
    // if (shippingRoutesData && shippingRoutesData.length > 0) {
    //   let vehicleIDs = shippingRoutesData.map((route: any) => route?.vehicleID?._id);
    //   const where = {
    //     vehicleId: vehicleIDs,
    //     startDate: newStartDate,
    //     endDate: NewEndDate,
    //   };
    //   QuerySizeData({
    //     variables: {
    //       where: where,
    //       limit: 100,
    //     },
    //   });
    // }
  }, [ fetchData, pageNumber, numberRow, reloading, searchValue, selectVehicle]);

  useEffect(() => {
    if (data?.shippingRoutes?.data) {
      setItems(data.shippingRoutes.data);
      setTotal(data.shippingRoutes.total);
    }
  }, [data]);

  // useEffect(() => {
  //   if (SizeData?.ReportItemFinalSummary) {
  //     setNewWidth(SizeData.ReportItemFinalSummary.map((item: any) => item.FinalSumWidth));
  //     seNewtWeight(SizeData.ReportItemFinalSummary.map((item: any) => item.FinalWeight));
  //   }
  // }, [SizeData]);

  // useEffect(() => {
  //   const newStartDate = StartOfDay();
  //   const newEndDate = EndOfDayTwo();
  //   setNewStartDate(newStartDate);
  //   setNewEndDate(newEndDate);
  // }, []);

  useEffect(() => {
    const _page = searchParams.get("page");
    const _row = searchParams.get("row");
    const _vehicle = searchParams.get("vehicle");
    const decodeVehicle = _vehicle ? atob(_vehicle) : undefined;
    setSelectVehicle(decodeVehicle ? decodeVehicle : undefined);
    setPageNumber(_page ? parseInt(_page) : 1);
    setNumberRow(_row ? parseInt(_row) : RootVariable.limit);
  }, [searchParams]);
  return (
    <>
      <div className="page-header">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">ໜ້າຫຼັກ</li>
          <li className="breadcrumb-item">ຈັດການຖ້ຽວລົດ</li>
        </ol>
      </div>
      &nbsp;&nbsp;
      <div className="card">
        <div className="card-header border-bottom">
          <div className="row">
            <div className="col-sm-8">
              <h4>ລາຍງານການຈັດຖ້ຽວລົດ</h4>
            </div>
            <div className="col-sm-4">
              <div style={{ float: "right" }}>
                {checkUserRole(["SUPER_ADMIN", "ADMIN", "AUDITOR"]) ? <AddShippingRoute onSuccess={() => setReloading(!reloading)} /> : null}
              </div>
            </div>
          </div>
        </div>
        <div className="card-body">
        <div className="col-sm-2">
            <label htmlFor="#">ເລືອກຕາມເບີລົດ</label>
            <SelectDriverRoute
              onChange={(option: any) => {
                searchParams.set("vehicle", btoa(option.value));
                setSearchParams(searchParams);
              }}
            />
          </div>
          <TableData
            total={total}
            loading={loading}
            pageNumber={pageNumber}
            numberRow={numberRow}
            onSearchText={(val) => {
              setSearchValue(val || "");
            }}
            header={
              <tr>
                <th className="text-nowrap text-center">ລຳດັບ</th>
                <th className="text-nowrap text-center">ໂຊເຟີ້(1)</th>
                <th className="text-nowrap text-center">ໂຊເຟີ້(2)</th>
                <th className="text-nowrap text-center">ເບີລົດ</th>
                {/* <th className="text-nowrap text-center">ຂະໜາດແລະນ້ຳໜັກ/ມື້</th> */}
                <th className="text-nowrap text-center">ຜູ້ຈັດຖ້ຽວ</th>
                <th className="text-nowrap text-center">ສາຍທີ່ແລ່ນ</th>
                <th className="text-nowrap text-center">ເລກທີໂກຕ່າ</th>
                <th className="text-nowrap text-center">ປະເພດສາຍ</th>
                <th className="text-nowrap text-center">ກະອອກລົດ</th>
                <th className="text-nowrap text-center">ປະເພດຖ້ຽວ</th>
                <th className="text-nowrap text-center">ລາຄາຕໍ່ຖ້ຽວໃນສາຍ(ກີບ)</th>
                <th className="text-nowrap text-center">ເວລາອອກຕົ້ນທາງ</th>
                <th className="text-nowrap text-center">ຢືນຢັນອອກລົດ</th>
                <th className="text-nowrap text-center">ວັນທີຈັດຖ້ຽວ</th>
                <th className="text-nowrap text-center">ໝາຍເຫດ</th>
                <th className="text-nowrap text-center">ຢືນຢັນອອກໂດຍ</th>
                <th className="text-nowrap text-center">ຢືນຢັນຮອດໂດຍ</th>
                <th className="text-nowrap text-center">ສູນປາຍທາງ</th>
                {checkUserRole(["SUPER_ADMIN", "ADMIN", "AUDITOR"]) ? <th className="text-nowrap text-center">ຈັດການ</th> : null}
              </tr>
            }
            // id="selectRow"
            body={
              <>
                {items?.map((value, index) => (
                  <tr key={index} className={value?.isDif === "1" ? "table-danger" : ""}>
                    <td className="text-center">{index + 1}</td>
                    <td className="text-nowrap">
                      {value?.driverID?.gender ? Gender(value?.driverID?.gender) : ""}&nbsp;
                      {value?.driverID?.first_name || "----"}&nbsp;{value?.driverID?.last_name || "----"}
                    </td>
                    <td className="text-center text-nowrap">
                      {value?.driverTwo?.gender ? Gender(value?.driverTwo?.gender) : ""}&nbsp;
                      {value?.driverTwo?.first_name || "----"} {value?.driverTwo?.last_name || "----"}
                    </td>
                    <td className="text-nowrap">{value?.vehicleID?.vehicleName || "----"}</td>
                    {/* <td className="text-nowrap">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <span style={{ color: "green" }}>
                          <TbArrowAutofitWidth />:{" "}
                          {newWidth && newWidth[index] ? (
                            `${currency(newWidth[index])} cm`
                          ) : (
                            <>
                              <Spinner animation="border" variant="danger" size="sm" /> cm
                            </>
                          )}
                        </span>
                        <span style={{ color: "red" }}>
                          <GiWeight />:{" "}
                          {newWeight && newWeight[index] ? (
                            `${currency(newWeight[index])} kg`
                          ) : (
                            <>
                              <Spinner animation="border" variant="danger" size="sm" /> kg
                            </>
                          )}
                        </span>
                      </div>
                    </td> */}
                    <td className="text-nowrap">
                      {value?.createdBy?.gender ? Gender(value?.createdBy?.gender) : ""} {value?.createdBy?.first_name || "----"}{" "}
                      {value?.createdBy?.last_name || "----"}
                    </td>
                    <td className="text-nowrap ">{value?.routeLocationID?.title || "----"}</td>
                    <td className="text-center">{value?.billNoRout || "----"}</td>
                    <td className="text-nowrap text-center">
                      {value?.checkRoute === "IN" ? "ສາຍໃນ" : value?.checkRoute === "OUT" ? "ສາຍນອກ" : "ສາຍສຳຮອງ"}
                    </td>
                    <td className="text-center text-nowrap">{value?.isDay === "1" ? "ພາກແລງ" : "ພາກເຊົ້າ"}</td>
                    <td className={value?.isDif === "0" ? "text-success text-nowrap" : "text-danger text-nowrap"}>
                      {value?.isDif === "0" ? "ຖ້ຽວປົກກະຕິ" : "ຖ້ຽວເສີມ"}
                    </td>
                    <td className="text-nowrap">{currency(value?.amountValue || 0)} ກີບ</td>
                    <td className="text-center text-nowrap">{value?.startTime ? formatDateTime(value?.startTime) : "----"}</td>
                    <td className="text-center text-nowrap">
                      {value?.acceptStatus === "UNACCEPTED" ? (
                        <>
                          <h5>
                            <Button variant="danger" className="disable-cursor" size="sm">
                              <Spinner animation="border" size="sm" variant="warning" /> ລໍຖ້າຢືນຢັນອອກລົດ...
                            </Button>
                          </h5>
                        </>
                      ) : (
                        <>
                          <h5>
                            <Button variant="success" className="disable-cursor" size="sm">
                              <i className="fas fa-check-circle"></i> ຢືນຢັນອອກລົດແລ້ວ
                            </Button>
                          </h5>
                        </>
                      )}
                    </td>
                    <td className="text-center text-nowrap">{formatDateTime(value?.createdAt)}</td>
                    <td className="text-nowrap">{value?.difNote || "----"}</td>
                    <td className="text-nowrap">
                      {value?.acceptBy?.gender ? Gender(value?.acceptBy?.gender) : ""} {value?.acceptBy?.first_name || "----"}{" "}
                      {value?.acceptBy?.last_name || "----"}
                    </td>
                    <td className="text-nowrap">
                      {value?.acceptArrivedBy?.gender ? Gender(value?.acceptArrivedBy?.gender) : ""} {value?.acceptArrivedBy?.first_name || "---"}{" "}
                      {value?.acceptArrivedBy?.last_name || "---"}
                    </td>
                    <td className="text-nowrap">{value?.centerRoute?.centerName || "----"}</td>
                    <td className=" text-nowrap">
                      <div className="btn-group">
                        {checkUserRole(["SUPER_ADMIN" , "AUDITOR"]) ? (
                          <>
                            <UpdateShippingRoute Items={value} onSuccess={() => setReloading(!reloading)} />
                          </>
                        ) : null}

                        {checkUserRole(["SUPER_ADMIN", "ADMIN" ,"AUDITOR"]) ? (
                          <>
                            <UpdateRoute item={value} onSuccess={() => setReloading(!reloading)} />
                          </>
                        ) : null}

                        {value?.acceptStatus === "UNACCEPTED" ? (
                          <>
                            {checkUserRole(["ADMIN", "SUPER_ADMIN", "AUDITOR"]) ? (
                              <Verified items={value} onSuccess={() => setReloading(!reloading)} />
                            ) : null}
                          </>
                        ) : (
                          <>{checkUserRole(["SUPER_ADMIN"]) ? <Cancel items1={value} onSuccess={() => setReloading(!reloading)} /> : null}</>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            }
          />
        </div>
      </div>
    </>
  );
}
