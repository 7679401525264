import { gql } from "@apollo/client";

export default class Schema {
  static HISTORY_SHIPPING_ROUTE_DELETE = gql`
query ShippingRouteDeletes($where: ShippingRouteDeleteInput, $limit: Int, $skip: Int) {
  shippingRouteDeletes(where: $where, limit: $limit, skip: $skip) {
    total
    data {
      sp_id
      routeLocationID {
        _id
        title
      }
      vehicleID {
        _id
        vehicleName
      }
      status
      startTime
      endTime
      amountValue
      paidStatus
      paidDate
      acceptStatus
      acceptBy {
        _id
        first_name
        last_name
        gender
      }
      newLocation
      createdAt
      createdBy {
        _id
        first_name
        last_name
        gender
      }
      consolidate_ID
      isConsolidated
      consolidatePaymentID
      difNote
      isDay
      isDif
      checkRoute
      petroleum
      billNoRout
      acceptArrivedBy {
        _id
        first_name
        last_name
        gender
      }
      driverTwo {
        _id
        first_name
        last_name
        gender
      }
      centerRoute {
        st_id
        centerName
      }
      deleteDate
      deleteBy {
        _id
        first_name
        last_name
        gender
      }
      driverID {
        _id
        first_name
        last_name
        gender
      }
    }
  }
}
  `;
}