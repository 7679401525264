import { useEffect, useState } from "react";
import { InvalidText, MessageConfirms, MessageErrors, MessageSuccess, confirmShow, getStaffLogin, notifyError, notifySuccess } from "../../../utils";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import Schema from "./Schema";

// verify component
type VerifyProps = {
  data: any;
  onSuccess: () => void;
  className?: string;
};

export const Verified = ({ data, onSuccess, className }: VerifyProps) => {
  const [FetchData, { data: result }] = useLazyQuery(Schema.QUERY_LOCATION_BRANCH, { fetchPolicy: "cache-and-network" });
  const [ServiceData] = useMutation(Schema.UPDATE_LOCATION_BRANCH);
  const [updateData, { loading }] = useMutation(Schema.UPDATE_SHIPPING_ROUTE_NEW_LOCATION);
  const getUserLogin = getStaffLogin();
  const [Items, setItems] = useState<any[]>([]);
  const RouteID = data?.routeLocationID?._id;

  useEffect(() => {
    if (!RouteID) return;
    FetchData({
      variables: {
        where: {
          locationID: Number(RouteID),
          service_type: "OPEN",
        },
      },
    });
  }, [FetchData, RouteID]);
  useEffect(() => {
    setItems(result?.locationGroupBranches?.data || []);
  }, [result]);

  const handleUpdate = async () => {
    confirmShow(MessageConfirms.confirmCheckDriver, async () => {
      try {
        await updateData({
          variables: {
            where: {
              sp_id: data?.sp_id,
            },
            data: {
              newLocation: "YES",
              acceptArrivedBy: getUserLogin?._id,
            },
          },
        });

        if (Items.length > 0) {
          await Promise.all(
            Items.map(async (values) => {
              await ServiceData({
                variables: {
                  where: { _id: values?._id },
                  data: {
                    service_type: "CLOSE",
                  },
                },
              });
            })
          );
        }

        notifySuccess(MessageSuccess.updateNewLocation);
        onSuccess?.();
      } catch (error) {
        notifyError(MessageErrors.updateNewLocation);
      }
    });
  };
  return (
    <Button className={className} variant="success" disabled={loading || data?.newLocation === "YES"} onClick={handleUpdate}>
      {loading ? (
        <Spinner animation="border" variant="white" size="sm" />
      ) : data?.newLocation === "YES" ? (
        <i className="fas fa-check m-0" />
      ) : (
        <i className="fas fa-check-circle m-0" />
      )}
    </Button>
  );
};

// update shipping_route component
type UpdateProps = {
  item: any;
  onSuccess: () => void;
  className?: string;
};

export const UpdateRoute = ({ item, className, onSuccess }: UpdateProps) => {
  const [show, setShow] = useState(false);
  const [note, setNote] = useState<any>();
  const [DeleteShippingRoute, { loading }] = useMutation(Schema.UPDATE_SHIPPING_ROUTE_DELETE);
  const HandleSubmit = async () => {
    try {
      await DeleteShippingRoute({
        variables: {
          data: {
            difNote: note,
          },
          where: {
            sp_id: item?.sp_id,
          },
        },
      });
      notifySuccess(MessageSuccess.delete);
      onSuccess?.();
      setShow(false);
    } catch (error) {
      notifyError(MessageSuccess.delete);
    }
  };

  return (
    <>
      <button type="button" className="btn btn-danger" onClick={() => setShow(true)}>
        <i className="fa fa-trash" />
      </button>
      <Modal show={show}>
        <Modal.Header onHide={() => setShow(false)} closeButton>
          <Modal.Title>
            <i className="fas fa-add"></i>ເຫດຜົນ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Form.Group className="mb-3">
              <Form.Label>ເຫດຜົນທີ່ຕ້ອງການລົບຖ້ຽວນີ້777{InvalidText()}</Form.Label>
              <Form.Control name="difNote" as="textarea" rows={3} onChange={(e: any) => setNote(e.target.value)} value={note} />
            </Form.Group>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={!note || loading} onClick={HandleSubmit}>
            <i className="fas fa-check-circle m-0" />
            <span className="ms-1">ສົ່ງຟອມ</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
