import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Gender, currency, formatDateTime, getStaffLogin } from "../../utils";
import { useReactToPrint } from "react-to-print";
import { BiPrinter } from "react-icons/bi";
import { Modal } from "react-bootstrap";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { BsFileEarmarkExcel } from "react-icons/bs";
import Logo from "../../assets/images/ans-logo-red.svg";

export const ExportTable = ({ data, loading }: any) => {
  const [items, setItems] = useState<any[]>();
  const elmRef = useRef<HTMLDivElement>(null);
  let today = moment().format("YYYY-MM-DD");
  let time = moment().format("HH:mm:ss");
  const getLogin = getStaffLogin();
  const [show, setShow] = useState(false);
  const handlePrint = useReactToPrint({
    content: () => elmRef.current,
  });
  useEffect(() => {
    if (!show) return;
    setItems(data?.shippingRouteDeletes?.data || []);
  }, [data, show]);

  return (
    <>
      <button
        type="button"
        className="btn btn-secondary  me-2 shadow-none"
        onClick={() => setShow(true)}
        style={{ position: "relative", top: "0px" }}
        disabled={loading}
      >
        <BiPrinter className="me-2" />
        ພິມລາຍງານ
      </button>
      <Modal show={show} dialogClassName="modal-width-95" animation={false}>
        <Modal.Header onHide={() => setShow(false)} closeButton>
          <Modal.Title>
            <i className="fas fa-truck"></i> ລາຍງານຂໍ້ມູນຖ້ຽວທີ່ຖືກລົບ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3 flex-control-group">
            <button onClick={handlePrint} type="button" className="btn btn-lg btn-dark ml-3 me-2">
              <BiPrinter /> ພິມລາຍງານ
            </button>
            <DownloadTableExcel filename="ລາຍງານການກວດສອບຂໍ້ມູນຖ້ຽວລົດ" sheet="ກວດສອບຂໍ້ມູນຖ້ຽວລົດ" currentTableRef={elmRef.current}>
              <button className="btn btn-lg btn-success">
                <BsFileEarmarkExcel /> Export excel{" "}
              </button>
            </DownloadTableExcel>
          </div>
          &nbsp;&nbsp;
          <div ref={elmRef} id="table-to-xls" className="container-print" style={{ fontFamily: "phetsarath OT" }}>
            <div className="text-center fw-bold" style={{ fontSize: "1.2rem" }}>
              ລາຍງານຂໍ້ມູນຖ້ຽວທີ່ຖືກລົບ
            </div>
            <div>
              <img style={{ width: 150 }} src={Logo} alt="" />
            </div>
            &nbsp;
            <div className="justify-content-between">
              <div>
                <div>ບໍລິສັດ ອານຸສິດ ໂລຈິສຕິກ ຈຳກັດ.</div>
                <div>ບ້ານ ໂພນສະອາດ, ເມືອງ ຈັນທະບູລີ, ນະຄອນຫຼວງວຽງຈັນ</div>
                <div className="text-end">
                  ພິມໂດຍ: {getLogin?.first_name} {getLogin?.last_name}
                </div>
                <div className="text-end">
                  ວັນທີພິມ: {time} , {today}
                </div>
              </div>
            </div>
            &nbsp;&nbsp;
            <div className="table">
              <table className="table" border={1}>
                <thead>
                  <tr>
                    <td>ລຳດັບ</td>
                    <td>ເບີລົດ</td>
                    <td>ສາຍທີ່ແລ່ນ</td>
                    <td>ລາຄາຕໍ່ຖ້ຽວໃນສາຍ</td>
                    <td>ປະເພດສາຍ</td>
                    <td>ກະອອກລົດ</td>
                    <td>ປະເພດຖ້ຽວ</td>
                    <td>ວັນທີຈັດຖ້ຽວ</td>
                    <td>ຜູ້ຈັດຖ້ຽວ</td>
                    <td>ລົບໂດຍ</td>
                    <td>ວັນທີ່ລົບ</td>
                    <td>ເຫດຜົນ</td>
                  </tr>
                </thead>
                <tbody>
                  {items?.map((value: any, index: number) => (
                    <tr className={value?.isDif === "1" ? "selectRow" : ""} key={index}>
                      <td style={{ backgroundColor: "#F97070" }}>{index + 1}</td>
                      <td style={{ backgroundColor: "#F97070" }}>{value?.vehicleID?.vehicleName || "----"}</td>
                      <td style={{ backgroundColor: "#F97070" }}>{value?.routeLocationID?.title || "----"}</td>
                      <td className="text-center" style={{ backgroundColor: "#F97070" }}>
                        {currency(value?.amountValue || "----")} ກີບ
                      </td>
                      <td style={{ backgroundColor: "#F97070" }}>
                        {(() => {
                          if (value?.checkRoute === "IN") {
                            return "ສາຍໃນ";
                          } else if (value?.checkRoute === "OUT") {
                            return "ສາຍນອກ";
                          } else {
                            return "ສາຍສຳຮອງ";
                          }
                        })()}
                      </td>
                      <td style={{ backgroundColor: "#F97070" }}>{value?.isDay === "0" ? "ພາກເຊົ້າ" : "ພາກເເລງ"}</td>
                      <td style={{ backgroundColor: "#F97070" }}>{value?.isDif === "1" ? "ຖ້ຽວເສີມ" : "ຖ້ຽວປົກກະຕິ"}</td>

                      <td style={{ backgroundColor: "#F97070" }}>{formatDateTime(value?.createdAt || "----")}</td>

                      <td style={{ backgroundColor: "#F97070" }}>
                        {value?.createdBy?.gender ? Gender(value?.createdBy?.gender) : ""}&nbsp;{value?.createdBy?.first_name || "----"}&nbsp;
                        {value?.createdBy?.last_name || "----"}
                      </td>
                      <td style={{ backgroundColor: "#F97070" }}>
                        {value?.deleteBy?.gender ? Gender(value?.deleteBy?.gender) : ""}&nbsp;{value?.deleteBy?.first_name || "----"}&nbsp;
                        {value?.deleteBy?.last_name || "----"}
                      </td>
                      <td style={{ backgroundColor: "#F97070" }}>{formatDateTime(value?.deleteDate || "----")}</td>
                      <td style={{ backgroundColor: "#F97070" }}>{value?.difNote || "----"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="row">
                <div className="col-sm-2"></div>
                <div className="col-sm-8 d-flex justify-content-between aligns-center">
                  <div>ຜູ້ອຳນວຍການ</div>
                  <div>ຜູ້ຮັບຜິດຊອບ</div>
                </div>
                <div className="col-sm-2"></div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

