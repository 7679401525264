import { useMutation } from "@apollo/client";
import React, { useState, useRef, useEffect } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import Schema from "./Schema";
import {
  Gender,
  MessageSuccess,
  currency,
  formatDash,
  formatDate,
  formatDateTime,
  getStaffLogin,
  graphQLErrors,
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../utils";
import { BiPrinter } from "react-icons/bi";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { BsFileEarmarkExcel } from "react-icons/bs";
import Logo from "../../assets/images/ans-logo-red.svg";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
export const UPDATE_CHECK_ROUTE = ({ data, onSuccess, className }: { data: any; onSuccess: () => void; className?: string }) => {
  const [show, setShow] = useState(false);
  const [note, setNote] = useState<any>(data?.difNote);
  const [UpdateScan, { loading }] = useMutation(Schema.UPDATE_CHECK_SCAN);
  const HandleUpdate = async () => {
    try {
      await UpdateScan({
        variables: {
          data: {
            difNote: String(note),
            checkScan: 1,
          },
          where: {
            sp_id: data?.sp_id,
          },
        },
      });
      notifySuccess(MessageSuccess.update);
      onSuccess?.();
      setShow(false);
    } catch (error) {
      notifyError(MessageSuccess.update);
    }
  };
  return (
    <React.Fragment>
      <button className="btn btn-primary" onClick={() => setShow(true)}>
        <i className="fas fa-edit"></i>
      </button>
      <Modal show={show}>
        <Modal.Header closeButton onHide={() => setShow(false)}>
          <Modal.Title>
            <i className="fas fa-edit"></i>ເຫດຜົນທີ່ແກ້ໄຂຂໍ້ມູນ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>ເຫດຜົນ</Form.Label>
            <Form.Control type="text" placeholder="ເຫດຜົນ......" as="textarea" value={note} rows={3} onChange={(e: any) => setNote(e.target.value)} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={!note} onClick={HandleUpdate}>
            {loading ? <Spinner animation="border" variant="white" size="sm" /> : <i className="fas fa-check-circle m-0" />}
            <span className="ms-1">ສົ່ງຟອມ</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

// Verify shipping routes
export const UPDATE_VERIFY_ROUTE = ({ data, onSuccess, className }: { data: any; onSuccess: () => void; className?: string }) => {
  const [Verify, { loading }] = useMutation(Schema.UPDATE_VERIFY_SHIPPING_ROUTE);
  const HandleVerify = async () => {
    try {
      const result = {
        vehicleID: data?.vehicleID?._id,
      };
      await Verify({
        variables: {
          data: result,
          where: {
            sp_id: data?.sp_id,
          },
        },
      });
      notifySuccess(MessageSuccess.Verify);
      onSuccess?.();
    } catch (e) {
      const error = graphQLErrors(e);
      if (error === "NO DATA FOR SCANNING") {
        notifyWarning("ກວດສອບບໍ່ສຳເລັດກະລຸນນກວດສອບຄືນ");
        onSuccess?.();
      } else {
        notifyError(MessageSuccess.Verify);
      }
    }
  };
  return (
    <React.Fragment>
      <button className="btn btn-success btn-sm" onClick={HandleVerify} disabled={loading}>
        <i className="fas fa-check-circle"></i>ກວດສອບ
      </button>
    </React.Fragment>
  );
};

// Verify by administrators
export const UPDATE_VERIFY_ROUTE_BY_ADMIN = ({ sp_id, onSuccess, className }: { sp_id: any; onSuccess: () => void; className?: string }) => {
  const [VerifyByAdmin, { loading }] = useMutation(Schema.VERIFY_BY_ADMIN);
  const userID = getStaffLogin()?._id;
  const newDate: any = new Date();
  const todays = formatDash(newDate);
  const VerifyBYadmin = async () => {
    try {
      await VerifyByAdmin({
        variables: {
          data: {
            checkScan: 1,
            checkBy: Number(userID),
            checkDate: todays,
            difNote: "Provider By Supper Admin !",
          },
          where: {
            sp_id: sp_id,
          },
        },
      });
      notifySuccess(MessageSuccess.Verify);
      onSuccess?.();
    } catch (error) {
      notifyError(MessageSuccess.Verify);
    }
  };
  return (
    <React.Fragment>
      <button type="button" className="btn btn-danger btn-sm" onClick={VerifyBYadmin} disabled={loading}>
        <i className="fas fa-warning"></i>ກວດສອບ
      </button>
    </React.Fragment>
  );
};

// export table to export

export const ExportTable = ({ data, loading }: any) => {
  const [items, setItems] = useState<any[]>();
  const elmRef = useRef<HTMLDivElement>(null);
  let today = moment().format("YYYY-MM-DD");
  let time = moment().format("HH:mm:ss");
  const getLogin = getStaffLogin();
  const [show, setShow] = useState(false);
  const handlePrint = useReactToPrint({
    content: () => elmRef.current,
  });
  useEffect(() => {
    if (!show) return;
    setItems(data?.shippingRoutes?.data || []);
  }, [data, show]);

  return (
    <>
      <button
        type="button"
        className="btn btn-secondary  me-2 shadow-none"
        onClick={() => setShow(true)}
        style={{ position: "relative", top: "0px" }}
        disabled={loading}
      >
        <BiPrinter className="me-2" />
        ພິມລາຍງານ
      </button>
      <Modal show={show} dialogClassName="modal-width-95" animation={false}>
        <Modal.Header onHide={() => setShow(false)} closeButton>
          <Modal.Title>
            <i className="fas fa-truck"></i> ລາຍງານຂໍ້ມູນລົດ ກຳລັງເດີນທາງ ແລະ ຮອດປາຍທາງ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3 flex-control-group">
            <button onClick={handlePrint} type="button" className="btn btn-lg btn-dark ml-3 me-2">
              <BiPrinter /> ພິມລາຍງານ
            </button>
            <DownloadTableExcel filename="ລາຍງານການກວດສອບຂໍ້ມູນຖ້ຽວລົດ" sheet="ກວດສອບຂໍ້ມູນຖ້ຽວລົດ" currentTableRef={elmRef.current}>
              <button className="btn btn-lg btn-success">
                <BsFileEarmarkExcel /> Export excel{" "}
              </button>
            </DownloadTableExcel>
          </div>
          &nbsp;&nbsp;
          <div ref={elmRef} id="table-to-xls" className="container-print" style={{ fontFamily: "phetsarath OT" }}>
            <div className="text-center fw-bold" style={{ fontSize: "1.2rem" }}>
              ລາຍງານຂໍ້ມູນລົດ ກຳລັງເດີນທາງ ແລະ ຮອດປາຍທາງ
            </div>
            <div>
              <img style={{ width: 150 }} src={Logo} alt="" />
            </div>
            &nbsp;
            <div className="justify-content-between">
              <div>
                <div>ບໍລິສັດ ອານຸສິດ ໂລຈິສຕິກ ຈຳກັດ.</div>
                <div>ບ້ານ ໂພນສະອາດ, ເມືອງ ຈັນທະບູລີ, ນະຄອນຫຼວງວຽງຈັນ</div>
                <div className="text-end">
                  ພິມໂດຍ: {getLogin?.first_name} {getLogin?.last_name}
                </div>
                <div className="text-end">
                  ວັນທີພິມ: {time} , {today}
                </div>
              </div>
            </div>
            &nbsp;&nbsp;
            <div className="table">
              <table className="table" border={1}>
                <thead>
                  <tr>
                    <td>ລຳດັບ</td>
                    <td>ເບີລົດ</td>
                    <td>ຊື່ໂຊເຟີ້</td>
                    <td>ສາຍທີ່ແລ່ນ</td>
                    <td>ປະເພດສາຍ</td>
                    <td>ກະອອກລົດ</td>
                    <td>ປະເພດຖ້ຽວ</td>
                    <td>ການກວດສອບ</td>
                    <td>ກວດສອບໂດຍ</td>
                    <td>ຈຳນວນພັດສະດຸ</td>
                    <td>ລາຄາຕໍ່ຖ້ຽວໃນສາຍ(ກີບ)</td>
                    <td>ວັນທີຈັດຖ້ຽວ</td>
                    <td>ວັນທີກວດສອບ</td>
                    <td>ວັນທີສະແກນອອກ</td>
                    <td>ໝາຍເຫດ</td>
                  </tr>
                </thead>
                <tbody>
                  {items?.map((value: any, index: number) => (
                    <tr className={value?.isDif === "1" ? "selectRow" : ""} key={index}>
                      <td>{index + 1}</td>
                      <td>{value?.vehicleID?.vehicleName || "----"}</td>
                      <td>
                        <div>
                          {value?.driverID?.gender ? Gender(value?.driverID?.gender) : ""} {value?.driverID?.first_name || "----"}{" "}
                          {value?.driverID?.last_name || "----"}
                        </div>
                        <hr />
                        <div>
                          {value?.driverTwo?.gender ? Gender(value?.driverTwo?.gender) : ""}
                          {value?.driverTwo?.first_name || "----"} {value?.driverTwo?.last_name || "----"}
                        </div>
                      </td>
                      <td>{value?.routeLocationID?.title || "----"}</td>
                      <td>
                        {(() => {
                          if (value?.checkRoute === "IN") {
                            return "ສາຍໃນ";
                          } else if (value?.checkRoute === "OUT") {
                            return "ສາຍນອກ";
                          } else {
                            return "ສາຍສຳຮອງ";
                          }
                        })()}
                      </td>
                      <td>{value?.isDay === "0" ? "ພາກເຊົ້າ" : "ພາກເເລງ"}</td>
                      <td>{value?.isDif === "1" ? "ຖ້ຽວເສີມ" : "ຖ້ຽວປົກກະຕິ"}</td>
                      <td>
                        {value?.checkScan === 1 ? (
                          <>
                            <p className="text-success">
                              <i className="fas fa-check-circle"></i>ກວດສອບຜ່ານ
                            </p>
                          </>
                        ) : value?.checkScan === 2 ? (
                          <>
                            <p className="text-danger">
                              <i className="fas fa-warning"></i>ກວດສອບບໍ່ຜ່ານ
                            </p>
                          </>
                        ) : null}
                      </td>
                      <td>
                        {value?.checkBy?.gender ? Gender(value?.checkBy?.gender) : ""}
                        {value?.checkBy?.first_name || "----"} {value?.checkBy?.last_name || "----"}
                      </td>
                      <td>{value?.totalItem || "----"}</td>
                      <td style={{ textAlign: "center" }}>{currency(value?.amountValue || 0)} ກີບ</td>

                      <td>{formatDateTime(value?.createdAt || "----")}</td>

                      <td>{value?.checkDate ? formatDate(value?.checkDate) : "-----"}</td>

                      <td>{value?.dateScan_out ? formatDateTime(value.dateScan_out) : "----"}</td>
                      <td>{value?.difNote || "----"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="row">
                <div className="col-sm-2"></div>
                <div className="col-sm-8 d-flex justify-content-between aligns-center">
                  <div>ຜູ້ອຳນວຍການ</div>
                  <div>ຜູ້ຮັບຜິດຊອບ</div>
                </div>
                <div className="col-sm-2"></div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
