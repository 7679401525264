import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { InvalidText, MessageErrors, MessageSuccess, getStaffLogin, graphQLErrors, notifyError, notifySuccess, notifyWarning } from "../../../utils";
import SearchDriverVehicle from "../../../utils/components/SelectDriver";
import SearchDriverVehicleTwo from "../../../utils/components/SelectDriverTwo";
import SearchNumberVehicleOut from "../../../utils/components/SelectNumberVehicleOut";
import SearchNumberVehicle from "../../../utils/components/SelectNumberVehicle";
import SearchRouteTitle from "../../../utils/components/SelectRouteTitle";
import SearchCenterRoute from "../../../utils/components/SelectCenterRoute";
import { useLazyQuery, useMutation } from "@apollo/client";
import Schema from "./Schema";
import { useFormik } from "formik";

type TypeValue = {
  routeLocationID: number;
  vehicleID: number;
  driverID: number;
  driverTwo: number;
  isDif: string;
  difNote: string;
  isDay: string;
  checkRoute: string;
  pay: string;
  centerRoute: number;
  inSide: number;
};

type TypeError = {
  routeLocationID?: string;
  vehicleID?: string;
  isDif?: string;
  isDay?: string;
  checkRoute?: string;
  centerRoute?: string;
};
// create component
export default function AddShippingRoute({ onSuccess }: any) {
  const [show, setShow] = useState(false);
  const userLogin = getStaffLogin();
  const [checkRoute, setCheckRoute] = useState<any>();
  const [isDay, setIsDay] = useState<any>();
  const [Items, setItems] = useState<any[]>();
  const [total, setTotal] = useState(0);
  const [RouteID, setRouteID] = useState<any>();
  const [vehicleID, setVehicleID] = useState<any>();
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredItems, setFilteredItems] = useState<any[]>([]);

  const [createData, { loading }] = useMutation(Schema.CREATE_SHIPPING_ROUTE);
  const [FetchData, { data, loading: loadingLocation }] = useLazyQuery(Schema.QUERY_LOCATION_BRANCH, { fetchPolicy: "cache-and-network" });
  const [queryVehicleID, { data: results }] = useLazyQuery(Schema.QUERY_VEHICLE_SHIPPING_ROUTES, {
    fetchPolicy: "cache-and-network",
  });

  const DriverPartnerID = results?.vehicles?.data[0]?.driver?._id;
  useEffect(() => {
    if (vehicleID === undefined) return;
    queryVehicleID({
      variables: {
        where: {
          _id: Number(vehicleID) ? Number(vehicleID) : undefined,
        },
      },
    });
  }, [vehicleID]);

  useEffect(() => {
    if (!RouteID) return;
    const where = {
      locationID: Number(RouteID),
      isDay: parseInt(isDay),
      service_type: "CLOSE",
    };
    FetchData({
      variables: {
        where: where,
        limit: 100,
      },
    });
  }, [FetchData, RouteID, isDay]);
  useEffect(() => {
    setItems(data?.locationGroupBranches?.data || []);
    setTotal(data?.locationGroupBranches?.total || 0);
  }, [data]);

  useEffect(() => {
    if (searchTerm) {
      const filtered = (Items || []).filter((item: any) => item.branch_id.branch_name.toLowerCase().includes(searchTerm.toLowerCase()));
      setFilteredItems(filtered);
    } else {
      setFilteredItems(Items || []);
    }
  }, [searchTerm, Items]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  // useFormik
  const { values, errors, handleChange, submitForm, isSubmitting, setSubmitting, resetForm, setFieldValue } = useFormik({
    initialValues: {
      routeLocationID: 0,
      vehicleID: 0,
      driverID: 0,
      driverTwo: 0,
      isDif: "2",
      difNote: "",
      isDay: "",
      checkRoute: "",
      pay: "",
      centerRoute: 0,
      inSide: 0,
    },
    enableReinitialize: false,
    validate: (values: TypeValue) => {
      const errors: TypeError = {};
      if (!values?.routeLocationID) errors.routeLocationID = "ກະລຸນາເລືອກສາຍຂົນສົ່ງກ່ອນ";
      if (!values?.vehicleID) errors.vehicleID = "ກະລຸນາເລືອກເບີລົດກ່ອນ";
      if (!values?.isDif) errors.isDif = "ກະລຸນາຈັດສາຍກ່ອນ";
      if (!values?.isDay) errors.isDay = "ກະລຸນາເລືອກກະອອກລົດ";
      if (!values?.checkRoute) errors.checkRoute = "ກະລຸນາເລືອກປະເພດສາຍກ່ອນ";
      if (!values?.centerRoute) errors.centerRoute = "ກະລຸນາເລືອກສູນປາຍທາງ";
      return errors;
    },
    onSubmit: async (values) => {
      if (selectedItems.length === 0) {
        return notifyWarning("ກະລຸນາເລືອກສາຂາກ່ອນ!!!");
      }
      try {
        await createData({
          variables: {
            data: {
              ...values,
              pay: "0",
              createdBy: userLogin?._id,
              driverID: Number(values?.driverID) ? Number(values?.driverID) : Number(DriverPartnerID),
              isDif: "2",
              branch_id: String(selectedItems),
              // center: values.centerRoute,
            },
          },
        });

        notifySuccess(MessageSuccess.insert);
        setItems([]);
        setSubmitting(false);
        resetForm();
        setRouteID(undefined);
        setSelectedItems([]);
        setSearchTerm("");
        setFilteredItems([]);
        setTotal(0);
        // setShow(false);
        onSuccess?.();
      } catch (error) {
        const errors = graphQLErrors(error);
        if (errors.includes("Pls Select branch")) {
          notifyError("ກະລຸນາເລືອກສາຂາກ່ອນ!!!");
        } else {
          notifyError(MessageErrors.insert);
        }
        setSubmitting(false);
      }
    },
  });

  const handleCheckboxChange = (branchId: number) => {
    if (selectedItems.includes(branchId)) {
      setSelectedItems(selectedItems.filter((id) => id !== branchId));
    } else {
      setSelectedItems([...selectedItems, branchId]);
    }
  };

  const handleSelectAll = () => {
    if (!Items) return;
    if (selectedItems.length === Items.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(Items.map((item) => item.branch_id.id_branch));
    }
  };

  return (
    <>
      <button type="button" className="btn btn-primary  shadow-none" onClick={() => setShow(true)}>
        <i className="fas fa-plus"></i>
        ເພີ່ມຖ້ຽວລົດ
      </button>
      <Modal show={show} dialogClassName="modal-width-95" animation={false}>
        {/* <Modal.Header onHide={() => setShow(false)} closeButton>
          <Modal.Title>
            <i className="fas fa-plus"></i>ຟອມເພີ່ມຖ້ຽວລົດເສີມ
          </Modal.Title>
        </Modal.Header> */}

        <Modal.Header>
          <div style={{ flex: 1, textAlign: "left" }}>
            <i className="fas fa-times" onClick={() => setShow(false)} style={{ cursor: "pointer", fontSize: 20 }} aria-label="Close"></i>
          </div>

          <div style={{ flex: 1, textAlign: "right" }}>
            <Modal.Title>
              <i className="fas fa-plus"></i> ຟອມເພີ່ມຖ້ຽວລົດລົດເສີມ
            </Modal.Title>
          </div>
        </Modal.Header>

        <Modal.Body>
          <>
            <div className="row">
              <div className="col-sm-9">
                <Row>
                  <Col xs={6} md={2}>
                    ເລືອກປະເພດສາຍ:{InvalidText()}
                  </Col>
                  <Col xs={12} md={9}>
                    <Form.Group className="mb-3">
                      <Form.Select
                        name="checkRoute"
                        value={values?.checkRoute}
                        onChange={(e: any) => {
                          setFieldValue("checkRoute", e.target.value);
                          setCheckRoute(e.target.value);
                        }}
                      >
                        <option>ເລືອກປະເພດສາຍ</option>
                        <option value="IN">ສາຍໃນ</option>
                        <option value="OUT">ສາຍນອກ</option>
                        <option value="SPARE">ສາຍສຳຮອງ</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Control.Feedback type="invalid">{errors.checkRoute}</Form.Control.Feedback>
                  </Col>
                  {checkRoute === "IN" ? (
                    <>
                      <Col xs={6} md={2} className="mt-2">
                        ໂຊເຟີ້(1):
                      </Col>
                      <Col xs={12} md={9} className="mt-2">
                        <Form.Group className="mb-3">
                          <SearchDriverVehicle
                            value={values?.driverID}
                            onChange={(res: any) => {
                              setFieldValue("driverID", res.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={6} md={2} className="mt-2">
                        ໂຊເຟີ້(2):
                      </Col>
                      <Col xs={12} md={9} className="mt-2">
                        <Form.Group className="mb-3">
                          <SearchDriverVehicleTwo
                            value={values?.driverTwo}
                            onChange={(res: any) => {
                              setFieldValue("driverTwo", res?.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </>
                  ) : null}
                  {checkRoute !== "IN" ? (
                    <>
                      <Col xs={6} md={2} className="mt-2">
                        ເບີລົດ:{InvalidText()}
                      </Col>
                      <Col xs={12} md={9} className="mt-2">
                        <Form.Group className="mb-3">
                          <SearchNumberVehicleOut
                            value={values?.vehicleID}
                            onChange={(res: any) => {
                              setFieldValue("vehicleID", res?.value);
                              setVehicleID(res?.value);
                            }}
                          />
                        </Form.Group>
                        <Form.Control.Feedback type="invalid">{errors?.vehicleID}</Form.Control.Feedback>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col xs={6} md={2} className="mt-2">
                        ເບີລົດ:{InvalidText()}
                      </Col>
                      <Col xs={12} md={9} className="mt-2">
                        <Form.Group className="mb-3">
                          <SearchNumberVehicle
                            value={values?.vehicleID}
                            onChange={(res: any) => {
                              setFieldValue("vehicleID", res?.value);
                              setFieldValue("center", res?.center);
                            }}
                          />
                        </Form.Group>
                        <Form.Control.Feedback type="invalid">{errors?.vehicleID}</Form.Control.Feedback>
                      </Col>
                    </>
                  )}

                  <Col xs={6} md={2} className="mt-2">
                    ກະອອກລົດ:{InvalidText()}
                  </Col>
                  <Col xs={12} md={9} className="mt-2">
                    <Form.Group className="mb-3">
                      <Form.Select
                        name="isDay"
                        value={values?.isDay}
                        onChange={(e: any) => {
                          setFieldValue("isDay", e.target.value);
                          setIsDay(e.target.value);
                        }}
                      >
                        <option>ເລືອກກະອອກລົດ</option>
                        <option value="0">ພາກເຊົ້າ</option>
                        <option value="1">ພາກແລງ</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Control.Feedback type="invalid">{errors?.isDay}</Form.Control.Feedback>
                  </Col>

                  <Col xs={6} md={2} className="mt-2">
                    ສາຍຂົນສົ່ງ:{InvalidText()}
                  </Col>
                  <Col xs={12} md={9} className="mt-2">
                    <Form.Group className="mb-3">
                      <SearchRouteTitle
                        isDay={isDay}
                        checkRoute={checkRoute}
                        value={values?.routeLocationID}
                        onChange={(res: any) => {
                          setFieldValue("routeLocationID", res?.value);
                          setRouteID(res?.value);
                        }}
                      />
                    </Form.Group>
                    <Form.Control.Feedback type="invalid">{errors?.routeLocationID}</Form.Control.Feedback>
                  </Col>

                  <Col xs={6} md={2} className="mt-2">
                    ກໍລະນີຈັດສາຍ:{InvalidText()}
                  </Col>

                  <Col xs={12} md={9} className="mt-2">
                    <Form.Group className="mb-3">
                      <Form.Control name="difNote" type="text" value={"ຖ້ຽວເສີມພິເສດ"} readOnly disabled />
                    </Form.Group>
                    <Form.Control.Feedback type="invalid">{errors?.difNote}</Form.Control.Feedback>
                  </Col>

                  <Col xs={6} md={2} className="mt-2"></Col>
                  <Col xs={12} md={9} className="mt-2">
                    <Form.Group className="mb-3">
                      <Form.Select
                        name="pay"
                        value={values?.pay}
                        onChange={(e: any) => {
                          setFieldValue("pay", e.target.value);
                        }}
                      >
                        <option value="0">ຈ່າຍແລ້ວ</option>
                        <option value="1">ຍັງບໍ່ທັນຈ່າຍ</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col xs={6} md={2} className="mt-2">
                    ເລືອກສູນປາຍທາງ:{InvalidText()}
                  </Col>
                  <Col xs={12} md={9} className="mt-2">
                    <Form.Group className="mb-3">
                      <SearchCenterRoute
                        value={values?.centerRoute}
                        onChange={(res: any) => {
                          setFieldValue("centerRoute", res?.value);
                        }}
                      />
                    </Form.Group>
                    <Form.Control.Feedback type="invalid">{errors?.centerRoute}</Form.Control.Feedback>
                  </Col>
                  <Col xs={6} md={2} className="mt-2">
                    ໝາຍເຫດ:
                  </Col>
                  <Col xs={12} md={9} className="mt-2">
                    <Form.Group>
                      <Form.Control name="difNote" as="textarea" rows={3} onChange={handleChange} value={values?.difNote} />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
              <div className="col-sm-3">
                <div className="card" style={{ marginInlineStart: -80 }}>
                  <div className="card-header" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="ຄົ້ນຫາສາຂາ..."
                      value={searchTerm}
                      onChange={handleSearchChange}
                      style={{ width: "40%" }}
                    />
                    <span style={{ marginLeft: "20px" }}>ເລືອກສາຂາໃນສາຍທີ່ຕ້ອງການໄປເກັບ</span>
                  </div>
                  {total ? (
                    <div className="card-body" style={{ maxHeight: "500px", overflowY: "auto" }}>
                      <Form.Check
                        type="checkbox"
                        label="ເລືອກທັ້ງໝົດ"
                        checked={Items ? selectedItems.length === Items.length : false}
                        onChange={handleSelectAll}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Form.Check.Input
                          type="checkbox"
                          style={{
                            border: "1px solid black",
                            padding: "5px",
                          }}
                          checked={Items ? selectedItems.length === Items.length : false}
                          onChange={handleSelectAll}
                        />
                        <Form.Check.Label style={{ marginLeft: "8px" }}>ເລືອກທັ້ງໝົດ</Form.Check.Label>
                      </Form.Check>
                      <br />
                      {loadingLocation ? (
                        <tr>
                          <td colSpan={5} className="text-center">
                            <Spinner animation="border" style={{ color: "red" }} size="sm" /> ກຳລັງໂຫລດຂໍ້ມູນ
                          </td>
                        </tr>
                      ) : (
                        <>
                          {filteredItems?.map((item: any) => (
                            <>
                              <Form.Group key={item.id} controlId={`checkbox-${item.branch_id.id_branch}`}>
                                <Form.Check
                                  type="checkbox"
                                  label={item?.branch_id?.branch_name}
                                  checked={selectedItems.includes(item.branch_id.id_branch)}
                                  onChange={() => handleCheckboxChange(item.branch_id.id_branch)}
                                  style={{ display: "flex", alignItems: "center" }}
                                >
                                  <Form.Check.Input
                                    type="checkbox"
                                    style={{ border: "1px solid black", padding: "5px" }}
                                    checked={selectedItems.includes(item.branch_id.id_branch)}
                                    onChange={() => handleCheckboxChange(item.branch_id.id_branch)}
                                  />

                                  <Form.Check.Label style={{ marginLeft: "8px" }}>{item?.branch_id?.branch_name}</Form.Check.Label>
                                </Form.Check>
                                <br />
                              </Form.Group>
                            </>
                          ))}
                        </>
                      )}
                      ,
                    </div>
                  ) : (
                    <>
                      <h6 className="text-center mt-2">ບໍ່ມີສາຂາ</h6>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          {loading ? (
            <Spinner animation="border" variant="white" size="sm" />
          ) : (
            <Button
              disabled={isSubmitting || loading}
              onDoubleClick={() => {
                return false;
              }}
              onClick={() => {
                if (!loading) {
                  submitForm();
                }
              }}
            >
              <span className="ms-1">ສົ່ງຟອມ</span>
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
