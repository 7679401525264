import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { EndOfDay, StartOfDay, StartOfDaySummary } from "..";

interface SearchDateRangeProps {
  eDay?: boolean;
  sDay?: boolean;
  hideStartDate?: boolean;
  hideEndDate?: boolean;
  containerClass?: string;
  containerStyle?: React.CSSProperties;
  isToday?: boolean;
  onDateChange?: (startDate: string, endDate: string) => void;
}

export default function SearchDateRangeConsolidateSummary({
  hideStartDate,
  hideEndDate,
  containerClass,
  containerStyle,
  isToday,
  eDay,
  sDay,
  onDateChange,
}: SearchDateRangeProps) {
  const [defaultStartDate, setDefaultStartDate] = useState("");
  const [defaultEndDate, setDefaultEndDate] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const _start = searchParams.get("startDate");
    const _end = searchParams.get("endDate");
    const _endOfDay = eDay ? EndOfDay() : EndOfDay();
    const _startOfDay = sDay ? StartOfDaySummary() : StartOfDaySummary();
    setDefaultStartDate(_start ? _start : _startOfDay);
    setDefaultEndDate(_end ? _end : _endOfDay);
  }, [searchParams, isToday, eDay, sDay]);

  const handleDateChange = (key: string, value: string) => {
    searchParams.set(key, value);
    if (searchParams.get("page")) {
      searchParams.set("page", "1");
    }
    setSearchParams(searchParams);
    if (key === "startDate") {
      setDefaultStartDate(value);
    } else if (key === "endDate") {
      setDefaultEndDate(value);
    }
    if (onDateChange) {
      onDateChange(defaultStartDate, defaultEndDate);
    }
  };

  const handleSearch = () => {
    if (searchParams.get("page")) {
      searchParams.set("page", "1");
    }
    searchParams.set("startDate", defaultStartDate);
    searchParams.set("endDate", defaultEndDate);
    setSearchParams(searchParams);
  };

  return (
    <div className={`search-date-range ${containerClass}`} style={containerStyle}>
      <i className="fas fa-search" />
      ຄົ້ນຫາຂໍ້ມູນຈາກວັນທີເລືອກສະຫຼຸບ
      <div className="input-group">
        {!hideStartDate && (
          <Form.Control
            type="date"
            value={defaultStartDate}
            onChange={(e) => {
              handleDateChange("startDate", e.target.value);
            }}
          />
        )}
        {!hideEndDate && (
          <Form.Control
            type="date"
            value={defaultEndDate}
            onChange={(e) => {
              handleDateChange("endDate", e.target.value);
            }}
          />
        )}
        <Button disabled={!defaultStartDate || !defaultEndDate} onClick={handleSearch}>
          <i className="fas fa-search" />
          ຄົ້ນຫາ
        </Button>
      </div>
    </div>
  );
}
