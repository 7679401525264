import moment from "moment";
import React, { useRef, useState, useEffect, useMemo, useCallback } from "react";
import { currency, formatDate, generateBillNo, getStaffLogin } from "../../utils";
import { useReactToPrint } from "react-to-print";
import { BiPrinter } from "react-icons/bi";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { BsFileEarmarkExcel } from "react-icons/bs";
import Logo from "../../assets/images/ans-logo-red.svg";
import { Button, Image, Modal } from "react-bootstrap";
import { useLazyQuery } from "@apollo/client";
import Schema from "./Schema";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import ObserveSignature from "./signature/observeSignature";
import AdministratorSignature from "./signature/administrator";
import ManagerSignature from "./signature/manageSignature";
import SummarySignature from "./signature/summaryOne";
import "../../App";
import { url_upload_Profile } from "../../configs/Environment";
export default function ExportTotal({
  data,
  driver,
  company,
  paid,
  isDay,
  isDif,
  checkRoute,
  center,
  vehicle,
  startDate,
  endDate,
  TotalDrivers,
  totalBalance,
  item,
  consolidateID,
  _endConsolidate,
  _startConsolidate,
}: any) {
  const elmRef = useRef<HTMLDivElement>(null);
  let today = moment().format("YYYY-MM-DD");
  let time = moment().format("HH:mm:ss");
  const getLogin = getStaffLogin();
  const handlePrint = useReactToPrint({
    content: () => elmRef.current,
  });

  console.log("data", data);
  const [show, setShow] = useState(false);
  let bilNoToday = moment().format("YYYYMMDD");
  const getGenerate = generateBillNo("");

  const [FetchData, { data: dataCompany }] = useLazyQuery(Schema.Query_company, { fetchPolicy: "cache-and-network" });
  const [FetchCenter, { data: DataCenter }] = useLazyQuery(Schema.Query_center, { fetchPolicy: "cache-and-network" });
  const [FetchVehicle, { data: DataVehicle }] = useLazyQuery(Schema.Query_vehicle, { fetchPolicy: "cache-and-network" });
  const [FetchUser, { data: DataUser }] = useLazyQuery(Schema.Query_users, { fetchPolicy: "cache-and-network" });
  const user1 = DataUser?.users?.data[0]?.first_name;
  const last_name = DataUser?.users?.data[0]?.last_name;
  const company1 = dataCompany?.driverPartners?.data[0]?.cp_name;
  const center1 = DataCenter?.separationCenters?.data[0]?.centerName;
  const vehicle1 = DataVehicle?.vehicles?.data[0]?.vehicleName;
  const [items, setItems] = useState<any>();
  const [signature, setSignature] = useState<any>();
  const [signatureObserve, setSignatureObserver] = useState<any>();
  const [signatureAmin, setSignatureAdmin] = useState<any>();
  const [signatureManager, setSignatureManager] = useState<any>();

  const [querySignature, { data: imageSignature, loading }] = useLazyQuery(Schema.QUERY_SIGNATURE, { fetchPolicy: "cache-and-network" });
  const [querySignatureObserve, { data: imageSignatureObserve }] = useLazyQuery(Schema.QUERY_SIGNATURE, { fetchPolicy: "cache-and-network" });
  const [querySignatureAdmin, { data: imageSignatureAdmin }] = useLazyQuery(Schema.QUERY_SIGNATURE, { fetchPolicy: "cache-and-network" });
  const [querySignatureManager, { data: imageSignatureManager }] = useLazyQuery(Schema.QUERY_SIGNATURE, { fetchPolicy: "cache-and-network" });

  const NewNoID = imageSignature?.signatures?.data[0]?.noID;
  const SignatureTwo = imageSignature?.signatures?.data[0]?.signatureTwo;
  const SignatureAdmin = imageSignatureAdmin?.signatures?.data[0]?.signatureTwo;
  const SignatureManager = imageSignatureManager?.signatures?.data[0]?.signatureTwo;
  const SignatureObserver = imageSignatureObserve?.signatures?.data[0]?.signatureTwo;

  useEffect(() => {
    if (vehicle === undefined) return;
    querySignatureObserve({
      variables: {
        where: {
          dateBetween: [_startConsolidate, _endConsolidate],
          vehicleID: Number(vehicle) ? Number(vehicle) : undefined,
          signatureStatus: "OBSERVER",
        },
        limit: 1,
      },
    });
  }, [show, vehicle, querySignatureObserve, _endConsolidate, _startConsolidate]);

  useEffect(() => {
    setSignatureObserver(imageSignatureObserve?.signatures?.data || []);
  }, [imageSignatureObserve]);

  useEffect(() => {
    if (vehicle === undefined) return;
    querySignatureAdmin({
      variables: {
        where: {
          dateBetween: [_startConsolidate, _endConsolidate],
          vehicleID: Number(vehicle) ? Number(vehicle) : undefined,
          signatureStatus: "ADMIN",
        },
        limit: 1,
      },
    });
  }, [show, _startConsolidate, _endConsolidate, vehicle, querySignatureAdmin]);

  useEffect(() => {
    setSignatureAdmin(imageSignatureAdmin?.signatures?.data || []);
  }, [imageSignatureAdmin]);

  useEffect(() => {
    if (vehicle === undefined) return;
    querySignatureManager({
      variables: {
        where: {
          dateBetween: [_startConsolidate, _endConsolidate],
          vehicleID: Number(vehicle) ? Number(vehicle) : undefined,
          signatureStatus: "MANAGER",
        },
        limit: 1,
      },
    });
  }, [querySignatureManager, show, _startConsolidate, _endConsolidate, vehicle]);

  useEffect(() => {
    setSignatureManager(imageSignatureManager?.signatures?.data || []);
  }, [imageSignatureManager]);

  useEffect(() => {
    if (vehicle === undefined) return;
    querySignature({
      variables: {
        where: {
          dateBetween: [_startConsolidate, _endConsolidate],
          vehicleID: Number(vehicle) ? Number(vehicle) : undefined,
          signatureStatus: "SUMMARY",
        },
        limit: 1,
      },
    });
  }, [querySignature, show, _startConsolidate, _endConsolidate, vehicle]);
  useEffect(() => {
    setSignature(imageSignature?.signatures?.data || []);
  }, [loading, imageSignature]);

  // END SIGNATURE

  useEffect(() => {
    if (company === undefined) return;
    FetchData({
      variables: {
        where: {
          cp_id: Number(company) ? Number(company) : undefined,
        },
      },
    });
  }, [company]);
  useEffect(() => {
    if (center === undefined) return;
    FetchCenter({
      variables: {
        where: {
          st_id: Number(center) ? Number(center) : undefined,
        },
      },
    });
  }, [center]);
  useEffect(() => {
    if (vehicle === undefined) return;
    FetchVehicle({
      variables: {
        where: {
          _id: Number(vehicle) ? Number(vehicle) : undefined,
        },
      },
    });
  }, [vehicle]);
  useEffect(() => {
    if (driver === undefined) return;
    FetchUser({
      variables: {
        where: {
          _id: Number(driver) ? Number(driver) : undefined,
        },
      },
    });
  }, [driver]);

  useEffect(() => {
    if (!show) return;
    setItems(data?.consolidateSummaries?.data);
  }, [data, show]);

  return (
    <React.Fragment>
      <button
        type="button"
        className="btn btn-secondary  me-2 shadow-none"
        onClick={() => setShow(true)}
        style={{ position: "relative", top: "0px" }}
      >
        <BiPrinter className="me-2" />
        ພິມລາຍງານ
      </button>
      <Modal show={show} dialogClassName="modal-width-95" animation={false}>
        <Modal.Header onHide={() => setShow(false)} closeButton>
          <Modal.Title>
            <i className="fas fa-receipt"></i> ລາຍງານຂໍ້ມູນສະຫລຸບຖ້ຽວລົດ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-sm-4">
              <div className="flex-control-group">
                <button onClick={handlePrint} type="button" className="btn btn-dark ml-3 me-2">
                  <BiPrinter /> ພິມລາຍງານ
                </button>
                <DownloadTableExcel
                  filename={"ລາຍງານຂໍ້ມູນຖ້ຽວລົດທີ່ສະຫລຸບແລ້ວ" + `${startDate}` + "ຫາ" + `${endDate}`}
                  sheet="ຂໍ້ມູນຖ້ຽວລົດ"
                  currentTableRef={elmRef.current}
                >
                  <button className="btn btn-success">
                    <BsFileEarmarkExcel /> Export excel{" "}
                  </button>
                </DownloadTableExcel>
                <DownloadFile items={item} />
              </div>
            </div>
            <div className="col-sm-8"></div>
          </div>
          <div ref={elmRef} id="table-to-xls" className="container-print" style={{ fontFamily: "phetsarath OT", fontSize: "12px" }}>
            <h4 className="text-center mt-3">ລາຍງານຂໍ້ມູນຖ້ຽວລົດທີ່ສະຫລຸບແລ້ວ</h4>
            <div style={{marginLeft: "50px"}}>
              <img style={{ width: 150 }} src={Logo} alt="" />
            </div>
            &nbsp;
            <div>
              <div style={{marginLeft: "50px" , fontSize: "15px"}}>
                <div>ບໍລິສັດ ອານຸສິດ ໂລຈິສຕິກ ຈຳກັດ.</div>
                <div>ບ້ານ ໂພນສະອາດ, ເມືອງ ຈັນທະບູລີ, ນະຄອນຫຼວງວຽງຈັນ</div>
                {NewNoID ? (
                  <>
                    <div className="text-end">ເລກທີ່: {NewNoID}</div>
                  </>
                ) : (
                  <>
                    <div className="text-end" style={{marginRight: "50px"}}>
                      ເລກທີ່: ANS-R{bilNoToday}
                      {getGenerate}
                    </div>
                  </>
                )}
                <div className="text-end" style={{marginRight: "50px"}}>
                  ພິມໂດຍ: {getLogin?.first_name} {getLogin?.last_name}
                </div>
                <div className="text-end" style={{marginRight: "50px"}}>
                  ວັນທີພິມ: {time} , {today}
                </div>
              </div>
            </div>
            <h4 className="mt-3" style={{marginLeft: "50px"}}>ລາຍງານການຄົ້ນຫາຂໍ້ມູນ ໃນລາຍການສະຫລຸບຖ້ຽວລົດ</h4>
            <table className="table" style={{ fontSize: "12px" }} border={1}>
              <thead>
                <tr>
                  <th>ວັນທີເລືອກສະຫລຸບ</th>
                  <th>ວັນທີສະຫລຸບ</th>
                  <th>ຊື່ໂຊເຟີລົດ</th>
                  <th>ບໍລິສັດ</th>
                  <th>ການຊຳລະ</th>
                  <th>ກະອອກລົດ</th>
                  <th>ປະເພດສາຍ</th>
                  <th>ເບີລົດ</th>
                  <th>ຖ້ຽວລົດ</th>
                  <th>ສູນ</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center">
                    {_startConsolidate || "---"} ເຖິງ {_endConsolidate || "---"}
                  </td>
                  <td className="text-center">{startDate}</td>
                  <td>
                    {user1}
                    {last_name}
                  </td>
                  <td>{company1}</td>
                  <td>{paid === "PAID" ? "ຊຳລະແລ້ວ" : paid === "UNPAID" ? "ຍັງບໍ່ຊຳລະ" : ""}</td>
                  <td>{isDay === "0" ? "ພາກເຊົ້າ" : isDay === "1" ? "ພາກແລງ" : ""}</td>
                  <td>{checkRoute === "IN" ? "ສາຍໃນ" : checkRoute === "OUT" ? "ສາຍນອກ" : checkRoute === "SPARE" ? "ສາຍເສີມ" : ""}</td>
                  <td>{vehicle1}</td>
                  <td>{isDif === "FALSE" ? "ຖ້ຽວປົກກະຕິ" : isDif === "TRUE" ? "ຖ້ຽວເສີມ" : ""}</td>
                  <td>{center1}</td>
                </tr>
              </tbody>
            </table>
            &nbsp;&nbsp;
            <div>
            <h4 className="mt-3" style={{marginLeft: "50px"}}>ລາຍງານຂໍ້ມູນຖ້ຽວລົດທີ່ສະຫຼຸບແລ້ວ</h4>
            </div>
            
            {items?.some((value: any) => value?.route_subline === "NORMAL") ? (
              <>
                <span style={{ fontSize: 20 , marginLeft: "50px" }} >ສາຍຫຼັກ</span>
                <table className="table" style={{ fontSize: "12px" }} border={1}>
                  <thead className="text-white">
                    <tr>
                      <th>ລຳດັບ</th>
                      <th>ຊື່ໂຊເຟີ້</th>
                      <th>ຂໍ້ມູນຖ້ຽວລົດ</th>
                      {/* <th>ການຊຳລະ</th> */}
                      {/* <th>ຂໍ້ມູນບັນຊີທະນາຄານ</th> */}
                      <th>ສາຍທີ່ແລ່ນ</th>
                      <th>ລາຄາສາຍ(ກີບ)</th>
                      <th>ນ້ຳມັນລວມ(ລິດ)</th>
                      <th>ຈຳນວນຖ້ຽວ(ຖ້ຽວ)</th>
                      <th>ເງິນທີ່ສະຫລຸບຂອງໂຊເຟີ້(1)(ກີບ)</th>
                      {/* <th>ເງິນທີ່ສະຫລຸບຂອງໂຊເຟີ້(2)(ກີບ)</th> */}
                      {/* <th>ວັນທີ່ສະຫຼຸບ</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {items
                        ?.filter((value: any) => value?.route_subline === "NORMAL")
                        ?.map((value: any, index: number) => (
                          <tr
                            className="tbody"
                            key={index}
                            style={{ backgroundColor: value?.consolidatePaidStatus === "PAID" ? "#c7ffea" : "", fontSize: "12px" }}
                          >
                            <td className="text-center text-nowrap">
                              <div style={{ marginTop: 25 }}>{index + 1}</div>
                            </td>
                            <td className="text-nowrap">
                              <div>
                                ໂຊເຟີ້(1): {value?.consolidateDriverID?.first_name || "---"}
                                {value?.consolidateDriverID?.last_name || "---"}
                              </div>
                              <br />
                              <div>
                                ໂຊເຟີ້(2): {value?.driverTwo?.first_name || "---"}
                                {value?.driverTwo?.last_name || "---"}
                              </div>
                            </td>
                            <td className="text-nowrap">
                              <div>ກະອອກລົດ: {value?.isDay === "1" ? "ພາກແລງ" : "ພາກເຊົ້າ"}</div>
                              <div>ເບີລົດ: {value?.consolidateVehicleID?.vehicleName || "-----"}</div>
                              <div className="text-success"> ກວດສອບຜ່ານ</div>
                              <div>ປະເພດຖ້ຽວ: {value?.isDif === "TRUE" ? "ຖ້ຽວເສີມ" : "ຖ້ຽວປົກກະຕິ"}</div>
                              <div>ສາຍລົດ: {value?.checkRoute === "IN" ? "ສາຍໃນ" : value?.checkRoute === "OUT" ? "ສາຍນອກ" : "ສາຍສຳຮອງ"}</div>
                            </td>
                            {/* <td className="text-nowrap">
                              <div>
                                ການຊຳລະ:{" "}
                                <span className={value?.consolidatePaidStatus === "UNPAID" ? "text-danger" : "text-success"}>
                                  {value?.consolidatePaidStatus === "UNPAID" ? "ຍັງບໍ່ຊຳລະ" : "ຊຳລະແລ້ວ"}
                                </span>{" "}
                              </div>
                              <div>
                                ຜູ້ຊຳລະ: {value?.paidBy?.first_name || "---" || "----"} {value?.paidBy?.last_name || "----"}
                              </div>
                              <div>ວັນທີຊຳລະ: {value?.consolidatePaidDate ? formatDate(value?.consolidatePaidDate) : "------"}</div>
                            </td> */}
                            {/* <td className="text-nowrap">
                              <div>ໂຊເຟີ້(1)(ຊື່ບັນຊີ): {value?.consolidateDriverID?.account_bank_name || "-----"}</div>
                              <div>ໂຊເຟີ້(1)(ເລກບັນຊີ): {value?.consolidateDriverID?.account_bank_number || "-----"}</div>
                              <div>ໂຊເຟີ້(2)(ຊື່ບັນຊີ): {value?.driverTwo?.account_bank_name || "-----"}</div>
                              <div>ໂຊເຟີ້(2)(ເລກບັນຊີ):{value?.driverTwo?.account_bank_number || "-----"}</div>
                            </td> */}
                            <td className="text-start">
                              <div>{value?.consolidateRouteID?.title || "-----"}</div>
                              <hr />
                              <div>ຈຳນວນສາຍ: 1 ສາຍ</div>
                            </td>
                            <td className="text-center text-nowrap">
                              {value?.checkRoute === "IN" ? (
                                <span>{value?.priceNight || 0}</span>
                              ) : value?.checkRoute === "OUT" ? (
                                <span>{value?.priceNight || 0}</span>
                              ) : (
                                <span>{value?.priceNight || 0}</span>
                              )}
                            </td>
                            <td className="text-center text-nowrap">
                              <div>{value?.petroleum || 0}</div>
                            </td>
                            <td className="text-center">
                              <div>{value?.consolidateTotalRoute || 0}</div>
                            </td>
                            <td className="text-nowrap">
                              <div>ລວມມູນຄ່າຖ້ຽວ: {value?.consolidateAmountRoute ? currency(value?.consolidateAmountRoute) : 0} ກີບ</div>
                              <div>ເງິນເດືອນຂອງໂຊເຟີ້(1): {value?.basicSalaryOne ? currency(value?.basicSalaryOne) : 0} ກີບ</div>
                              <div>ເງິນເພີ່ມ(ລວມ): {value?.extraPayment ? currency(value?.extraPayment) : 0} ກີບ</div>
                              <div className="text-danger">ເງິນຫັກ(ລວມ): {value?.deductionPayment ? currency(value?.deductionPayment) : 0} ກີບ</div>
                              <div className="text-success">
                                ເງິນລວມສຸດທິ: {value?.paymentBalanceAmount ? currency(value?.paymentBalanceAmount) : 0} ກີບ
                              </div>
                            </td>
                            {/* <td className="text-nowrap">
                              <div>ລວມມູນຄ່າຖ້ຽວ: {value?.consolidateAmountRouteTwo ? currency(value?.consolidateAmountRouteTwo) : 0} ກີບ</div>
                              <div>ເງິນເດືອນຂອງໂຊເຟີ້(2): {value?.basicSalaryTwo ? currency(value?.basicSalaryTwo) : 0} ກີບ</div>
                              <div>ເງິນເພີ່ມ(ລວມ): {value?.extraPaymentTwo ? currency(value?.extraPaymentTwo) : 0} ກີບ</div>
                              <div className="text-danger">
                                ເງິນຫັກ(ລວມ): {value?.deductionPaymentTwo ? currency(value?.deductionPaymentTwo) : 0} ກີບ
                              </div>
                              <div className="text-success">
                                ເງິນລວມສຸດທິ: {value?.paymentBalanceAmountTwo ? currency(value?.paymentBalanceAmountTwo) : 0} ກີບ
                              </div>
                            </td> */}
                            {/* <td className="text-nowrap">
                              <div>{value?.consolidateDate ? formatDate(value?.consolidateDate) : "-----"}</div>
                              <div>
                                {value?.startDate ? formatDate(value?.startDate) : "----"} ຫາ {value?.endDate ? formatDate(value?.endDate) : "-----"}
                              </div>
                            </td> */}
                          </tr>
                        ))}
                    </>
                  </tbody>
                  <tr>
                    <td colSpan={2} style={{ backgroundColor: "white", padding: "10px" }}>
                      ໂຊເຟີ້ລວມ: {data?.consolidateSummaries?.SumTotalDrivers || ""} ຄົນ
                    </td>
                    <td colSpan={1} style={{ backgroundColor: "white", padding: "10px" }}>
                      ຈຳນວນລົດ: {data?.consolidateSummaries?.TotalVehicle || ""} ຄົນ
                    </td>
                    <td colSpan={2} style={{ backgroundColor: "white", padding: "10px" }}>
                      ຍອດລວມ
                    </td>
                    <td colSpan={1} style={{ backgroundColor: "white", padding: "10px" }}>
                      {data?.consolidateSummaries?.SumTotalSalary || 0}
                    </td>
                    <td colSpan={1} style={{ backgroundColor: "white", padding: "10px" }}>
                      {data?.consolidateSummaries?.petroleum || 0}
                    </td>
                    <td colSpan={3} style={{ backgroundColor: "white", padding: "10px" }}>
                      <div>ຍອດລວມມູນຄ່າຖ້ຽວ: {currency(data?.consolidateSummaries?.SumTotalAmountRoutes) || 0} ກີບ</div>
                      <div>ຍອດລວມເງິນເດືອນ: {currency(data?.consolidateSummaries?.SumTotalSalary) || 0} ກີບ</div>
                      <div className="text-success">ຍອດລວມເງິນເພີ່ມ: {currency(data?.consolidateSummaries?.extraPayment) || 0} ກີບ</div>
                      <div className="text-danger">ຍອດລວມເງິນຫັກ: {currency(data?.consolidateSummaries?.SumDeductionTotal) || 0} ກີບ</div>
                      <div>ຍອດລວມທັງໝົດ: {currency(data?.consolidateSummaries?.SumTotalBalance) || 0} ກີບ</div>
                    </td>
                  </tr>
                </table>
              </>
            ) : null}
            {items?.some((value: any) => value?.route_subline === "SUBLINE") ? (
              <>
                <span style={{ fontSize: 20 , marginLeft: "50px" }}>ສາຍຍ່ອຍ</span>{" "}
                <table className="table" style={{ fontSize: "12px" }} border={1}>
                  <thead className="text-white">
                    <tr>
                      <th>ລຳດັບ</th>
                      <th>ຊື່ໂຊເຟີ້</th>
                      <th>ຂໍ້ມູນຖ້ຽວລົດ</th>
                      {/* <th>ການຊຳລະ</th> */}
                      {/* <th>ຂໍ້ມູນບັນຊີທະນາຄານ</th> */}
                      <th>ສາຍທີ່ແລ່ນ</th>
                      <th>ລາຄາສາຍ(ກີບ)</th>
                      <th>ນ້ຳມັນລວມ(ລິດ)</th>
                      <th>ຈຳນວນຖ້ຽວ(ຖ້ຽວ)</th>
                      <th>ເງິນທີ່ສະຫລຸບຂອງໂຊເຟີ້(1)(ກີບ)</th>
                      <th>ເງິນທີ່ສະຫລຸບຂອງໂຊເຟີ້(2)(ກີບ)</th>
                      {/* <th>ວັນທີ່ສະຫຼຸບ</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {items
                        ?.filter((value: any) => value?.route_subline === "NORMAL")
                        ?.map((value: any, index: number) => (
                          <tr
                            className="tbody"
                            key={index}
                            style={{ backgroundColor: value?.consolidatePaidStatus === "PAID" ? "#c7ffea" : "", fontSize: "12px" }}
                          >
                            <td className="text-center text-nowrap">
                              <div style={{ marginTop: 25 }}>{index + 1}</div>
                            </td>
                            <td className="text-nowrap">
                              <div>
                                ໂຊເຟີ້(1): {value?.consolidateDriverID?.first_name || "---"}
                                {value?.consolidateDriverID?.last_name || "---"}
                              </div>
                              <br />
                              <div>
                                ໂຊເຟີ້(2): {value?.driverTwo?.first_name || "---"}
                                {value?.driverTwo?.last_name || "---"}
                              </div>
                            </td>
                            <td className="text-nowrap">
                              <div>ກະອອກລົດ: {value?.isDay === "1" ? "ພາກແລງ" : "ພາກເຊົ້າ"}</div>
                              <div>ເບີລົດ: {value?.consolidateVehicleID?.vehicleName || "-----"}</div>
                              <div className="text-success"> ກວດສອບຜ່ານ</div>
                              <div>ປະເພດຖ້ຽວ: {value?.isDif === "TRUE" ? "ຖ້ຽວເສີມ" : "ຖ້ຽວປົກກະຕິ"}</div>
                              <div>ສາຍລົດ: {value?.checkRoute === "IN" ? "ສາຍໃນ" : value?.checkRoute === "OUT" ? "ສາຍນອກ" : "ສາຍສຳຮອງ"}</div>
                            </td>
                            {/* <td className="text-nowrap">
                              <div>
                                ການຊຳລະ:{" "}
                                <span className={value?.consolidatePaidStatus === "UNPAID" ? "text-danger" : "text-success"}>
                                  {value?.consolidatePaidStatus === "UNPAID" ? "ຍັງບໍ່ຊຳລະ" : "ຊຳລະແລ້ວ"}
                                </span>{" "}
                              </div>
                              <div>
                                ຜູ້ຊຳລະ: {value?.paidBy?.first_name || "---" || "----"} {value?.paidBy?.last_name || "----"}
                              </div>
                              <div>ວັນທີຊຳລະ: {value?.consolidatePaidDate ? formatDate(value?.consolidatePaidDate) : "------"}</div>
                            </td> */}
                            {/* <td className="text-nowrap">
                              <div>ໂຊເຟີ້(1)(ຊື່ບັນຊີ): {value?.consolidateDriverID?.account_bank_name || "-----"}</div>
                              <div>ໂຊເຟີ້(1)(ເລກບັນຊີ): {value?.consolidateDriverID?.account_bank_number || "-----"}</div>
                              <div>ໂຊເຟີ້(2)(ຊື່ບັນຊີ): {value?.driverTwo?.account_bank_name || "-----"}</div>
                              <div>ໂຊເຟີ້(2)(ເລກບັນຊີ):{value?.driverTwo?.account_bank_number || "-----"}</div>
                            </td> */}
                            <td className="text-center text-nowrap">
                              <div>{value?.consolidateRouteID?.title || "-----"}</div>
                              <hr />
                              <div>ຈຳນວນສາຍ: 1 ສາຍ</div>
                            </td>
                            <td className="text-center text-nowrap">
                              {value?.checkRoute === "IN" ? (
                                <span>{value?.priceNight || 0}</span>
                              ) : value?.checkRoute === "OUT" ? (
                                <span>{value?.priceNight || 0}</span>
                              ) : (
                                <span>{value?.priceNight || 0}</span>
                              )}
                            </td>
                            <td className="text-center text-nowrap">
                              <div>{value?.petroleum || 0}</div>
                            </td>
                            <td className="text-center">
                              <div>{value?.consolidateTotalRoute || 0}</div>
                            </td>
                            <td className="text-nowrap">
                              <div>ລວມມູນຄ່າຖ້ຽວ: {value?.consolidateAmountRoute ? currency(value?.consolidateAmountRoute) : 0} ກີບ</div>
                              <div>ເງິນເດືອນຂອງໂຊເຟີ້(1): {value?.basicSalaryOne ? currency(value?.basicSalaryOne) : 0} ກີບ</div>
                              <div>ເງິນເພີ່ມ(ລວມ): {value?.extraPayment ? currency(value?.extraPayment) : 0} ກີບ</div>
                              <div className="text-danger">ເງິນຫັກ(ລວມ): {value?.deductionPayment ? currency(value?.deductionPayment) : 0} ກີບ</div>
                              <div className="text-success">
                                ເງິນລວມສຸດທິ: {value?.paymentBalanceAmount ? currency(value?.paymentBalanceAmount) : 0} ກີບ
                              </div>
                            </td>
                            <td className="text-nowrap">
                              <div>ລວມມູນຄ່າຖ້ຽວ: {value?.consolidateAmountRouteTwo ? currency(value?.consolidateAmountRouteTwo) : 0} ກີບ</div>
                              <div>ເງິນເດືອນຂອງໂຊເຟີ້(2): {value?.basicSalaryTwo ? currency(value?.basicSalaryTwo) : 0} ກີບ</div>
                              <div>ເງິນເພີ່ມ(ລວມ): {value?.extraPaymentTwo ? currency(value?.extraPaymentTwo) : 0} ກີບ</div>
                              <div className="text-danger">
                                ເງິນຫັກ(ລວມ): {value?.deductionPaymentTwo ? currency(value?.deductionPaymentTwo) : 0} ກີບ
                              </div>
                              <div className="text-success">
                                ເງິນລວມສຸດທິ: {value?.paymentBalanceAmountTwo ? currency(value?.paymentBalanceAmountTwo) : 0} ກີບ
                              </div>
                            </td>
                            {/* <td className="text-nowrap">
                              <div>{value?.consolidateDate ? formatDate(value?.consolidateDate) : "-----"}</div>
                              <div>
                                {value?.startDate ? formatDate(value?.startDate) : "----"} ຫາ {value?.endDate ? formatDate(value?.endDate) : "-----"}
                              </div>
                            </td> */}
                          </tr>
                        ))}
                    </>
                  </tbody>
                </table>
              </>
            ) : null}
            <br />
            <h4 className="" style={{ marginLeft: "50px" }}>ລາຍງານຍອດເງິນໄດ້ຮັບສຸດທິຂອງໂຊເຟີ້</h4>
            <table className="table" style={{ fontSize: "10px" }} border={1}>
              <thead className="text-white">
                <tr style={{ fontSize: "13px" }}>
                  <th>ລຳດັບ</th>
                  <th>ຊື່ໂຊເຟີ້</th>
                  <th>ຊື່ສາຍ</th>
                  <th>ເງິນສຸດທິຂອງໂຊເຟີ້(ກີບ)</th>
                  <th>ຊື່ບັນຊີທະນາຄານ</th>
                  <th>ເລກບັນຊີທະນາຄານ</th>
                  <th>ໝາຍເຫດ</th>
                </tr>
              </thead>
              <tbody>
                {items?.map((item: any, index: number) => (
                  <>
                    <tr key={index} style={{ fontSize: "10px" }}>
                      <td className="text-nowrap">{index + 1}</td>
                      <td className="text-nowrap">
                        <div> {item?.consolidateDriverID?.first_name || ""}</div>
                        <div> {item?.driverTwo?.first_name || ""}</div>
                      </td>
                      <td>{item?.consolidateRouteID?.title || "-----"}</td>
                      <td className="text-nowrap">
                        <div>{item?.paymentBalanceAmount ? `${currency(item?.paymentBalanceAmount)} ກີບ` : ""}</div>
                        <br />
                        <span>{item?.paymentBalanceAmountTwo ? `${currency(item?.paymentBalanceAmountTwo)} ກີບ` : ""} </span>
                      </td>
                      <td className="text-nowrap">
                        <div>{item?.consolidateDriverID?.account_bank_name || ""}</div>
                        <div>{item?.driverTwo?.account_bank_name || ""}</div>
                      </td>
                      <td className="text-nowrap">
                        <div>ເລກບັນຊີ:{item?.consolidateDriverID?.account_bank_number || ""}</div>
                        <div>
                          {item?.driverTwo?.account_bank_number ? "ເລກບັນຊີ" : ""} {item?.driverTwo?.account_bank_number || ""}
                        </div>
                      </td>
                      <td className="text-nowrap"></td>
                    </tr>
                  </>
                ))}
                <tr>
                  <td colSpan={3}>
                    <div style={{ marginInlineStart: 80 }}>ໂຊເຟີ້ລວມ: {TotalDrivers || ""} ຄົນ</div>
                  </td>
                  <td colSpan={4}>
                    <div>ຍອດລວມສຸດທິ: {currency(totalBalance)} ກີບ</div>
                    <div></div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="row" style={{ height: 200 }}>
              <div className="col-sm-3 text-center mt-4">
                <h6>ເຊັນຜູ້ຈັດການທົ່ວໄປ</h6>
                <br />
              </div>
              <div className="col-sm-3 text-center mt-4">
                <h6>ເຊັນຫົວໜ້າພະແນກຂົນສົ່ງ</h6>
                <br />
              </div>
              <div className="col-sm-3 text-center mt-4">
                <h6>ຫົວໜ້າໜວ່ຍງານຕິດຕາມລົດຮ່ວມ</h6>
                <br />
              </div>
              <div className="col-sm-3 text-center mt-4">
                <h6>ຜູ້ສະຫຼຸບ</h6>
                <br />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-3" style={{ marginTop: -180 }}>
                {SignatureManager ? (
                  <>
                    {signatureManager?.map((value: any, index: number) => (
                      <>
                        <div className="text-center">
                          <Image src={url_upload_Profile + value?.signatureTwo} width={280} height={180} />
                        </div>
                      </>
                    ))}
                  </>
                ) : (
                  <>
                    {" "}
                    <div className="text-center mt-5">
                      {/* <ManagerSignature
                        getGenerate={getGenerate}
                        bilNoToday={bilNoToday}
                        vehicle={vehicle}
                        NewNoID={NewNoID}
                        _endConsolidate={_endConsolidate}
                        _startConsolidate={_startConsolidate}
                      /> */}
                    </div>
                  </>
                )}
              </div>
              <div className="col-sm-3" style={{ marginTop: -180 }}>
                {SignatureAdmin ? (
                  <>
                    {signatureAmin?.map((value: any, index: number) => (
                      <>
                        <div className="text-center">
                          <Image src={url_upload_Profile + value?.signatureTwo} width={280} height={180} />
                        </div>
                      </>
                    ))}
                  </>
                ) : (
                  <>
                    {" "}
                    <div className="text-center mt-5">
                      {/* <AdministratorSignature
                        getGenerate={getGenerate}
                        bilNoToday={bilNoToday}
                        vehicle={vehicle}
                        NewNoID={NewNoID}
                        _endConsolidate={_endConsolidate}
                        _startConsolidate={_startConsolidate}
                      /> */}
                    </div>
                  </>
                )}
              </div>
              <div className="col-sm-3" style={{ marginTop: -180 }}>
                {SignatureObserver ? (
                  <>
                    {signatureObserve?.map((value: any, index: number) => (
                      <>
                        <div className="text-center">
                          <Image src={url_upload_Profile + value?.signatureTwo} width={280} height={180} />
                        </div>
                      </>
                    ))}
                  </>
                ) : (
                  <>
                    {" "}
                    <div className="text-center mt-5">
                      {/* <ObserveSignature
                        getGenerate={getGenerate}
                        bilNoToday={bilNoToday}
                        vehicle={vehicle}
                        NewNoID={NewNoID}
                        _endConsolidate={_endConsolidate}
                        _startConsolidate={_startConsolidate}
                      /> */}
                    </div>
                  </>
                )}
              </div>
              <div className="col-sm-3" style={{ marginTop: -180 }}>
                {SignatureTwo ? (
                  <>
                    {signature?.map((value: any, index: number) => (
                      <>
                        <div className="text-center">
                          <Image src={url_upload_Profile + value?.signatureTwo} width={280} height={180} />
                        </div>
                      </>
                    ))}
                  </>
                ) : (
                  <>
                    {" "}
                    <div className="text-center mt-5">
                      {/* <SummarySignature
                        getGenerate={getGenerate}
                        bilNoToday={bilNoToday}
                        vehicle={vehicle}
                        consolidateID={consolidateID}
                        _endConsolidate={_endConsolidate}
                        _startConsolidate={_startConsolidate}
                      /> */}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

// downloadFileTransfer

function DownloadFile({ items }: any) {
  const gridRef: any = useRef();
  const array: any = [];
  for (const _data of items) {
    const gridOptions = {
      additionalRawString: _data?.driverTwo?.account_bank_number,
      balanceTwo: Number(_data?.paymentBalanceAmountTwo),
      nameTwo: _data?.driverTwo?.account_bank_name,
      rawString: _data?.consolidateDriverID?.account_bank_number,
      nameString: _data?.driverTwo?.first_name + _data?.driverTwo?.last_name,
      rowData: Number(_data?.paymentBalanceAmount),
      negativeValue: _data?.consolidateDriverID?.account_bank_name,
      dateValue: _data?.consolidateDriverID?.first_name + _data?.consolidateDriverID?.last_name,
    };
    array.push(gridOptions);
  }

  const [columnDefs]: any = useState([
    {
      headerName: "ເລກບັນຊີ",
      field: "rawString",
      cellClass: "stringType",
    },
    { headerName: "ຈຳນວນເງິນທີໄດ້ຮັບ", field: "rowData", cellClass: "numberType" },
    { headerName: "ຊື່ບັນຊີ", field: "negativeValue" },
    { headerName: "ຊື່ແລະນາມສະກຸນ", field: "dateValue", cellClass: "string" },
    {
      headerName: "ເລກບັນຊີໂຊເຟີ້(2)",
      field: "additionalRawString",
      cellClass: "stringType",
    },
    {
      headerName: "ຈຳນວນເງິນທີໄດ້ຮັບ(2)",
      field: "balanceTwo",
      cellClass: "numberType",
    },
    {
      headerName: "ຊື່ບັນຊີ(2)",
      field: "nameTwo",
    },
    {
      headerName: "ຊື່ແລະນາມສະກຸນ(2)",
      field: "nameString",
      cellClass: "stringType",
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
      resizable: true,
    };
  }, []);

  const excelStyles: any = useMemo(() => {
    return [
      {
        id: "numberType",
        numberFormat: {
          format: "0",
        },
      },
      {
        id: "stringType",
        dataType: "String",
      },
    ];
  }, []);

  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  const onBtExport = useCallback(() => {
    gridRef.current.api.exportDataAsExcel();
  }, []);
  return (
    <>
      <AgGridReact
        ref={gridRef}
        rowData={array}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        excelStyles={excelStyles}
        popupParent={popupParent}
      />
      <Button onClick={() => onBtExport()} variant="info" className="btn btn-info me-2">
        <i className="fas fa-download me-1" />
        ດາວໂຫຼດໄຟລ໌
      </Button>
    </>
  );
}
