import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import { Gender, currency, formatDash, formatDate, generateBillNo, getStaffLogin } from "../../utils";
import { useReactToPrint } from "react-to-print";
import { BiPrinter } from "react-icons/bi";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { BsFileEarmarkExcel } from "react-icons/bs";
import Logo from "../../assets/images/ans-logo-red.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ParentPath } from "../../routes/Path";
import { useLazyQuery } from "@apollo/client";
import Schema from "./Schema";
import _ from "lodash";

export default function PrintDetails() {
  const navigate = useNavigate();
  const elmRef = useRef<HTMLDivElement>(null);
  let today = moment().format("YYYY-MM-DD");
  let time = moment().format("HH:mm:ss");
  const getLogin = getStaffLogin();
  const handlePrint = useReactToPrint({
    content: () => elmRef.current,
  });
  const [ItemsConsolidate, setItemsConsolidate] = useState<any[]>();

  const [total, setTotal] = useState(0);
  const [ExtraConsolidate, setExtraConsolidate] = useState<any[]>();
  const [DeductionConsolidate, setDeductionConsolidate] = useState<any[]>();
  const [driverOne, setDriverOne] = useState<any[]>();
  const [params, setParams] = useSearchParams();
  const getConsolidateID = params.get("ConsolidateDriverID");
  const getDriverTwo = params.get("DriverTwo");
  const getDriverOne = params.get("driverOne");
  const getNo = params.get("No");
  const getBillNo = params.get("noID");
  let bilNoToday = moment().format("YYYYMMDD");
  const getGenerate = generateBillNo("");
  const status = params.get("Status");

  const [ConsolidateShipping, { data }] = useLazyQuery(Schema.SHIPPING_ROUTE_CONSOLIDATE, { fetchPolicy: "cache-and-network" });
  const [ExtraData, { data: results }] = useLazyQuery(Schema.EXTRA_PAYMENT_CONSOLIDATE, { fetchPolicy: "cache-and-network" });
  const [DeductionData, { data: deduction }] = useLazyQuery(Schema.DEDUCTION_PAYMENTS, { fetchPolicy: "cache-and-network" });
  const [driverData, { data: driver1 }] = useLazyQuery(Schema.QUERY_SUMMARY_SHIPPING_ROUTE, { fetchPolicy: "cache-and-network" });

  const [fetchData, { data: companyData }] = useLazyQuery(Schema.Query_company, { fetchPolicy: "cache-and-network" });

  const ServicesMoney = companyData?.driverPartners?.data[0]?.servicesMoney;

  useEffect(() => {
    fetchData({
      variables: {
        where: {
          cp_id: Number(status) ? Number(status) : undefined,
        },
      },
    });
  }, [status]);

  // get check status

  const getExtraStatus = results?.extraPayments?.data[0]?.exTraAmount;
  const getDeductionStatus = deduction?.deductionPayments?.data[0]?.deductionAmount;
  // driver one
  const first_name = driver1?.consolidateSummaries?.data[0]?.consolidateDriverID?.first_name;
  const gender = driver1?.consolidateSummaries?.data[0]?.consolidateDriverID?.gender;
  const last_name = driver1?.consolidateSummaries?.data[0]?.consolidateDriverID?.last_name;
  const account_bank_name = driver1?.consolidateSummaries?.data[0]?.consolidateDriverID?.account_bank_name;
  const account_bank_number = driver1?.consolidateSummaries?.data[0]?.consolidateDriverID?.account_bank_number;
  // driverTwo
  const first_nameTwo = driver1?.consolidateSummaries?.data[0]?.driverTwo?.first_name || null;
  const genderTwo = driver1?.consolidateSummaries?.data[0]?.driverTwo?.gender || null;
  const last_nameTwo = driver1?.consolidateSummaries?.data[0]?.driverTwo?.last_name || null;
  const account_bank_nameTwo = driver1?.consolidateSummaries?.data[0]?.driverTwo?.account_bank_name || null;
  const account_bank_numberTwo = driver1?.consolidateSummaries?.data[0]?.driverTwo?.account_bank_number || null;

  // Consolidate
  const Petroleum = _.sumBy(ItemsConsolidate, "petroleum");
  const ConsolidateAmount = _.sumBy(ItemsConsolidate, "amountValue");
  // extra payment
  const extraPayments = _.sumBy(ExtraConsolidate, "exTraAmount");
  // deduction payment
  const deductionPayments = _.sumBy(DeductionConsolidate, "deductionAmount");

  // driver one
  const SumConsolidateAmountRouteOne = _.sumBy(driverOne, "consolidateAmountRoute");
  const SumDeductionPaymentOne = _.sumBy(driverOne, "deductionPayment");
  const SumExtraPaymentOne = _.sumBy(driverOne, "extraPayment");
  const SumBasicSalaryOne = _.sumBy(driverOne, "basicSalaryOne");

  // and driverTwo

  const SumConsolidateAmountRouteTwo = _.sumBy(driverOne, "consolidateAmountRouteTwo");
  const SumDeductionPaymentTwo = _.sumBy(driverOne, "deductionPaymentTwo");
  const SumExtraPaymentTwo = _.sumBy(driverOne, "extraPaymentTwo");
  const SumBasicSalaryTwo = _.sumBy(driverOne, "basicSalaryTwo");

  // get percent service company

  const percent = (Number(ServicesMoney) * ConsolidateAmount) / 100;

  const basicSalaryAll = SumBasicSalaryOne + SumBasicSalaryTwo;

  const allTotal = ConsolidateAmount + extraPayments + Number(percent) + basicSalaryAll - deductionPayments;

  useEffect(() => {
    ConsolidateShipping({
      variables: {
        where: {
          consolidate_ID: Number(getConsolidateID) ? Number(getConsolidateID) : undefined,
        },
      },
    });
  }, [ConsolidateShipping, getConsolidateID]);
  useEffect(() => {
    setItemsConsolidate(data?.shippingRoutes.data || []);
    setTotal(data?.shippingRoutes.total || 0);
  }, [data]);

  // extra payment
  useEffect(() => {
    ExtraData({
      variables: {
        where: {
          exTraCheck: Number(getConsolidateID) ? Number(getConsolidateID) : undefined,
        },
      },
    });
  }, [ExtraData, getConsolidateID]);
  useEffect(() => {
    setExtraConsolidate(results?.extraPayments?.data || []);
  }, [results]);

  // deduction payment
  useEffect(() => {
    DeductionData({
      variables: {
        where: {
          deductionCheck: Number(getConsolidateID) ? Number(getConsolidateID) : undefined,
        },
      },
    });
  }, [DeductionData, getConsolidateID]);
  useEffect(() => {
    setDeductionConsolidate(deduction?.deductionPayments?.data || []);
  }, [deduction]);

  // consolidateSummary driver one and driver two
  useEffect(() => {
    driverData({
      variables: {
        where: {
          consolidateID: Number(getConsolidateID) ? Number(getConsolidateID) : undefined,
          consolidateDriverID: Number(getDriverOne) ? Number(getDriverOne) : undefined,
          driverTwo: Number(getDriverTwo) ? Number(getDriverTwo) : undefined,
        },
      },
    });
  }, [driverData, getConsolidateID, getDriverOne, getDriverTwo]);
  useEffect(() => {
    setDriverOne(driver1?.consolidateSummaries?.data || []);
  }, [driver1]);

  return (
    <React.Fragment>
      <div className="mb-3 flex-control-group">
        <button type="button" className="btn btn-primary ml-3 me-2" onClick={() => navigate(`${ParentPath.summarizeDestinationVehicle}`)}>
          <i className="fas fa-arrow-left"></i>ກັບຄືນ
        </button>
        <button onClick={handlePrint} type="button" className="btn btn-dark ml-3 me-2">
          <BiPrinter /> ພິມລາຍງານ
        </button>
        <DownloadTableExcel filename="ຂໍ້ມູນພະນັກງານ" sheet="users" currentTableRef={elmRef.current}>
          <button className="btn btn-success">
            <BsFileEarmarkExcel /> Export excel{" "}
          </button>
        </DownloadTableExcel>
      </div>
      &nbsp;&nbsp;
      <div ref={elmRef} id="table-to-xls" className="container-print" style={{ fontFamily: "phetsarath OT" }}>
        <div className="text-center fw-bold mt-3" style={{ fontSize: "1.8rem"}}>
          ລາຍງານຂໍ້ມູນສະຫລຸບລົດ
        </div>
        <div style={{marginLeft: "50px"}}>
          <img style={{ width: 150 }} src={Logo} alt="" />
        </div>
        &nbsp;
        <div>
          <div style={{marginLeft: "50px"}}>
            <div>ບໍລິສັດ ອານຸສິດ ໂລຈິສຕິກ ຈຳກັດ.</div>
            <div>ບ້ານ ໂພນສະອາດ, ເມືອງ ຈັນທະບູລີ, ນະຄອນຫຼວງວຽງຈັນ</div>
            <div className="text-end" style={{marginRight: "50px"}}>
              ເລກທີ່: ANS-IV{getNo}
              {bilNoToday}
              {getGenerate}
            </div>
            <div className="text-end" style={{marginRight: "50px"}}>
              ພິມໂດຍ: {getLogin?.first_name} {getLogin?.last_name}
            </div>
            <div className="text-end" style={{marginRight: "50px"}}>
              ວັນທີພິມ: {time} , {today}
            </div>
          </div>
        </div>
        &nbsp;&nbsp;
        <table className="table" style={{ fontSize: "10px" }} border={1}>
          <thead>
            <tr>
              <th>ລຳດັບ</th>
              <th>ເບີລົດ</th>
              <th>ສາຍທີ່ແລ່ນ</th>
              <th>ເລກທີໂກຕ່າ</th>
              <th>ປະເພດສາຍ</th>
              <th>ກະອອກລົດ</th>
              <th>ປະເພດຖ້ຽວ</th>
              <th>ນ້ຳມັນ(ລິດ)</th>
              <th>ລາຄາຕໍ່ຖ້ຽວໃນສາຍ(ກີບ)</th>
              <th className="text-start">ວັນທີຈັດຖ້ຽວ</th>
              <th>ໝາຍເຫດ</th>
            </tr>
          </thead>
          <tbody>
            {ItemsConsolidate?.map((value, index) => (
              <>
                <tr key={index}>
                  <td className="text-center">{index + 1}</td>
                  <td className="text-nowrap">{value?.vehicleID?.vehicleName || "----"}</td>
                  <td className="text-nowrap">{value?.routeLocationID?.title || "----"}</td>
                  <td className="text-nowrap">{value?.billNoRout || "-----"}</td>
                  <td className="text-nowrap">{value?.checkRoute === "IN" ? "ສາຍໃນ" : value?.checkRoute === "OUT" ? "ສາຍນອກ" : "ສາຍສຳຮອງ"}</td>
                  <td className="text-nowrap">{value?.isDay === "0" ? "ພາກເຊົ້າ" : "ພາກແລງ"}</td>
                  <td className="text-nowrap">{value?.isDif === "0" ? "ຖ້ຽວປົກກະຕິ" : "ຖ້ຽວເສີມ"}</td>
                  <td className="text-nowrap text-center">{value?.petroleum || 0}</td>
                  <td className="text-nowrap">{currency(value?.amountValue || 0)} ກີບ</td>
                  <td className="text-nowrap">{value?.createdAt ? formatDash(value?.createdAt) : "----"}</td>
                  <td style={{ width: "150px" }}>{value.difNote || "----"}</td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
        <div className="row">
          <div className="col-sm-8">
            {getExtraStatus ? (
              <>
                <h4 style={{ textDecoration: "underline black" }}>ລາຍງານການເພີ່ມເງິນ</h4>
                &nbsp;&nbsp;
                <table className="table" style={{ fontSize: "10px" }} border={1}>
                  <thead>
                    <tr>
                      <th className="text-nowrap">ລຳດັບ</th>
                      <th className="text-nowrap">ໂຊເຟີ້</th>
                      <th className="text-nowrap">ຈຳນວນເງິນ</th>
                      <th className="text-nowrap">ລາຍລະອຽດ</th>
                      <th className="text-nowrap">ວັນທີສ້າງ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ExtraConsolidate?.map((value, index) => (
                      <>
                        <tr>
                          <td className="no">{index + 1}</td>
                          <td className="text-nowrap">
                            {value?.extraDriverID?.gender ? Gender(value?.extraDriverID?.gender) : ""}&nbsp;
                            {value?.extraDriverID?.first_name || "----"}&nbsp; {value?.extraDriverID?.last_name || "----"}
                          </td>
                          <td className="text-nowrap">{currency(value?.exTraAmount || 0)} ກີບ</td>
                          <td>{value?.exTraNote || "----"}</td>
                          <td className="text-nowrap">{value?.exTraCreatedAt ? formatDate(value?.exTraCreatedAt) : "----"}</td>
                        </tr>
                      </>
                    ))}
                    <tr>
                      <td colSpan={2} className="text-center">
                        ຍອດລວມທັງໝົດ
                      </td>
                      <td colSpan={4}>{currency(extraPayments) || 0} ກີບ</td>
                    </tr>
                  </tbody>
                </table>
              </>
            ) : null}
            {/* deduction */}
            {getDeductionStatus ? (
              <>
                <h4 style={{ textDecoration: "underline black" }}>ລາຍງານການຫັກເງິນ</h4>
                &nbsp;&nbsp;
                <table className="table" style={{ fontSize: "10px" }} border={1}>
                  <thead>
                    <tr>
                      <th className="text-nowrap">ລຳດັບ</th>
                      <th className="text-nowrap">ໂຊເຟີ້</th>
                      <th className="text-nowrap">ຈຳນວນເງິນ</th>
                      <th className="text-nowrap">ລາຍລະອຽດ</th>
                      <th className="text-nowrap">ວັນທີສ້າງ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {DeductionConsolidate?.map((value, index) => (
                      <>
                        <tr key={index}>
                          <td className="no">{index + 1}</td>
                          <td className="text-nowrap">
                            {value?.deductionDriverID?.gender ? Gender(value?.deductionDriverID?.gender) : ""}&nbsp;
                            {value?.deductionDriverID?.first_name || "----"}&nbsp;{value?.deductionDriverID?.last_name || "----"}
                          </td>
                          <td className="text-nowrap">{currency(value?.deductionAmount || 0)} ກີບ</td>
                          <td>{value?.deductionNote || "----"}</td>
                          <td className="text-nowrap">{value?.deductionCreatedAt ? formatDate(value?.deductionCreatedAt) : "----"}</td>
                        </tr>
                      </>
                    ))}
                    <tr>
                      <td colSpan={2} className="text-center">
                        ຍອດລວມທັງໝົດ
                      </td>
                      <td colSpan={4}>{currency(deductionPayments) || 0} ກີບ</td>
                    </tr>
                  </tbody>
                </table>
              </>
            ) : null}
            {/* ທັງໝົດ  */}
            <h4 style={{ textDecoration: "underline black" }} className="mt-4">
              ລາຍງານຍອດເງິນໄດ້ຮັບສຸດທິຂອງໂຊເຟີ້
            </h4>
            &nbsp;&nbsp;
            <table className="table" style={{ fontSize: "10px", marginLeft: "50px" }} border={1}>
              <thead>
                <th>ລຳດັບ</th>
                <th>ໂຊເຟີ້</th>
                <th>ຊື່ບັນຊີທະນາຄານ</th>
                <th>ເລກບັນຊີທະນາຄານ</th>
                <th>ເງິນໄດ້ຮັບສຸດທິ(ກີບ)</th>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td className="text-nowrap">
                    {gender ? Gender(gender) : ""} {first_name}&nbsp;
                    {last_name}
                  </td>
                  <td className="text-nowrap">{account_bank_name}</td>
                  <td className="text-nowrap">ເລກບັນຊີ:{account_bank_number}</td>
                  <td className="text-nowrap">
                    <div>ຄ່າຖ້ຽວ: {currency(SumConsolidateAmountRouteOne)} ກີບ</div>
                    <hr />
                    <div>ເງິນເດືອນ:{currency(SumBasicSalaryOne) || 0} ກີບ</div>
                    <hr />
                    <div>ເງິນເພີ່ມ: {currency(SumExtraPaymentOne) || 0}ກີບ</div>
                    <hr />
                    <div>ເງິນຫັກ: {currency(SumDeductionPaymentOne) || 0}ກີບ</div>
                    <hr />
                    <div>ເງິນໄດ້ຮັບສຸດທິ: {currency(allTotal) || 0}ກີບ</div>
                  </td>
                </tr>
                {first_nameTwo !== null ? (
                  <>
                    <tr>
                      <td>2</td>
                      <td className="text-nowrap">
                        {genderTwo ? Gender(genderTwo) : ""} {first_nameTwo}&nbsp;
                        {last_nameTwo}
                      </td>
                      <td className="text-nowrap">{account_bank_nameTwo}</td>
                      <td className="text-nowrap">ເລກບັນຊີ:{account_bank_numberTwo}</td>
                      <td className="text-nowrap">
                        <div>ຄ່າຖ້ຽວ: {currency(SumConsolidateAmountRouteTwo)} ກີບ</div>
                        <hr />
                        <div>ເງິນເດືອນ:{currency(SumBasicSalaryTwo) || 0} ກີບ</div>
                        <hr />
                        <div>ເງິນເພີ່ມ: {currency(SumExtraPaymentTwo) || 0}ກີບ</div>
                        <hr />
                        <div>ເງິນຫັກ: {currency(SumDeductionPaymentTwo) || 0}ກີບ</div>
                        <hr />
                        <div>ເງິນໄດ້ຮັບສຸດທິ: {currency(allTotal) || 0}ກີບ</div>
                      </td>
                    </tr>
                  </>
                ) : null}
              </tbody>
            </table>
          </div>
          {/* <div className="col-sm-4 mt-3 ">
            <table className="table" style={{fontSize : "10px"}} border={1}>
              <tr>
                <td>
                  <div className="d-flex justify-content-between">
                    <span> ລວມນ້ຳມັນ:</span>
                    <span> {currency(Petroleum || 0)} ລິດ</span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span> ຈຳນວນຖ້ຽວ:</span>
                    <span> {total} ຖ້ຽວ</span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span> ລວມຄ່າຖ້ຽວ:</span>
                    <span> {currency(ConsolidateAmount || 0)} ກີບ</span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span> ເງິນເດືອນພື້ນຖານ(ລວມ):</span>
                    <span> {currency(basicSalaryAll || 0)} ກີບ</span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span> ລວມເງິນເພີ່ມ:</span>
                    <span> {currency(extraPayments || 0)} ກີບ</span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span> ລວມເງິນຫັກ:</span>
                    <span> {currency(deductionPayments || 0)} ກີບ</span>
                  </div>
                  <hr />
                  {ServicesMoney !== null ? (
                    <div className="d-flex justify-content-between">
                      <span> ຄ່າບໍລິຫານ(4%):</span>
                      <span> {currency(percent) || 0}</span>
                    </div>
                  ) : null}
                  <div className="d-flex justify-content-between">
                    <span> ຍອດລວມທັງໝົດ: </span>
                    <span>{currency(allTotal || 0)} ກີບ</span>
                  </div>
                </td>
              </tr>
            </table>
          </div> */}
        </div>
        <div className="row">
          <div className="col-sm-3 mt-4">
            <h6 className="text-center">ທີ່ປຶກສາ</h6>
          </div>
          <div className="col-sm-3  mt-4">
            <h6 className="text-center ">ຫົວໜ້າພະແນກຂົນສົ່ງ</h6>
          </div>
          <div className="col-sm-3  mt-4">
            <h6 className="text-center text-nowrap">ຫົວໜ້າຫນ່ວຍງານບໍລິຫານລົດຮ່ວມ</h6>
          </div>
          <div className="col-sm-3  mt-4">
            <h6 className="text-center">ຜູ້ສະຫຼຸບ</h6>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
