import { IoCloudDownloadSharp } from "react-icons/io5";
const ExcelJS = require("exceljs");

export default function DownloadFiles({ data, startDate, endDate }: any) {
  const exportExcelFile = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("ໄຟລ໌ibank");
    sheet.columns = [
      {
        key: "account_bank_number1",
      },
      {
        key: "paymentBalanceAmount1",
      },
      {
        key: "account_bank_name1",
      },

      {
        key: "consolidateRouteID1",
      },
    ];
    const promise = Promise.all(
      data?.map(async (dataLoop: any, index: Number) => {
        sheet.addRow({
          account_bank_number1: dataLoop?.consolidateDriverID?.account_bank_number,
          paymentBalanceAmount1: dataLoop?.paymentBalanceAmount,
          account_bank_name1: dataLoop?.consolidateDriverID?.account_bank_name,
          consolidateRouteID1: dataLoop?.consolidateRouteID?.title,
        });
      })
    );
    promise.then(() => {
      workbook.xlsx.writeBuffer().then(function (data: any) {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = `ຂໍ້ມູນສະຫຼຸບຊຳລະຖ້ຽວລົດງວດວັນທີ ${startDate} ຫາ ${endDate}`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    });
  };
  return (
    <>
      <button className="btn btn-success btn-sm  float-center" onClick={exportExcelFile}>
        <IoCloudDownloadSharp />
        ດາວໂຫຼດໄຟລ໌ IBank
      </button>
    </>
  );
}
