import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { endOfMonth, startOfMonth } from "..";

interface SearchDateRangeProps {
  eMonth?: boolean;
  sMonth?: boolean;
  hideStartDate?: boolean;
  hideEndDate?: boolean;
  containerClass?: string;
  containerStyle?: React.CSSProperties;
  isToday?: boolean;
  onDateChange?: (startDate: string, endDate: string) => void;
}

export default function SearchDateRangeBetweenConsolidate({
  eMonth,
  sMonth,
  hideStartDate,
  hideEndDate,
  containerClass,
  containerStyle,
  isToday,
  onDateChange,
}: SearchDateRangeProps) {
  const [defaultStartDate, setDefaultStartDate] = useState("");
  const [defaultEndDate, setDefaultEndDate] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const _start = searchParams.get("startConsolidateDate");
    const _end = searchParams.get("endConsolidateDate");
    const _endOfMonth = eMonth ? endOfMonth("YYYY-MM-DD") : "";
    const _startOfMonth = sMonth ? startOfMonth("YYYY-MM-DD") : "";
    setDefaultStartDate(_start ? _start : _startOfMonth);
    setDefaultEndDate(_end ? _end : _endOfMonth);
  }, [searchParams, isToday, eMonth, sMonth]);

  const handleDateChange = (key: string, value: string) => {
    searchParams.set(key, value);
    if (searchParams.get("page")) {
      searchParams.set("page", "1");
    }
    setSearchParams(searchParams);
    if (key === "startConsolidateDate") {
      setDefaultStartDate(value);
    } else if (key === "endConsolidateDate") {
      setDefaultEndDate(value);
    }
    if (onDateChange) {
      onDateChange(defaultStartDate, defaultEndDate);
    }
  };

  const handleSearch = () => {
    if (searchParams.get("page")) {
      searchParams.set("page", "1");
    }
    searchParams.set("startDate", defaultStartDate);
    searchParams.set("endDate", defaultEndDate);
    setSearchParams(searchParams);
  };

  return (
    <div className={`search-date-range ${containerClass}`} style={containerStyle}>
      <i className="fas fa-search" />
      ຄົ້ນຫາຂໍ້ມູນຈາກວັນທີເລືອກສະຫຼຸບ
      <div className="input-group">
        {!hideStartDate && (
          <Form.Control
            type="date"
            value={defaultStartDate}
            onChange={(e) => {
              handleDateChange("startConsolidateDate", e.target.value);
            }}
          />
        )}
        {!hideEndDate && (
          <Form.Control
            type="date"
            value={defaultEndDate}
            onChange={(e) => {
              handleDateChange("endConsolidateDate", e.target.value);
            }}
          />
        )}
        <Button disabled={!defaultStartDate || !defaultEndDate} onClick={handleSearch}>
          <i className="fas fa-search" />
          ຄົ້ນຫາ
        </Button>
      </div>
    </div>
  );
}
