import { useState, useEffect } from "react";
import { Button, Spinner } from "react-bootstrap";
import Schema from "./Schema";
import { useLazyQuery } from "@apollo/client";
import { Calculator, Gender, RootVariable, checkUserRole, currency, formatDate, formatDateTime } from "../../../utils";
import TableData from "../../../utils/components/TableData";
import { useSearchParams } from "react-router-dom";
import SearchRouteLocation from "../../../utils/components/SelectRouteLocation";
import SelectNumberCar from "../../../utils/components/SelectNumberCar";
import moment from "moment";
import SearchDateRange from "../../../utils/components/SearchDateRange";
import { UPDATE_PRICE_ROUTE, UPDATE_PRICE_SHIPPING_ALL } from "./component";

export default function ModifyPriceRoute() {
  const [items, setItems] = useState<any[]>();
  const [total, setTotal] = useState(0);
  const [numberRow, setNumberRow] = useState(RootVariable.limit);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [reloading, setReloading] = useState(false);
  const [RouteLocation, setRouteLocation] = useState("");
  const [SelectCheck, setSelectChecking] = useState<any>();
  const [SelectCheckRoute, setSelectCheckRoute] = useState<any>();
  const [SelectVehicle, setSelectVehicle] = useState<any>();
  const [SelectIsDay, setSelectIsDay] = useState<any>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [dateBetween, setDateBetween] = useState<string[]>([]);
  const [FetchData, { data, loading }] = useLazyQuery(Schema.QUERY_SHIPPING_ROUTES, { fetchPolicy: "cache-and-network" });

  useEffect(() => {
    if (dateBetween?.length !== 2) return;
    const vehicleID = SelectVehicle === 0 ? undefined : Number(SelectVehicle);
    FetchData({
      variables: {
        where: {
          isDay: SelectIsDay ? SelectIsDay : undefined,
          checkRoute: SelectCheckRoute ? SelectCheckRoute : undefined,
          vehicleID: vehicleID ? vehicleID : undefined,
          checkScan: Number(SelectCheck) ? Number(SelectCheck) : undefined,
          routeLocationID: Number(RouteLocation) ? Number(RouteLocation) : undefined,
          createdDateBetween: [dateBetween[0], dateBetween[1]],
          acceptStatus: "ACCEPTED",
        },

        limit: searchValue ? 0 : numberRow,
        skip: searchValue ? 0 : numberRow * (pageNumber - 1),
      },
    });
  }, [
    FetchData,
    reloading,
    searchValue,
    SelectCheckRoute,
    numberRow,
    pageNumber,
    SelectVehicle,
    SelectIsDay,
    SelectCheck,
    RouteLocation,
    dateBetween,
  ]);

  useEffect(() => {
    if (loading) {
      setItems([]);
      setTotal(0);
      return;
    }
    setItems(data?.shippingRoutes?.data || []);
    setTotal(data?.shippingRoutes?.total || 0);
  }, [data, loading]);

  useEffect(() => {
    const _page = searchParams.get("page");
    const _row = searchParams.get("row");
    const _routeLocation = searchParams.get("routeLocation");
    const _vehicleID = searchParams.get("vehicle");
    const _isDay = searchParams.get("IsDay");
    const _checkRoute = searchParams.get("CheckRoute");
    const _checking = searchParams.get("Checking");

    const decodeChecking = _checking ? atob(_checking) : undefined;
    const decodeCheckRoute = _checkRoute ? atob(_checkRoute) : undefined;
    const decodeRouteLocation = _routeLocation ? atob(_routeLocation) : "";
    const decodeVehicle = _vehicleID ? atob(_vehicleID) : undefined;
    const decodeIsDay = _isDay ? atob(_isDay) : undefined;

    setSelectChecking(decodeChecking ? decodeChecking : undefined);
    setSelectCheckRoute(decodeCheckRoute ? decodeCheckRoute : undefined);
    setSelectIsDay(decodeIsDay ? decodeIsDay : undefined);
    setSelectVehicle(decodeVehicle ? decodeVehicle : undefined);
    setRouteLocation(decodeRouteLocation ? decodeRouteLocation : "");

    setPageNumber(_page ? parseInt(_page) : 1);
    setNumberRow(_row ? parseInt(_row) : RootVariable.limit);
    const _startDate = searchParams.get("startDate");
    const _endDate = searchParams.get("endDate");
    if (_startDate && _endDate) setDateBetween([_startDate, _endDate]);
    else setDateBetween([moment().subtract(30, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")]);
  }, [searchParams]);

  return (
    <>
      <div className="page-header">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">ໜ້າຫຼັກ</li>
          <li className="breadcrumb-item">ຈັດການຖ້ຽວລົດ</li>
        </ol>
      </div>
      <div className="main-container mt-3">
        <div className="card">
          <div className="card-header">
            <div className="row">
              <div className="col-sm-8">
                <h3 style={{ textAlign: "left" }}>ແກ້ໄຂລາຄາຖ້ຽວ</h3>
              </div>
              <div className="col-sm-4">
                <div style={{ float: "right" }}>
                  {checkUserRole(["SUPER_ADMIN"]) ? (
                    <UPDATE_PRICE_SHIPPING_ALL
                      items={items}
                      onSuccess={() => {
                        setReloading(!reloading);
                      }}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row blog">
              <div className="col-md-12">
                <div style={{ width: 500 }}>
                  <SearchDateRange />
                </div>
                &nbsp;&nbsp;
                <div className="row">
                  <div className="col-sm-3">
                    <label>ເລືອກສາຍລົດ</label>
                    <SearchRouteLocation
                      onChange={(option: any) => {
                        searchParams.set("routeLocation", btoa(option?.value));
                        setSearchParams(searchParams);
                      }}
                    />
                  </div>
                  <div className="col-sm-3">
                    <label htmlFor="#">ເລືອກຕາມເບີລົດ</label>
                    <SelectNumberCar
                      onChange={(option: any) => {
                        searchParams.set("vehicle", btoa(option.value));
                        setSearchParams(searchParams);
                      }}
                    />
                  </div>
                  <div className="col-md-2">
                    <label>ເລືອກຕາມກະອອກລົດ</label>
                    <select
                      className="form-control"
                      onChange={(e: any) => {
                        searchParams.set("IsDay", btoa(e.target.value));
                        setSearchParams(searchParams);
                      }}
                    >
                      <option value="">ທັງໝົດ</option>
                      <option value="0">ພາກເຊົ້າ</option>
                      <option value="1">ພາກເເລງ</option>
                    </select>
                  </div>
                  <div className="col-sm-2">
                    <label htmlFor="#">ປະເພດສາຍ</label>
                    <select
                      className="form-control"
                      onChange={(e: any) => {
                        searchParams.set("CheckRoute", btoa(e.target.value));
                        setSearchParams(searchParams);
                      }}
                    >
                      <option value="">ທັງໝົດ</option>
                      <option value="IN">ສາຍໃນ</option>
                      <option value="OUT">ສາຍນອກ</option>
                      <option value="SPARE">ສາຍສຳຮອງ</option>
                    </select>
                  </div>
                  <div className="col-sm-2">
                    <label htmlFor="#">ການກວດສອບ</label>
                    <select
                      className="form-control"
                      onChange={(e: any) => {
                        searchParams.set("Checking", btoa(e.target.value));
                        setSearchParams(searchParams);
                      }}
                    >
                      <option value="">ທັງໝົດ</option>
                      <option value="1">ກວດສອບ(ຜ່ານ)</option>
                      <option value="2">ກວດສອບ(ບໍ່ຜ່ານ)</option>
                    </select>
                  </div>
                </div>
                <br />
                <TableData
                  total={total}
                  loading={loading}
                  pageNumber={pageNumber}
                  numberRow={numberRow}
                  onSearchText={(val) => {
                    setSearchValue(val || "");
                  }}
                  header={
                    <tr>
                      <th className="text-nowrap text-center">ລຳດັບ</th>
                      <th className="text-nowrap text-center">ໂຊເຟີ້(1)</th>
                      <th className="text-nowrap text-center">ໂຊເຟີ້(2)</th>
                      <th className="text-nowrap text-center">ເບີລົດ</th>
                      <th className="text-nowrap text-center">ຜູ້ຈັດຖ້ຽວ</th>
                      <th className="text-nowrap text-center">ສາຍທີ່ແລ່ນ</th>
                      <th className="text-nowrap text-center">ເລກທີໂກຕ່າ</th>
                      <th className="text-nowrap text-center">ປະເພດສາຍ</th>
                      <th className="text-nowrap text-center">ກະອອກລົດ</th>
                      <th className="text-nowrap text-center">ປະເພດຖ້ຽວ</th>
                      <th className="text-nowrap text-center">ການກວດສອບ</th>
                      <th className="text-nowrap text-center">ກວດສອບໂດຍ</th>
                      <th className="text-nowrap text-center">ລາຄາຕໍ່ຖ້ຽວໃນສາຍ(ກີບ)</th>
                      <th className="text-nowrap text-center">ເວລາອອກຕົ້ນທາງ</th>
                      <th className="text-nowrap text-center">ເວລາຮອດປາຍທາງ</th>
                      <th className="text-nowrap text-center">ໃຊ້ເວລາເດີນທາງ</th>
                      <th className="text-nowrap text-center">ຢືນຢັນຮອດປາຍທາງ</th>
                      <th className="text-nowrap text-center">ວັນທີຈັດຖ້ຽວ</th>
                      <th className="text-nowrap text-center">ວັນທີກວດສອບ</th>
                      <th className="text-nowrap text-center">ວັນທີສະເເກນອອກ</th>
                      <th className="text-nowrap text-center">ໝາຍເຫດ</th>
                      <th className="text-nowrap text-center">ຢືນຢັນອອກໂດຍ</th>
                      <th className="text-nowrap text-center">ຢືນຢັນຮອດໂດຍ</th>
                      <th className="text-nowrap text-center">ສູນປາຍທາງ</th>
                      {checkUserRole(["SUPER_ADMIN"]) ? <th className="text-nowrap text-center">ຈັດການ </th> : null}
                    </tr>
                  }
                  body={
                    <>
                      {items?.map((value, index) => (
                        <tr key={index} className={value?.isDif === "1" ? "table-danger" : ""}>
                          <td className="text-center">{index + 1}</td>
                          <td className="text-nowrap">
                            {value?.driverID?.gender ? Gender(value?.driverID?.gender) : ""}&nbsp; {value?.driverID?.first_name || "----"}{" "}
                            {value?.driverID?.last_name || "----"}
                          </td>
                          <td className="text-nowrap">
                            {value?.driverTwo?.gender ? Gender(value?.driverTwo?.gender) : ""}&nbsp; {value?.driverTwo?.first_name || "----"}{" "}
                            {value?.driverTwo?.last_name || "----"}
                          </td>
                          <td className="text-nowrap">{value?.vehicleID?.vehicleName || "----"}</td>
                          <td className="text-nowrap">
                            {value?.createdBy?.gender ? Gender(value?.createdBy?.gender) : ""} {value?.createdBy?.first_name || "----"}{" "}
                            {value?.createdBy?.last_name || "----"}
                          </td>
                          <td className="text-nowrap">{value?.routeLocationID?.title || "----"}</td>
                          <td className="text-nowrap">{value?.billNoRout ? value?.billNoRout : "- - -"}</td>
                          <td className="text-center">
                            {(() => {
                              if (value?.checkRoute === "IN") {
                                return "ສາຍໃນ";
                              } else if (value?.checkRoute === "OUT") {
                                return "ສາຍນອກ";
                              } else {
                                return "ສາຍສຳຮອງ";
                              }
                            })()}
                          </td>
                          <td className="text-center">{value?.isDay === "0" ? "ພາກເຊົ້າ" : "ພາກເເລງ"}</td>
                          <td className={value?.isDif === "1" ? "text-danger text-center" : "text-center text-nowrap"}>
                            {value?.isDif === "1" ? "ຖ້ຽວເສີມ" : "ຖ້ຽວປົກກະຕິ"}
                          </td>
                          <td
                            style={{ textAlign: "center", whiteSpace: "nowrap" }}
                            className={
                              value?.checkScan === 0
                                ? "text-danger"
                                : value?.checkScan === 1
                                ? "text-success"
                                : value?.checkScan === 2
                                ? "text-danger"
                                : "-----"
                            }
                          >
                            {value?.checkScan === 0
                              ? "ຍັງບໍ່ກວດສອບ"
                              : value?.checkScan === 1
                              ? "ກວດສອບ(ຜ່ານ)"
                              : value?.checkScan === 2
                              ? "ກວດສອບ(ບໍ່ຜ່ານ)"
                              : null}
                          </td>
                          <td className="text-nowrap">
                            {value?.checkBy?.gender ? Gender(value?.checkBy?.gender) : ""} {value?.checkBy?.first_name || "----"}{" "}
                            {value?.checkBy?.last_name || "----"}
                          </td>
                          <td style={{ textAlign: "center" }} className="text-nowrap">
                            {currency(value?.amountValue || 0)} ກີບ
                          </td>
                          <td className="text-nowrap text-center">{value?.startTime ? formatDateTime(value.startTime) : "----"}</td>
                          <td className="text-nowrap text-center">{value?.endTime ? formatDateTime(value.endTime) : "----"}</td>

                          <td className="text-nowrap text-center">{value?.endTime ? Calculator(value?.startTime, value?.endTime) : "-----"}</td>

                          <td className="text-nowrap">
                            {" "}
                            {value?.newLocation === "NO" ? (
                              <>
                                <h5>
                                  <Button variant="danger" className="disable-cursor" size="sm">
                                    <Spinner animation="border" size="sm" variant="warning" /> ລົດກຳລັງເດີນທາງ...
                                  </Button>
                                </h5>
                              </>
                            ) : (
                              <>
                                <h5>
                                  <Button variant="success" className="disable-cursor" size="sm">
                                    <i className="fas fa-check-circle"></i> ຮອດປາຍທາງແລ້ວ
                                  </Button>
                                </h5>
                              </>
                            )}
                          </td>
                          <td className="text-center text-nowrap">{value?.createdAt ? formatDateTime(value?.createdAt) : "------"}</td>
                          <td className="text-center text-nowrap">{value?.checkDate ? formatDate(value?.checkDate) : "----"}</td>
                          <td className="text-nowrap text-center">{value?.dateScan_out ? formatDateTime(value.dateScan_out) : "----"}</td>
                          <td className="text-nowrap">{value?.difNote || "----"}</td>
                          <td className="text-nowrap">
                            {value?.acceptBy?.gender ? Gender(value?.acceptBy?.gender) : ""} {value?.acceptBy?.first_name || "----"}{" "}
                            {value?.acceptBy?.last_name || "----"}
                          </td>
                          <td className="text-nowrap">
                            {value?.acceptArrivedBy?.gender ? Gender(value?.acceptArrivedBy?.gender) : ""}{" "}
                            {value?.acceptArrivedBy?.first_name || "----"} {value?.acceptArrivedBy?.last_name || "----"}
                          </td>
                          <td className="text-nowrap">{value?.centerRoute?.centerName || "----"}</td>
                          {checkUserRole(["SUPER_ADMIN"]) ? (
                            <>
                              <td className="text-center text-nowrap">
                                <UPDATE_PRICE_ROUTE
                                  value={value}
                                  onSuccess={() => {
                                    setReloading(!reloading);
                                  }}
                                />
                              </td>
                            </>
                          ) : null}
                        </tr>
                      ))}
                    </>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
