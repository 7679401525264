import React, { useEffect, useState } from "react";
import TableData from "../../utils/components/TableData";
import { useLazyQuery } from "@apollo/client";
import { Calculator, Gender, RootVariable, checkUserRole, currency, formatDate, formatDateTime } from "../../utils";
import { useSearchParams } from "react-router-dom";
import Schema from "./Schema";
import { Button, Form } from "react-bootstrap";
import SelectNumberCar from "../../utils/components/SelectNumberCar";
import SearchDriverVehicle from "../../utils/components/SelectDriver";
import SearchDateRange from "../../utils/components/SearchDateRange";
import moment from "moment";
import { ExportTable, UPDATE_CHECK_ROUTE, UPDATE_VERIFY_ROUTE, UPDATE_VERIFY_ROUTE_BY_ADMIN } from "./component";

interface BranchInfo {
  branch_id: {
    branch_name: string;
  };
}

export default function CheckingDestinationVehicle() {
  const [items, setItems] = useState<any[]>();
  const [total, setTotal] = useState(0);
  const [showItems, setShowItems] = useState<Record<string, BranchInfo[]>>({});
  const [numberRow, setNumberRow] = useState(RootVariable.limit);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [reloading, setReloading] = useState(false);
  const [vehicle, setVehicle] = useState<any>();
  const [isDay, setIsDay] = useState<any>();
  const [user, setUser] = useState<any>();
  const [isDif, setIsDif] = useState<any>();
  const [checkScan, setCheckScan] = useState<any>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [dateBetween, setDateBetween] = useState<string[]>([]);

  const [FetchData, { data, loading }] = useLazyQuery(Schema.QUERY_SHIPPING_ROUTES, { fetchPolicy: "cache-and-network" });
  const [FetchDataShipping, { data: result }] = useLazyQuery(Schema.QUERY_HISTORY_SHIPPING, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (dateBetween?.length !== 2) return;
    const vehicleID = vehicle === 0 ? undefined : Number(vehicle);
    const userID = user === 0 ? undefined : Number(user);
    FetchData({
      variables: {
        where: {
          isDay: isDay ? isDay : undefined,
          driverID: userID ? userID : undefined,
          isDif: isDif ? isDif : undefined,
          checkScan: Number(checkScan) ? Number(checkScan) : undefined,
          vehicleID: vehicleID ? vehicleID : undefined,
          createdDateBetween: [dateBetween[0], dateBetween[1]],
          acceptStatus: "ACCEPTED",
          newLocation: "YES",
        },
        limit: searchValue ? 0 : numberRow,
        skip: searchValue ? 0 : numberRow * (pageNumber - 1),
      },
    });
  }, [FetchData, reloading, searchValue, numberRow, pageNumber, vehicle, isDay, user, isDif, checkScan, dateBetween]);

  useEffect(() => {
    if (loading) {
      setItems([]);
      setTotal(0);
    } else {
      setItems(data?.shippingRoutes?.data || []);
      setTotal(data?.shippingRoutes?.total || 0);
    }
  }, [data, loading]);

  useEffect(() => {
    if (items && items.length > 0) {
      const spIds = items.map((item) => item?.sp_id);
      FetchDataShipping({
        variables: {
          where: {
            sping_route: spIds,
            service: "OPEN",
          },
        },
      });
    }
  }, [items, FetchDataShipping]);

  useEffect(() => {
    if (result?.HistoryShippingRoutes?.data) {
      const updatedShowItems: Record<string, BranchInfo[]> = result.HistoryShippingRoutes.data.reduce(
        (acc: Record<string, BranchInfo[]>, item: any) => {
          if (item.sping_route && item.branch_id) {
            const branchInfo = {
              branch_id: {
                branch_name: item.branch_id.branch_name,
              },
            };
            acc[item.sping_route] = acc[item.sping_route] || [];
            acc[item.sping_route].push(branchInfo);
          }
          return acc;
        },
        {}
      );
      setShowItems(updatedShowItems);
    }
  }, [result]);

  useEffect(() => {
    const params = {
      page: searchParams.get("page"),
      row: searchParams.get("row"),
      vehicleID: searchParams.get("vehicle"),
      isDay: searchParams.get("IsDay"),
      isDif: searchParams.get("IsDif"),
      checkScan: searchParams.get("checkScan"),
      user: searchParams.get("user"),
      startDate: searchParams.get("startDate"),
      endDate: searchParams.get("endDate"),
    };

    setUser(params.user ? atob(params.user) : undefined);
    setCheckScan(params.checkScan ? atob(params.checkScan) : undefined);
    setIsDif(params.isDif ? atob(params.isDif) : undefined);
    setIsDay(params.isDay ? atob(params.isDay) : undefined);
    setVehicle(params.vehicleID ? atob(params.vehicleID) : undefined);
    setPageNumber(params.page ? parseInt(params.page) : 1);
    setNumberRow(params.row ? parseInt(params.row) : RootVariable.limit);
    setDateBetween([params.startDate || moment().subtract(30, "days").format("YYYY-MM-DD"), params.endDate || moment().format("YYYY-MM-DD")]);
  }, [searchParams]);

  const handleDateChange = (startDate: string, endDate: string) => {
    setDateBetween([startDate, endDate]);
  };

  return (
    <>
      <div className="page-header">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">ໜ້າຫຼັກ</li>
          <li className="breadcrumb-item">ກວດສອບຖ້ຽວລົດ</li>
        </ol>
      </div>
      <div className="main-container mt-3">
        <div className="card">
          <div className="card-header">
            <div className="row">
              <div className="col-sm-8">
                {" "}
                <h3 style={{ textAlign: "left" }}>ລາຍງານການກວດສອບຂໍ້ມູນຖ້ຽວລົດ</h3>
              </div>
              <div className="col-sm-4">
                <div style={{ float: "right" }}>
                  {checkUserRole(["SUPER_ADMIN", "ACCOUNTANT", "AUDITOR"]) ? <ExportTable loading={loading} data={data} /> : null}
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row blog p-2">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-sm-4">
                    <SearchDateRange onDateChange={handleDateChange} />
                  </div>
                  <div className="col-sm-2">
                    <label htmlFor="#">ເລືອກຕາມກະອອກລົດ</label>
                    <Form.Select
                      onChange={(e: any) => {
                        searchParams.set("IsDay", btoa(e?.target?.value));
                        setSearchParams(searchParams);
                      }}
                    >
                      <option>ເລືອກກະອອກລົດ</option>
                      <option value="0">ພາກເຊົ້າ</option>
                      <option value="1">ພາກເເລງ</option>
                    </Form.Select>
                  </div>
                  <div className="col-sm-2">
                    <label htmlFor="#">ເລືອກຕາມເບີລົດ</label>
                    <SelectNumberCar
                      onChange={(option: any) => {
                        searchParams.set("vehicle", btoa(option?.value));
                        setSearchParams(searchParams);
                      }}
                    />
                  </div>
                  <div className="col-sm-2">
                    <label htmlFor="#">ປະເພດສາຍ</label>
                    <Form.Select
                      onChange={(e: any) => {
                        searchParams.set("IsDif", btoa(e?.target?.value));
                        setSearchParams(searchParams);
                      }}
                    >
                      <option value="">ເລືອກປະເພດສາຍ</option>
                      <option value="0">ປົກກະຕິ</option>
                      <option value="1">ສາຍເສີມ</option>
                      <option value="2">ສາຍເສີມພິເສດ</option>
                    </Form.Select>
                  </div>
                  <div className="col-sm-2">
                    <label htmlFor="#">ການກວດສອບ</label>
                    <Form.Select
                      onChange={(e: any) => {
                        searchParams.set("checkScan", btoa(e?.target?.value));
                        setSearchParams(searchParams);
                      }}
                    >
                      <option value={""}>ເລືອກ</option>
                      <option value="1">ກວດສອບຜ່ານ</option>
                      <option value="2">ກວດສອບບໍ່ຜ່ານ</option>
                    </Form.Select>
                  </div>
                </div>
                &nbsp;
                <div className="row">
                  <div className="col-sm-4">
                    <label htmlFor="#">ເລືອກໂຊເຟີ້</label>
                    <SearchDriverVehicle
                      onChange={(option: any) => {
                        searchParams.set("user", btoa(option?.value));
                        setSearchParams(searchParams);
                      }}
                    />
                  </div>
                </div>
                &nbsp;
                <TableData
                  total={total}
                  loading={loading}
                  pageNumber={pageNumber}
                  numberRow={numberRow}
                  onSearchText={(val) => {
                    setSearchValue(val || "");
                  }}
                  header={
                    <tr>
                      <th className="text-nowrap text-center">ລຳດັບ</th>
                      <th className="text-nowrap text-center">ໂຊເຟີ້(1)</th>
                      <th className="text-nowrap text-center">ໂຊເຟີ້(2)</th>
                      <th className="text-nowrap text-center">ເບີລົດ</th>
                      <th className="text-nowrap text-center">ຜູ້ຈັດຖ້ຽວ</th>
                      <th className="text-nowrap text-center">ສາຍທີ່ແລ່ນ</th>
                      <th className="text-nowrap text-center">ເລກທີໂກຕ່າ</th>
                      <th className="text-nowrap text-center">ປະເພດສາຍ</th>
                      <th className="text-nowrap text-center">ກະອອກລົດ</th>
                      <th className="text-nowrap text-center">ປະເພດຖ້ຽວ</th>
                      {checkUserRole(["ACCOUNTANT", "SUPER_ADMIN", "AUDITOR"]) ? <th className="text-nowrap text-center">ການກວດສອບ</th> : null}
                      <th className="text-nowrap text-center">ກວດສອບໂດຍ</th>
                      <th className="text-nowrap text-center">ຈຳນວນພັດສະດຸ</th>
                      <th className="text-nowrap text-center">ລາຄາຕໍ່ຖ້ຽວໃນສາຍ(ກີບ)</th>
                      <th className="text-nowrap text-center">ເວລາອອກຕົ້ນທາງ</th>
                      <th className="text-nowrap text-center">ເວລາຮອດປາຍທາງ</th>
                      <th className="text-nowrap text-center">ໃຊ້ເວລາເດີນທາງ</th>
                      <th className="text-nowrap text-center">ຢືນຢັນຮອດປາຍທາງ</th>
                      <th className="text-nowrap text-center">ວັນທີຈັດຖ້ຽວ</th>
                      <th className="text-nowrap text-center">ວັນທີກວດສອບ</th>
                      <th className="text-nowrap text-center">ວັນທີສະເເກນອອກ</th>
                      <th className="text-nowrap text-center">ໝາຍເຫດ</th>
                      <th className="text-nowrap text-center">ຢືນຢັນອອກໂດຍ</th>
                      <th className="text-nowrap text-center">ຢືນຢັນຮອດໂດຍ</th>
                      <th className="text-nowrap text-center">ສູນປາຍທາງ</th>
                      {checkUserRole(["SUPER_ADMIN"]) ? <th className="text-nowrap text-center">ຈັດການ</th> : null}
                    </tr>
                  }
                  body={
                    <>
                      {items?.map((value, index) => (
                        <tr key={index} className={value?.isDif === "1" ? "table-danger" : ""}>
                          <td className="text-center">{index + 1}</td>
                          <td className="text-nowrap">
                            {value?.driverID?.gender ? Gender(value?.driverID?.gender) : ""}&nbsp; {value?.driverID?.first_name || "----"}&nbsp;
                            {value?.driverID?.last_name || "----"}
                          </td>
                          <td className="text-nowrap">
                            {value?.driverTwo?.gender ? Gender(value?.driverTwo?.gender) : ""}&nbsp;{value?.driverTwo?.first_name || "----"} &nbsp;
                            {value?.driverTwo?.last_name || "----"}
                          </td>
                          <td className="text-nowrap">{value?.vehicleID?.vehicleName || "----"}</td>
                          <td className="text-nowrap">
                            {value?.createdBy?.gender ? Gender(value?.createdBy?.gender) : ""}&nbsp;{value?.createdBy?.first_name || "----"}&nbsp;
                            {value?.createdBy?.last_name || "----"}
                          </td>
                          <td className="text-nowrap">
                            <div style={{ display: "flex", alignItems: "center" }}>{value?.routeLocationID?.title || "----"}</div>

                            {showItems[value?.sp_id] && showItems[value?.sp_id].length > 0 && (
                              <div className="location-info">
                                {showItems[value?.sp_id].map((branch: BranchInfo, itemIndex: number) => (
                                  <p key={itemIndex}>
                                    {itemIndex + 1}: ສາຂາ {branch.branch_id?.branch_name || "---"}
                                  </p>
                                ))}
                              </div>
                            )}
                          </td>

                          <td className="text-nowrap text-center">{value?.billNoRout ? value?.billNoRout : "- - -"}</td>
                          <td className="text-nowrap text-center">
                            {(() => {
                              if (value?.checkRoute === "IN") {
                                return "ສາຍໃນ";
                              } else if (value?.checkRoute === "OUT") {
                                return "ສາຍນອກ";
                              } else {
                                return "ສາຍສຳຮອງ";
                              }
                            })()}
                          </td>
                          <td className="text-center">{value?.isDay === "0" ? "ພາກເຊົ້າ" : "ພາກເເລງ"}</td>
                          <td
                            className={
                              value?.isDif === "1"
                                ? "text-danger text-center"
                                : value?.isDif === "0"
                                ? "text-success text-nowrap"
                                : "text-center text-nowrap"
                            }
                          >
                            {value?.isDif === "1" ? "ຖ້ຽວເສີມ" : value?.isDif === "2" ? "ຖ້ຽວເສີມພິເສດ" : "ຖ້ຽວປົກກະຕິ"}
                          </td>
                          {checkUserRole(["SUPER_ADMIN", "ACCOUNTANT", "AUDITOR"]) ? (
                            <>
                              <td className="text-center text-nowrap">
                                {value?.checkScan === 0 ? (
                                  <>
                                    <UPDATE_VERIFY_ROUTE data={value} onSuccess={() => setReloading(!reloading)} />
                                  </>
                                ) : value?.checkScan === 1 ? (
                                  <>
                                    <p className="text-success">
                                      <i className="fas fa-check-circle"></i>ກວດສອບຜ່ານ
                                    </p>
                                  </>
                                ) : value?.checkScan === 2 ? (
                                  <>
                                    <UPDATE_VERIFY_ROUTE_BY_ADMIN sp_id={value?.sp_id} onSuccess={() => setReloading(!reloading)} />
                                  </>
                                ) : (
                                  <p className="text-danger">
                                    <i className="fas fa-warning"></i>ກວດສອບບໍ່ຜ່ານ
                                  </p>
                                )}
                              </td>
                            </>
                          ) : null}

                          <td className="text-nowrap">
                            {value?.checkBy?.gender ? Gender(value?.checkBy?.gender) : ""} &nbsp;{value?.checkBy?.first_name || "----"}&nbsp;
                            {value?.checkBy?.last_name || "----"}
                          </td>
                          <td className="text-center">{value?.totalItem || "----"}</td>
                          <td className="text-nowrap">{currency(value?.amountValue || 0)} ກີບ</td>
                          <td className="text-nowrap text-center">{value?.startTime ? formatDateTime(value.startTime) : "----"}</td>
                          <td className="text-nowrap text-center">{value?.endTime ? formatDateTime(value.endTime) : "----"}</td>
                          <td className="text-nowrap">{value?.endTime ? Calculator(value?.startTime, value?.endTime) : "-----"}</td>
                          <td style={{ textAlign: "center" }} className="text-nowrap">
                            {value?.newLocation === "NO" ? (
                              "----"
                            ) : (
                              <>
                                {value?.newLocation === "NO" ? (
                                  <Button variant="danger" className="btn btn-success disable-cursor" size="sm">
                                    <i className="fas fa-check-circle"></i> ລົດກຳລັງອອກເດີນທາງ
                                  </Button>
                                ) : (
                                  <Button variant="success" className="btn btn-success disable-cursor" size="sm">
                                    <i className="fas fa-check-circle"></i> ຮອດປາຍທາງເເລ້ວ
                                  </Button>
                                )}
                              </>
                            )}
                          </td>
                          <td className="text-nowrap text-center">{formatDateTime(value?.createdAt || "----")}</td>
                          <td className="text-center">{value?.checkDate ? formatDate(value?.checkDate) : "-----"}</td>
                          <td className="text-center text-nowrap">{value?.dateScan_out ? formatDateTime(value.dateScan_out) : "----"}</td>
                          <td className="text-nowrap">{value?.difNote || "----"}</td>
                          <td className=" text-nowrap">
                            {value?.acceptBy?.gender ? Gender(value?.acceptBy?.gender) : ""} &nbsp;{value?.acceptBy?.first_name || "----"}
                            {value?.acceptBy?.last_name || "----"}
                          </td>
                          <td className="text-nowrap">
                            {value?.acceptArrivedBy?.gender ? Gender(value?.acceptArrivedBy?.gender) : ""}&nbsp;
                            {value?.acceptArrivedBy?.first_name || "----"}
                            {value?.acceptArrivedBy?.last_name || "----"}
                          </td>
                          <td className=" text-nowrap">{value?.centerRoute?.centerName || "----"}</td>
                          {checkUserRole(["SUPER_ADMIN"]) ? (
                            <>
                              <td className=" text-nowrap">
                                <div className="btn-group">
                                  <UPDATE_CHECK_ROUTE data={value} onSuccess={() => setReloading(!reloading)} />
                                </div>
                              </td>
                            </>
                          ) : null}
                        </tr>
                      ))}
                    </>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
