import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ChildPath, ParentPath } from "../routes/Path";
import Logo from "../img/AnousithDriverPartner.png";
import { ImOffice } from "react-icons/im";
import { BiSolidRightArrow } from "react-icons/bi";
import { FiTruck } from "react-icons/fi";
import { checkUserRole } from "../utils";

export default function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const onMouseLeave = () => {};
  const handleClose = () => {
    const sidebar = document.getElementById("app-sidebar");
    const layout = document.getElementById("app-layout");
    localStorage.setItem("hide_sidebar", "true");
    sidebar?.classList.remove("show");
    layout?.classList.remove("sidebar-show");
  };

  const isActive = (path: any) => location.pathname === path;

  return (
    <div id="app-sidebar" className="d-flex flex-column flex-shrink-0 p-3 bg-dark" onMouseLeave={onMouseLeave}>
      <div
        className="text-title text-decoration-none text-center "
        style={{
          marginTop: -20,
          backgroundColor: "#1e496e",
          width: "auto",
          height: "auto",
          marginLeft: -16,
          marginRight: -16,
          marginBottom: -6,
        }}
      >
        <span
          className="fs-4"
          onClick={() => {
            navigate(ParentPath.home);
            handleClickLink();
          }}
        >
          <img src={Logo} alt="logo" width="140" height={45} style={{ marginTop: 5 }} />
        </span>
        <button className="btn btn-close-sidebar" onClick={handleClose}>
          <i className="fas fa-close m-0" />
        </button>
      </div>
      <hr className="text-light" />
      <ul className="nav nav-pills flex-column mb-auto">
        <div className={`menu-group mt-2 ${isActive("/home") ? "active" : ""}`}>
          <span onClick={() => navigate(`/home`)}>
            <i className="fas fa-home me-2" />
            ໜ້າຫຼັກ
          </span>
        </div>
        {checkUserRole(["SUPER_ADMIN", "ACCOUNTANT", "AUDITOR"]) ? (
          <>
            <div className={`menu-group ${isActive("/company_driver_partner") ? "active" : ""}`}>
              <span onClick={() => navigate(`/company_driver_partner`)}>
                <i className="fas fa-bank me-2"></i>
                ກຸ່ມບໍລິສັດ
              </span>
            </div>
            <div className={`menu-group ${isActive("/users") ? "active" : ""}`}>
              <span onClick={() => navigate(`/users`)}>
                <i className="fas fa-user" />
                ຈັດການຜູ້ນຳໃຊ້ລະບົບ
              </span>
            </div>
            <MenuGroup
              open={false}
              title={
                <span>
                  <ImOffice className="me-2" />
                  ຈັດການສູນຄັດແຍກ
                </span>
              }
            >
              <MenuLink to={`${ParentPath.driverPartnerSeparation}/${ChildPath.centralSeparation}`}>
                <span className="text-nowrap">
                  {" "}
                  <BiSolidRightArrow style={{ fontSize: "12px" }} /> ຈັດການຂໍ້ມູນສູນຄັດແຍກ
                </span>
              </MenuLink>

              <MenuLink to={`${ParentPath.driverPartnerSeparation}/${ChildPath.containVehicleCentral}`}>
                <BiSolidRightArrow className="me-1" style={{ fontSize: "12px" }} />
                ບັນຈຸລົດເຂົ້າສູນ
              </MenuLink>
            </MenuGroup>
          </>
        ) : null}

        {checkUserRole(["SUPER_ADMIN", "ACCOUNTANT", "AUDITOR"]) ? (
          <>
            <MenuGroup
              open={false}
              title={
                <span>
                  <FiTruck className="me-2" />
                  ຈັດການຂໍ້ມູນລົດຂົ່ນສົ່ງ
                </span>
              }
            >
              <MenuLink to={`${ParentPath.transportationVehicle}/${ChildPath.typeVehicle}`}>
                <BiSolidRightArrow className="me-1" style={{ fontSize: "12px" }} />
                ຈັດການປະເພດລົດ
              </MenuLink>
              <MenuLink to={`${ParentPath.transportationVehicle}/${ChildPath.informationVehicle}`}>
                <BiSolidRightArrow className="me-1" style={{ fontSize: "12px" }} />
                ຈັດການຂໍ້ມູນລົດ
              </MenuLink>
              <MenuLink to={`${ParentPath.transportationVehicle}/${ChildPath.destinationVehicle}`}>
                <BiSolidRightArrow className="me-1" style={{ fontSize: "12px" }} />
                ຈັດການສາຍລົດ
              </MenuLink>
              <MenuLink to={`${ParentPath.transportationVehicle}/${ChildPath.containDriver}`}>
                <BiSolidRightArrow className="me-1" style={{ fontSize: "12px" }} />
                ບັນຈຸໂຊເຟີ້
              </MenuLink>
              {checkUserRole(["SUPER_ADMIN", "ACCOUNTANT"]) ? (
                <MenuLink to={`${ParentPath.transportationVehicle}/${ChildPath.modifyDestinationVehicle}`}>
                  <BiSolidRightArrow className="me-1" style={{ fontSize: "12px" }} />
                  ປ່ຽນລາຄາສາຍລົດ
                </MenuLink>
              ) : (
                <></>
              )}
            </MenuGroup>
          </>
        ) : null}

        {checkUserRole(["SUPER_ADMIN", "ACCOUNTANT", "AUDITOR"]) ? (
          <>
            <MenuGroup
              open={false}
              title={
                <span>
                  <i className="fa-solid fa-cube me-2" />
                  ຄິດໄລ່ເງິນຖ້ຽວ
                </span>
              }
            >
              <MenuLink to={`${ParentPath.calculateFare}/${ChildPath.exTraPayment}`}>
                <BiSolidRightArrow className="me-1" style={{ fontSize: "12px" }} />
                ຈັດການເງິນເພີ່ມ
              </MenuLink>
              <MenuLink to={`${ParentPath.calculateFare}/${ChildPath.deductionPayment}`}>
                <BiSolidRightArrow className="me-1" style={{ fontSize: "12px" }} />
                ຈັດການເງິນຫັກ
              </MenuLink>
            </MenuGroup>
          </>
        ) : null}

        {checkUserRole(["ACCOUNTANT", "SUPER_ADMIN", "AUDITOR", "ADMIN"]) ? (
          <>
            <MenuGroup
              open={false}
              title={
                <span>
                  <i className="fa-solid fa-cube me-2" />
                  ການຈັດຖ້ຽວລົດ
                </span>
              }
            >
              <MenuLink to={`${ParentPath.vehicleManagement}/${ChildPath.shippingRoute}`}>
                <BiSolidRightArrow className="me-1" style={{ fontSize: "12px" }} />
                ເພີ່ມຖ້ຽວລົດອອກ
              </MenuLink>

              <MenuLink to={`${ParentPath.vehicleManagement}/${ChildPath.shippingCheckingRoute}`}>
                <BiSolidRightArrow className="me-1" style={{ fontSize: "12px" }} />
                ລາຍງານລົດເຂົ້າ-ອອກ
              </MenuLink>
              <MenuLink to={`${ParentPath.vehicleManagement}/${ChildPath.shippingRouteTwo}`}>
                <BiSolidRightArrow className="me-1" style={{ fontSize: "12px" }} />
                ເພີ່ມຖ້ຽວລົດເສີມ
              </MenuLink>
              <MenuLink to={`${ParentPath.vehicleManagement}/${ChildPath.shippingCheckingRouteTwo}`}>
                <BiSolidRightArrow className="me-1" style={{ fontSize: "12px" }} />
                ລາຍງານຖ້ຽວເສີມ
              </MenuLink>
              {checkUserRole(["ACCOUNTANT", "SUPER_ADMIN"]) ? (
                <>
                  <MenuLink to={`${ParentPath.vehicleManagement}/${ChildPath.modifyPriceRoute}`}>
                    <BiSolidRightArrow className="me-1" style={{ fontSize: "12px" }} />
                    ແກ້ໄຂລາຄາຖ້ຽວ
                  </MenuLink>
                </>
              ) : (
                <></>
              )}
            </MenuGroup>
          </>
        ) : null}
        {checkUserRole(["ACCOUNTANT" ,"SUPER_ADMIN", "AUDITOR"]) ? (
          <>
            <div className={`menu-group mt-2 ${isActive("/checking_destination_vehicle") ? "active" : ""}`} style={{ marginTop: -5 }}>
              <span onClick={() => navigate(`/checking_destination_vehicle`)}>
                <i className="fas fa-check-square me-2" />
                ກວດສອບຖ້ຽວລົດ
              </span>
            </div>
            <div className={`menu-group mt-2 ${isActive("/summarize_destination_vehicle") ? "active" : ""}`}>
              <span onClick={() => navigate(`/summarize_destination_vehicle`)}>
                <i className="fas fa-calculator me-2" />
                ສະຫຼຸບຖ້ຽວລົດ
              </span>
            </div>
            <div className={`menu-group  ${isActive("/summarize_destination_vehicle_Two") ? "active" : ""}`}>
              <span onClick={() => navigate(`/summarize_destination_vehicle_Two`)}>
                <i className="fas fa-calculator me-2" />
                ສະຫຼຸບຖ້ຽວລົດເສີມພິເສດ
              </span>
            </div>
            {checkUserRole(["ACCOUNTANT", "SUPER_ADMIN"]) ? (
              <>
                <div className={`menu-group  ${isActive("/export_ibank_summary") ? "active" : ""}`}>
                  <span onClick={() => navigate(`/export_ibank_summary`)}>
                    <i className="fas fa-calculator me-2" />
                    ໄຟຮIBANK
                  </span>
                </div>
                <div className={`menu-group  ${isActive("/export_ibank_summary_Two") ? "active" : ""}`}>
                  <span onClick={() => navigate(`/export_ibank_summary_Two`)}>
                    <i className="fas fa-calculator me-2" />
                    ໄຟຮIBANK ເສີມພິເສດ
                  </span>
                </div>
              </>
            ) : null}
          </>
        ) : null}

        {checkUserRole(["ACCOUNTANT", "SUPER_ADMIN"]) ? (
          <>
            <div className={`menu-group  ${isActive("/trash") ? "active" : ""}`}>
              <span onClick={() => navigate(`/trash`)}>
                <i className="fas fa-trash me-2" />
                ຖັງຂີ້ເຫຍື້ອ
              </span>
            </div>
          </>
        ) : null}
      </ul>
    </div>
  );
}

function handleClickLink() {
  window.scrollTo(0, 0);

  const x = window.matchMedia("(max-width: 768px)");
  if (x.matches) {
    const sidebar = document.getElementById("app-sidebar");
    const layout = document.getElementById("app-layout");
    localStorage.setItem("hide_sidebar", "true");
    sidebar?.classList.remove("show");
    layout?.classList.remove("sidebar-show");
  }
}

function MenuGroup({ title, children, open }: { title: JSX.Element; children: JSX.Element | JSX.Element[]; open?: boolean }) {
  const [show, setShow] = useState(open ? open : false);
  const [activeGroup, setActiveGroup] = useState(false);
  const elmRef = useRef<HTMLDivElement>(null);

  const checkChildActiveLink = () => {
    const elmLength = elmRef.current?.children.length || 0;
    for (let i = 0; i < elmLength; i++) {
      const elm = elmRef.current?.children[i] as HTMLDivElement;
      if (elm.classList.contains("active")) {
        setShow(true);
        setActiveGroup(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("message", (e) => {
      if (typeof e.data !== "string") return;
      const data = JSON.parse(e.data);
      if (data?.type === "activeLink") checkChildActiveLink();
    });
  }, []);
  return (
    <div className="menu-group">
      <div className={`item-group ${activeGroup ? "active" : ""}`} onClick={() => setShow(!show)}>
        <div className="text">{title}</div>
        <div className="icon">
          <i className={`fas fa-angle-${show ? "up" : "down"}`} />
        </div>
      </div>
      <div ref={elmRef} className={`content-group ${show ? "show" : ""}`}>
        {children}
      </div>
    </div>
  );
}

function MenuLink({ to, active, children }: { to: string; active?: string; children: any }) {
  const [pathname, setPathname] = useState("");
  const location = useLocation();

  useEffect(() => {
    setPathname(location.pathname || "");
  }, [location]);

  const isActiveLink = (pathname: string, activePath: string) => {
    if (pathname === activePath) {
      window.postMessage(JSON.stringify({ type: "activeLink", data: activePath }), "*");
      return "active";
    } else {
      return "";
    }
  };
  return (
    <Link onClick={handleClickLink} className={`item-link ${isActiveLink(pathname, active ? active : to)}`} to={to}>
      {children}
    </Link>
  );
}
