import { ApolloError } from "@apollo/client";
import Compressor from "compressorjs";
import moment from "moment";
import { Confirm, Notify } from "notiflix";
import React, { useState } from "react";
import AnimateHeight from "react-animate-height";

export const RootVariable = {
  limit: 10,
};

export const FormatDateToAM_PM = (date: Date | string, format?: string) => {
  return moment(date ? date : new Date()).format(format ? format : "DD/MM/YYYY HH:mm");
};

export const generateBillNo = (Bill: string) => {
  for (let i = 1; i <= 8; i++) Bill += ~~(Math.random() * 6);
  return Bill;
};

export const USER_KEY = "ans-driver-partner";

// ອັບໂຫລດຮູບພາບ

//get staff lo

// Compress image
export default async function compressImage(file: any) {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: 0.6,
      maxWidth: 1920,
      maxHeight: 1920,
      success: (result: any) => {
        const _file = new File([result], result?.name, { type: result?.type });
        resolve(_file);
      },
      error: (error: any) => {
        reject(error);
      },
    });
  });
}

// ຄິດໄລ່ເວລາຈັດຖ້ຽວ

export const Calculator = (startTime: string, endTime: string) => {
  if (!endTime) {
    let difference = "-----";
    return difference;
  } else {
    const sTime = new Date(startTime).getTime();
    const eTime = new Date(endTime).getTime();
    const timeDifference = Math.abs(sTime - eTime);
    const Days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const show_day = Days === 24 ? " " : Days;

    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
    let difference = "";

    if (show_day === 0) {
      difference = `${hours} ຊົ້ວໂມງ ${minutes} ນາທີ ${seconds} ວິນາທີ`;
    } else if (show_day !== 0) {
      difference = `${show_day} ວັນ ${hours} ຊົ້ວໂມງ ${minutes} ນາທີ ${seconds} ວິນາທີ`;
    }
    return difference;
  }
};

// export gender

export const Gender = (gender: string) => {
  let users = "";
  if (gender === "MALE") {
    users = "ທ້າວ";
  } else {
    users = "ນາງ";
  }
  return users;
};

// Local Storage Name
export enum LocalStorageName {
  token = "APP-TOKEN-EASY-SPEED",
  localProvinceId = "LOCAL_PROVINCE_ID-EASY-SPEED",
  localCenter = "LOCAL_BRANCH_ID-EASY-SPEED",
}

// Clear localStorage
export const clearLocalStorage = () => {
  localStorage.removeItem(LocalStorageName.token);
  localStorage.removeItem(LocalStorageName.localProvinceId);
  localStorage.removeItem(LocalStorageName.localCenter);
};

// Notiflix
Notify.init({
  warning: { background: "var(--color-warning)" },
  success: { background: "var(--color-success)" },
  failure: { background: "var(--color-danger)" },
  info: { background: "var(--color-info)" },
  fontSize: "0.95rem",
});

export const notifySuccess = (msg: string) => {
  setTimeout(() => {
    Notify.success(msg);
  }, 500);
};

export const notifyError = (msg: string) => {
  Notify.failure(msg);
};

export const notifyWarning = (msg: string) => {
  Notify.warning(msg);
};

export const notifyInfo = (msg: string) => {
  Notify.info(msg);
};

export const confirmShow = (title: string, action: () => void) => {
  Confirm.show(
    "ເເຈ້ງເຕືອນ",
    title,
    "ຕົກລົງ",
    "ຍົກເລີກ",
    async () => {
      action();
    },
    () => {
      return false;
    }
  );
};

//NO ITEM
export const NO = (index: number, page: number, limit: number) => {
  const no = limit * page - limit;
  if (limit > 0) {
    return currency(no + index + 1);
  } else {
    return currency(index + 1);
  }
};

export const startOfMonth = (format?: string) => {
  return moment()
    .clone()
    .startOf("month")
    .format(format ? format : "YYYY-MM-DD");
};

// ວັນທີເດືອນປີ ທ້າຍວັນທີ ທ້າຍເດືອນ ທ້າຍປີ
export const endOfMonth = (format?: string) => {
  return moment()
    .clone()
    .endOf("month")
    .format(format ? format : "YYYY-MM-DD");
};

export const StartOfDay = () => {
  return moment().clone().startOf("day").format("YYYY-MM-DD");
};

export const StartOfDaySummary = () => {
  return moment().startOf("month").format("YYYY-MM-DD");
};

export const EndOfDay = () => {
  return moment().clone().endOf("day").format("YYYY-MM-DD");
};

export const EndOfDayTwo = () => {
  return moment().clone().add(1, "day").endOf("day").format("YYYY-MM-DD");
};

// Format DateTime
export const toDayDate = (format?: string) => {
  return moment().format(format ? format : "DD-MM-YYYY");
};

export const formatDateGte = (date: Date) => {
  const _date = date ? date : new Date();
  return moment(_date).format("YYYY-MM-DD");
};

export const formatDateLt = (date: Date) => {
  const _date = date ? date : new Date();
  return moment(_date).add(1, "days").format("YYYY-MM-DD");
};

export const formatDate = (date: Date, format?: string) => {
  return moment(date ? date : new Date()).format(format ? format : "DD-MM-YYYY");
};

// formatdash

export const formatDash = (date: Date, format?: string) => {
  return moment(date ? date : new Date()).format(format ? format : "YYYY-MM-DD HH:mm:ss");
};

//formatData_verification
export const formatVerify = (date: Date, format?: string) => {
  return moment(date ? date : new Date()).format(format ? format : "YYYY-MM-DD");
};

export const formatDateUpdate = (date: Date, format?: string) => {
  return moment(date ? date : new Date()).format(format ? format : "YYYY-MM-DD");
};

// ກຳນົດ ວັນທີປັດຈຸບັນ(-)
export const formatDateDash = (dashDate: Date, format?: string) => {
  let resp = moment(dashDate).format("YYYY-MM-DD");
  return resp;
};

export const formatDateTime = (date: Date | string, format?: string) => {
  return moment(date ? date : new Date()).format(format ? format : "DD-MM-YYYY HH:mm:ss");
};

// Invalid Text
export const InvalidText = () => {
  return <span style={{ color: "var(--color-danger)" }}>*</span>;
};

// Format Currency
export const currency = (number: number = 0) => {
  if (!number) return "0";
  const currency = new Intl.NumberFormat("en-CA").format(number);
  return currency;
};

// Message Errors
export enum MessageErrors {
  process = "ການດຳເນິນງານບໍ່ສຳເລັດ ກະລຸນາລອງອີກຄັ້ງ",
  invalidPassword = "ລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ ກະລຸນາລອງອີກຄັ້ງ",
  insert = "ບໍ່ສາມາດເພີ່ມຂໍ້ມູນໄດ້ ກະລຸນາລອງອີກຄັ້ງ",
  save = "ບໍ່ສາມາດບັນທຶກຂໍ້ມູນໄດ້ ກະລຸນາລອງອີກຄັ້ງ",
  edit = "ບໍ່ສາມາດແກ້ໄຂຂໍ້ມູນໄດ້ ກະລຸນາລອງອີກຄັ້ງ",
  update = "ບໍ່ສາມາດອັບເດດຂໍ້ມູນໄດ້ ກະລຸນາລອງອີກຄັ້ງ",
  updateAddBranch = "ກະລຸນາເລືອກສາຂາໃຫ້ຕົງກັບພາກສາຍລົດ",
  branchNotFound = "ບັນຊີຂອງທ່ານບໍ່ມີຂໍ້ມູນສາຂາ ກະລຸນາກວດສອບຄືນ",
  delete = "ບໍ່ສາມາດລຶບຂໍ້ມູນໄດ້ ກະລຸນາລອງອີກຄັ້ງ",
  cancel = "ບໍ່ສາມາດຍົກເລີກໄດ້ ກະລຸນາລອງອີກຄັ້ງ",
  error = "ມີຂໍ້ຜິດພາດເກີດຂື້ນ ກະລຸນາລອງໃໝ່ອີກຄັ້ງ",
  NotClosed = "ປິດສູນບໍ່ສຳເລັດ",
  closedRouteLocation = "ປິດສາຍຂົ່ນສົງບໍ່ສຳແລ້ວ",
  openRouteLocation = "ເປິດສາຍຂົ່ນສົງບໍ່ສຳແລ້ວ",
  addVehicle = "ບັນຈຸລົດເຂົ້າສູນບໍ່ສຳເລັດ",
  addBranches = "ເພີ່ມບໍ່ສຳເລັດ",
  addVehicleTORouteLocation = "ເພີ່ມບໍ່ສຳເລັດ",
  deleteDriver = "ຖອນບໍ່ສຳເລັດ",
  updateCar = "ຢືນຢັນລົດອອກບໍ່ສຳເລັດ",
  updateNewLocation = "ຢືນຢັນລົດຮອດປາຍທາງບໍ່ສຳເລັດແລ້ວ",
  Verify = "ກວດສອບບໍ່ສຳເລັດແລ້ວ",
  Paid = "ຢືນຢັນຊຳລະບໍ່ສຳເລັດແລ້ວ",
  back = "ຍົກເລິກລາຍການນີ້ບໍ່ສຳເລັດ",
  summaryDriver = "ສະຫຼຸບບໍ່ສຳເລັດ",
  summaryCenter = "ສະຫຼຸບບໍ່ສຳເລັດ",
  checkNameDriver = "ຊື້ຂອງໂຊເຟີຊ້ຳກັນ!!!",
}

// Message Success
export enum MessageSuccess {
  process = "ການດຳເນິນງານສຳເລັດແລ້ວ",
  insert = "ເພີ່ມຂໍ້ມູນໃໝ່ສຳເລັດແລ້ວ",
  save = "ບັນທຶກຂໍ້ມູນສຳເລັດແລ້ວ",
  edit = "ແກ້ໄຂຂໍ້ມູນສຳເລັດແລ້ວ",
  update = "ອັບເດດຂໍ້ມູນສຳເລັດແລ້ວ",
  updateAddBranch = "ເພີ່ມສາຂາສຳເລັດ",
  delete = "ລຶບຂໍ້ມູນສຳເລັດແລ້ວ",
  cancel = "ຍົກເລີກສຳເລັດແລ້ວ",
  IsClosed = "ປິດສູນສຳເລັດແລ້ວ",
  closedRouteLocation = "ປິດສາຍຂົ່ນສົງສຳແລ້ວ",
  openRouteLocation = "ເປິດສາຍຂົ່ນສົງສຳແລ້ວ",
  addVehicle = "ບັນຈຸລົດເຂົ້າສູນສຳເລັດແລ້ວ",
  addBranches = "ເພີ່ມສຳເລັດແລ້ວ",
  addVehicleTORouteLocation = "ເພີ່ມສຳເລັດແລ້ວ",
  deleteDriver = "ຖອນສຳເລັດແລ້ວ",
  updateCar = "ຢືນຢັນລົດອອກສຳເລັດ",
  updateNewLocation = "ຢືນຢັນລົດຮອດປາຍທາງສຳເລັດແລ້ວ",
  Verify = "ກວດສອບສຳເລັດແລ້ວ",
  Paid = "ຢືນຢັນຊຳລະແລ້ວສຳເລັດແລ້ວ",
  back = "ຍົກເລິກລາຍການນີ້ສຳເລັດແລ້ວ",
  summaryDriver = "ສະຫຼຸບສຳເລັດແລ້ວ",
  summaryCenter = "ສະຫຼຸບສຳເລັດແລ້ວ",
}

export enum MessageConfirms {
  deleteDriver = "ຕ້ອງການຖອນໂຊເຟີ້ຄົນນີ້ ແທ້ ຫຼື ບໍ່",
  addVehicleTORouteLocation = "ຕ້ອງການເພີ່ມລົດເຂົ້າສາຍນີ້ ແທ້ ຫຼື ບໍ່",
  addBranches = "ຕ້ອງການເພີ່ມສາຂາເຂົ້າສາຍນີ້ ແທ້ ຫຼື ບໍ່",
  addVehicle = "ຕ້ອງການບັນຈຸລົດຄັນນີ້ເຂົ້າສູນ ແທ້ ຫຼື ບໍ່",
  closedRouteLocation = "ຕ້ອງການປິດສາຍຂົ່ນສົງນີ້ ແທ້ ຫຼື ບໍ່",
  openRouteLocation = "ຕ້ອງການເປິດສາຍຂົ່ນສົງນີ້ ແທ້ ຫຼື ບໍ່",
  delete = "ທ່ານຕ້ອງການລຶບຂໍ້ມູນນີ້ ແທ້ ຫຼື ບໍ່?",
  updated = "ທ່ານຕ້ອງການປິດສູນນີ້ ແທ້ ຫຼື ບໍ່?",
  cancelTransferItem = "ທ່ານຕ້ອງການຍົກເລີກເຄື່ອນຍ້າຍພັດສະດຸ ແທ້ ຫຼື ບໍ່?",
  destConfirmReceiveItem = "ທ່ານຕ້ອງການຢືນຢັນຮັບພັດສະດຸເຂົ້າສາງ ແທ້ ຫຼື ບໍ່?",
  setItemRoute = "ທ່ານຕ້ອງການເລີ່ມຈັດສາຍລາຍການພັດສະດຸທັງໝົດ ແທ້ ຫຼື ບໍ່?",
  setItemRouteOnlySelect = "ທ່ານຕ້ອງການເລີ່ມຈັດສາຍລາຍການທີ່ເລືອກໄວ້ ແທ້ ຫຼື ບໍ່?",
  lockedItem = "ທ່ານຕ້ອງການລ໋ອກຈັດສາຍ ແທ້ ຫຼື ບໍ່?",
  cancelLockedItem = "ທ່ານຕ້ອງການຍົກເລີກລ໋ອກຈັດສາຍ ແທ້ ຫຼື ບໍ່?",
  waitingToBackShop = "ທ່ານຕ້ອງການຍ້າຍພັດສະດຸໄປຍັງພັດສະດຸກຽມສົ່ງຄືນແມ່ຄ້າ ແທ້ ຫຼື ບໍ່?",
  confirmToBackShop = "ທ່ານຕ້ອງການຢືນຢັນສົ່ງພັດສະດຸຄືນແມ່ຄ້າ ແທ້ ຫຼື ບໍ່",
  cancelSent = "ທ່ານຕ້ອງການຍົກເລີກຈັດສົ່ງພັດສະດຸ ແທ້ ຫຼື ບໍ່?",
  cancelShipperToPickup = "ທ່ານຕ້ອງການຍົກເລີກການສັ່ງພະນັກງານໄປຮັບເຄື່ອງ ແທ້ ຫຼື ບໍ່?",
  cancelWaitingAssignShipper = "ທ່ານຕ້ອງການຍົກເລີກລໍຖ້າຈັດຄົນເຂົ້າສາຍ ແທ້ ຫຼື ບໍ່?",
  confirmedCodDeposit = "ທ່ານຕ້ອງການຢືນຢັນຄວາມຖືກຕ້ອງຂອງຍອດເງິນ COD ແທ້ ຫຼື ບໍ່?",
  cancelAssignedShipper = "ທ່ານຕ້ອງການຍົກເລີກຈັດຄົນເຂົ້າສາຍແທ້ ຫຼື ບໍ່?",
  cancelPostpone = "ທ່ານຕ້ອງການຍົກເລີກການເລື່ອນນັດ ແທ້ ຫຼື ບໍ່?",
  confirmSentCODFromShipper = "ທ່ານຕ້ອງການຢືນຢັນຮັບເງິນປາຍທາງທັງໝົດ ແທ້ ຫຼື ບໍ່?",
  confirmCheckDriver = "ຕ້ອງການຢືນຢັນຖ້ຽວລົດນີ້ ແທ້ ບໍ່?",
  cancelDriverCar = "ຕ້ອງການຍົກເລີກຖ້ຽວລົດນີ້ ແທ້ບໍ່?",
  Paid = "ຕ້ອງການຢືນຢັນຊຳລະລາຍການນີ້ແທ້ບໍ່? ",
  back = "ຕ້ອງການຍົກເລິກລາຍການນີ້ແທ້ບໍ່?",
  verify_all = "ຕ້ອງການຢືນຢັນຊຳລະທັງໝົດ ແທ້ ຫຼື ບໍ່? ",
  summaryDriver = "ຕ້ອງການສະຫຼຸບ ແທ້ ຫຼື ບໍ່?",
  summaryCenter = "ຕ້ອງການສະຫຼຸບ ແທ້ ຫຼື ບໍ່?",
  CancelSummary = "ຕ້ອງການຍົກເລິກທັງໝົດ ແທ້ ຫຼື ບໍ່?",
}

export enum MessageWarnings {
  dataNotFound = "ບໍ່ມີຂໍ້ມູນໃນລະບົບ ກະລຸນາລອງໃໝ່ອີກຄັ້ງ",
  duplicate = "ມີຂໍ້ມູນນີ້ໃນລະບົບແລ້ວ ກະລຸນາກວດສອບ",
  difNoteNotFound = "ກະລູນາປ້ອນເຫດຜົນກ່ອນ!!!",
}

// Item Select
export class ItemSelect {
  static all(e: React.ChangeEvent<HTMLInputElement>) {
    const elms = document.querySelectorAll<HTMLInputElement>(".item-checkbox");
    const arrays: number[] = [];
    if (elms?.length) {
      if (e.target.checked) {
        elms.forEach((elm) => {
          elm.checked = true;
          arrays.push(parseInt(elm.value));
        });
        return arrays;
      } else {
        elms.forEach((elm) => {
          elm.checked = false;
        });
        return arrays;
      }
    }
    return arrays;
  }

  static one() {
    const elms = document.querySelectorAll<HTMLInputElement>(".item-checkbox");
    let arrays: number[] = [];

    if (elms?.length) {
      elms.forEach((elm) => {
        const value = parseInt(elm.value);
        if (elm.checked) {
          arrays.push(value);
        } else {
          arrays = arrays?.filter((id) => id !== value);
        }
      });
    }
    return arrays;
  }

  static unselect() {
    const checkAll = document.getElementById("select-all") as HTMLInputElement;
    const elms = document.querySelectorAll<HTMLInputElement>(".item-checkbox");
    let arrays: number[] = [];
    if (checkAll) {
      checkAll.checked = false;
    }
    if (elms?.length) {
      elms.forEach((elm) => {
        elm.checked = false;
      });
    }
    return arrays;
  }
}

// get parse JWT
export const parseJwt = (token: string) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  const object = JSON.parse(jsonPayload);
  return object;
};

export type UserRole =
  | "HR"
  | "IT"
  | "ACCOUNTANT"
  | "FINANCE"
  | "LAW"
  | "STATE_DIRECTOR"
  | "BRANCH_DIRECTOR"
  | "CALL_CENTER"
  | "ADMIN"
  | "SUPER_ADMIN"
  | "CUSTOMER_SERVICE"
  | "DRIVER_DIRECTOR"
  | "DRIVER"
  | "AUDITION_DIRECTOR"
  | "AUDITION_STAFF"
  | "SHIPPER"
  | "AUDITOR"
  | "PC";

export const userRoles: any[] = [
  { role: "HR", title: "ບຸກຄະລາກອນ" },
  { role: "IT", title: "ໄອທີ" },
  { role: "ACCOUNTANT", title: "ບັນຊີ" },
  { role: "FINANCE", title: "ການເງິນ" },
  { role: "LAW", title: "ກົດໝາຍ" },
  { role: "STATE_DIRECTOR", title: "ຫົວໜ້າພາກ" },
  { role: "BRANCH_DIRECTOR", title: "ຫົວໜ້າສາຂາ" },
  { role: "CALL_CENTER", title: "ພະນັກງານຕ້ອນຮັບ" },
  { role: "ADMIN", title: "ແອັດມິນ" },
  { role: "SUPER_ADMIN", title: "ສິດສູງສຸດ" },
  { role: "CUSTOMER_SERVICE", title: "ພະນັກງານບໍລິການ" },
  { role: "DRIVER_DIRECTOR", title: "ຫົວໜ້າບໍລິຫານລົດ" },
  { role: "DRIVER", title: "ພະນັກງານຂັບລົດ" },
  { role: "AUDITION_DIRECTOR", title: "ຫົວໜ້າສູນ" },
  { role: "AUDITION_STAFF", title: "ພະນັກງານສູນ" },
  { role: "SHIPPER", title: "ພະນັກງານສົ່ງເຄື່ອງ" },
  { role: "AUDITOR", title: "ຜູ້ກວດສອບ" },
  { role: "PC", title: "ພະນັກງານສູນ" },
];

export const getStaffLogin = () => {
  const token = localStorage?.getItem(LocalStorageName?.token);
  if (token) {
    const data = parseJwt(token);
    const user: any = {
      _id: data?._id,
      image: data?.image,
      gender: data?.gender,
      first_name: data?.first_name,
      last_name: data?.last_name,
      phoneNumber: data?.phoneNumber,
      age: data?.age,
      role: data?.role,
      center: data?.center,
      status: data?.status,
      createdAt: data?.createdAt,
    };
    return user;
  }
  return null;
};

export const getUserRole = () => {
  const role = getStaffLogin()?.role;
  return role;
};

export const getLocalCenter = () => {
  const local = localStorage?.getItem(LocalStorageName?.localCenter);
  if (local) {
    return parseInt(local);
  } else {
    const id = getStaffLogin()?.center;
    return id;
  }
};

export const getLocalProvinceId = () => {
  const local = localStorage?.getItem(LocalStorageName?.localProvinceId);
  if (local) {
    return parseInt(local);
  } else {
    const id = getStaffLogin()?.province?._id;
    return id;
  }
};

export const convertRole = (role: string) => {
  const result = userRoles?.filter((item) => item?.role === role);
  if (result?.length) {
    return result[0]?.title;
  } else {
    return "";
  }
};

export const checkUserRole = (roles: UserRole[] | undefined) => {
  if (roles?.includes(getUserRole())) {
    return true;
  } else {
    return false;
  }
};

export const graphQLErrors = (e: any) => {
  const error = e as ApolloError;
  if (error?.graphQLErrors?.length) {
    return error?.graphQLErrors[0].toString().replace("Error: ", "");
  }
  if (error?.graphQLErrors?.length) {
    return error?.graphQLErrors[0].toString().replace("Error: ", "");
  }
  return "";
};

// Toggle animation height
export const ToggleAnimationHeight = ({
  button,
  children,
}: {
  button: (onClick: () => void) => JSX.Element;
  children: JSX.Element | JSX.Element[];
}) => {
  const [show, setShow] = useState(false);
  const onClick = () => setShow(!show);
  return (
    <>
      <AnimateHeight height={show ? "auto" : 0}>
        <>{children}</>
      </AnimateHeight>
      {button?.(onClick)}
    </>
  );
};

export const gcpUpload = async ({ file, bucketName, pathname, filename }: any) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("bucketName", bucketName);
    formData.append("pathname", pathname);
    formData.append("filename", filename);
    const res = await fetch("https://api.driver.anousith.express/graphql/upload_file/", {
      method: "POST",
      body: formData,
    });
    const resWithBody = await res.json();
    return resWithBody;
  } catch (error: any) {
    throw new Error(error);
  }
};
