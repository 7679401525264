import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { useReactToPrint } from "react-to-print";
import { formatDate, formatDateTime, getStaffLogin } from "../../utils";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { BiPrinter } from "react-icons/bi";
import { BsFileEarmarkExcel } from "react-icons/bs";
import Logo from "../../assets/images/ans-logo-red.svg";

export default function ExportFile({ data, loading }: any) {
  const [show, setShow] = useState(false);
  const elmRef = useRef<HTMLDivElement>(null);
  let today = moment().format("YYYY-MM-DD");
  let time = moment().format("HH:mm:ss");
  const getLogin = getStaffLogin();
  const [items, setItems] = useState<any>([]);
  useEffect(() => {
    if (!show) return;
    setItems(data?.users?.data || []);
  }, [data, show]);

  const handlePrint = useReactToPrint({
    content: () => elmRef.current,
  });

 
  return (
    <React.Fragment>
      <>
        <button
          type="button"
          className="btn btn-secondary  me-2 shadow-none"
          onClick={() => setShow(true)}
          style={{ position: "relative", top: "0px" }}
          disabled={loading}
        >
          <BiPrinter className="me-2" />
          ພິມລາຍງານ
        </button>
        <Modal show={show} dialogClassName="modal-width-95" animation={false}>
          <Modal.Header onHide={() => setShow(false)} closeButton>
            <Modal.Title>
              {" "}
              <AiOutlineUsergroupAdd className="me-2" style={{ fontSize: "30px" }} />
              ຂໍ້ມູນພະນັກງານໃຊ້ລະບົບ
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3 flex-control-group">
              <button onClick={handlePrint} type="button" className="btn btn-lg btn-dark ml-3 me-2">
                <BiPrinter /> ພິມລາຍງານ
              </button>
              <DownloadTableExcel filename="ຂໍ້ມູນພະນັກງານ" sheet="ຂໍ້ມູນພະນັກງານ" currentTableRef={elmRef.current}>
                <button className="btn btn-lg btn-success">
                  <BsFileEarmarkExcel /> Export excel{" "}
                </button>
              </DownloadTableExcel>
            </div>
            &nbsp;&nbsp;
            <div ref={elmRef} id="table-to-xls" className="container-print" style={{ fontFamily: "phetsarath OT" }}>
              <div className="text-center fw-bold" style={{ fontSize: "1.2rem" }}>
                ຂໍ້ມູນພະນັກງານ
              </div>
              <div>
                <img style={{ width: 150 }} src={Logo} alt="" />
              </div>
              &nbsp;
              <div className="justify-content-between">
                <div>
                  <div>ບໍລິສັດ ອານຸສິດ ໂລຈິສຕິກ ຈຳກັດ.</div>
                  <div>ບ້ານ ໂພນສະອາດ, ເມືອງ ຈັນທະບູລີ, ນະຄອນຫຼວງວຽງຈັນ</div>
                  <div className="text-end">
                    ພິມໂດຍ: {getLogin?.first_name} {getLogin?.last_name}
                  </div>
                  <div className="text-end">
                    ວັນທີພິມ: {time} , {today}
                  </div>
                </div>
              </div>
              &nbsp;&nbsp;
              <div className="table">
                <table className="table" border={1}>
                  <thead>
                    <tr style={{ fontSize: "11px" }}>
                      <th>ລຳດັບ</th>
                      <th>ຊື່ ແລະ ນາມສະກຸນ</th>
                      <th>ອາຍຸ</th>
                      <th>ທີ່ຢູ່</th>
                      <th>ເບີໂທ</th>
                      <th>ເລກບັດປະຈຳຕົວ</th>
                      <th>ເລກທີໃບຂັບຂີ່</th>
                      <th>ສິດການນຳໃຊ້</th>
                      <th>ສະຖານະ</th>
                      <th>ຊື່ບັນຊີທະນາຄານ</th>
                      <th>ເລກບັນຊີທະນາຄານ</th>
                      <th>ວັນທີເຂົ້າ</th>
                      <th>ວັນທີອອກ</th>
                      <th>ວັນທີສ້າງ</th>
                      <th>ສ້າງໂດຍ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items?.map((value: any, index: number) => (
                      <tr key={index} style={{ fontSize: "11px" }}>
                        <td className="no">{index + 1}</td>
                        <td>
                          {value?.first_name || "---"} {value?.last_name || "---"}
                        </td>
                        <td>{value?.age || "---"}</td>
                        <td>
                          {value?.address || "---"} {value?.center?.centerName || "---"}
                        </td>
                        <td>{value?.phoneNumber || 0}</td>
                        <td>{value?.cardID || "---"}</td>
                        <td>{value?.passID || "---"}</td>
                        <td>
                          {value?.role === "SUPER_ADMIN" ? (
                            <p>ຜູ້ດູແລລະບົບ</p>
                          ) : value?.role === "ADMIN" ? (
                            <p>ຜູ້ຈັດຖ້ຽວ</p>
                          ) : value?.role === "USERS" ? (
                            <p>ຜູ້ໃຊ້</p>
                          ) : value?.role === "DRIVER_OWN" ? (
                            <p>ໂຊເຟີ້ສ່ວນບຸກຄົນ</p>
                          ) : value?.role === "AUDITOR" ? (
                            <p>ກວດສອບຖ້ຽວລົດ</p>
                          ) : value?.role === "ACCOUNTANT" ? (
                            <p>ບັນຊີ</p>
                          ) : (
                            <p>ໂຊເຟີ້ບໍລິສັດ</p>
                          )}
                        </td>
                        <td>{value?.status === "ACTIVED" ? <p>ກຳລັງໃຊ້ງານ</p> : <p>ອອກແລ້ວ</p>}</td>
                        <td>{value?.account_bank_name || "----"}</td>
                        <td>ເລກບັນຊີ:{value?.account_bank_number || "----"}</td>
                        <td>ວັນທີເຂົ້າ: {value?.startDate ? formatDate(value?.startDate) : "----"}</td>

                        <td>ວັນທີອອກ:{value?.endDate ? formatDate(value?.endDate) : "----"}</td>
                        <td>{value?.createdAt ? formatDateTime(value?.createdAt) : "----"}</td>
                        <td>
                          {value?.createdBy?.first_name}
                          {value?.createdBy?.last_name || "----"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="row">
                  <div className="col-sm-2"></div>
                  <div className="col-sm-8 d-flex justify-content-between aligns-center">
                    <div>ຜູ້ອຳນວຍການ</div>
                    <div>ຜູ້ຮັບຜິດຊອບ</div>
                  </div>
                  <div className="col-sm-2"></div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    </React.Fragment>
  );
}
