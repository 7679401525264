import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { Calculator, Gender, RootVariable, checkUserRole, currency, formatDateTime } from "../../utils";
import { useSearchParams } from "react-router-dom";
import SelectNumberCar from "../../utils/components/SelectNumberCar";
import TableData from "../../utils/components/TableData";
import { useLazyQuery } from "@apollo/client";
import Schema from "./Schema";
import SearchDateRange from "../../utils/components/SearchDateRange";
import moment from "moment";
import { ExportTable } from "./component";

export default function ModifyPriceRoute() {
  const [items, setItems] = useState<any[]>();
  const [total, setTotal] = useState(0);
  const [numberRow, setNumberRow] = useState(RootVariable.limit);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [vehicle, setVehicle] = useState<any>();
  const [isDay, setIsDay] = useState<any>();
  const [SelectCheckRoute, setSelectCheckRoute] = useState<any>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [dateBetween, setDateBetween] = useState<string[]>([]);
  const [FetchData, { data, loading }] = useLazyQuery(Schema.HISTORY_SHIPPING_ROUTE_DELETE, { fetchPolicy: "cache-and-network" });

  useEffect(() => {
    if (dateBetween?.length !== 2) return;
    FetchData({
      variables: {
        where: {
          isDay: isDay ? isDay : undefined,
          checkRoute: SelectCheckRoute ? SelectCheckRoute : undefined,
          vehicleID: parseInt(vehicle) ? parseInt(vehicle) : undefined,
          createdDateBetween: [dateBetween[0], dateBetween[1]],
        },
        limit: searchValue ? 0 : numberRow,
        skip: searchValue ? 0 : numberRow * (pageNumber - 1),
      },
    });
  }, [FetchData, searchValue, isDay, SelectCheckRoute, numberRow, pageNumber, vehicle, dateBetween]);

  useEffect(() => {
    if (loading) {
      setItems([]);
      setTotal(0);
      return;
    }
    setItems(data?.shippingRouteDeletes?.data || []);
    setTotal(data?.shippingRouteDeletes?.total || 0);
  }, [data, loading]);

  useEffect(() => {
    const _page = searchParams.get("page");
    const _row = searchParams.get("row");
    const _isDay = searchParams.get("isDay");
    const _vehicle = searchParams.get("vehicle");
    const _checkRoute = searchParams.get("checkRoute");

    const decodeIsDay = _isDay ? atob(_isDay) : undefined;
    const decodeVehicle = _vehicle ? atob(_vehicle) : undefined;
    const decodeCheckRoute = _checkRoute ? atob(_checkRoute) : undefined;

    setIsDay(decodeIsDay ? decodeIsDay : undefined);
    setVehicle(decodeVehicle ? decodeVehicle : undefined);
    setSelectCheckRoute(decodeCheckRoute ? decodeCheckRoute : undefined);

    setPageNumber(_page ? parseInt(_page) : 1);
    setNumberRow(_row ? parseInt(_row) : RootVariable.limit);
  }, [searchParams]);

  useEffect(() => {
    const _page = searchParams.get("page");
    const _row = searchParams.get("row");
    setPageNumber(_page ? parseInt(_page) : 1);
    setNumberRow(_row ? parseInt(_row) : RootVariable.limit);
    const _startDate = searchParams.get("startDate");
    const _endDate = searchParams.get("endDate");
    if (_startDate && _endDate) setDateBetween([_startDate, _endDate]);
    else setDateBetween([moment().subtract(30, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")]);
  }, [searchParams]);

  return (
    <>
      <div className="page-header">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">ໜ້າຫຼັກ</li>
          <li className="breadcrumb-item">ຖັງຂີ້ເຫຍື້ອຖ້ຽວລົດ</li>
        </ol>
      </div>

      <div className="main-container mt-3">
        <div className="card">
          <div className="card-body">
            <div className="row blog p-2">
              <div className="col-md-12">
                <div className="text-center d-flex justify-content-between align-items-center">
                  <h3 style={{ textAlign: "left" }}>ລາຍການຂໍ້ມູນຖ້ຽວລົດທີ່ຖືກລົບ</h3>
                  <div>{checkUserRole(["SUPER_ADMIN", "ACCOUNTANT", "AUDITOR"]) ? <ExportTable loading={loading} data={data} /> : null}</div>
                </div>

                <hr />
                <div className="row">
                  <div className="col-sm-6">
                    <SearchDateRange />
                  </div>
                  <div className="col-md-2">
                    <label>ເລືອກຕາມກະອອກລົດ</label>
                    <select
                      className="form-control"
                      onChange={(e: any) => {
                        searchParams.set("isDay", btoa(e.target.value));
                        setSearchParams(searchParams);
                      }}
                    >
                      <option value="">ທັງໝົດ</option>
                      <option value="0">ພາກເຊົ້າ</option>
                      <option value="1">ພາກເເລງ</option>
                    </select>
                  </div>

                  <div className="col-sm-2">
                    <label htmlFor="#">ເລືອກຕາມເບີລົດ</label>
                    <SelectNumberCar
                      onChange={(option: any) => {
                        searchParams.set("vehicle", btoa(option.value));
                        setSearchParams(searchParams);
                      }}
                    />
                  </div>

                  <div className="col-sm-2">
                    <label htmlFor="#">ປະເພດສາຍ</label>
                    <select
                      className="form-control"
                      onChange={(e: any) => {
                        searchParams.set("checkRoute", btoa(e.target.value));
                        setSearchParams(searchParams);
                      }}
                    >
                      <option value="">ທັງໝົດ</option>
                      <option value="IN">ສາຍໃນ</option>
                      <option value="OUT">ສາຍນອກ</option>
                      <option value="SPARE">ສາຍສຳຮອງ</option>
                    </select>
                  </div>
                </div>
              </div>
              &nbsp;&nbsp;
              <TableData
                total={total}
                loading={loading}
                pageNumber={pageNumber}
                numberRow={numberRow}
                onSearchText={(val) => {
                  setSearchValue(val || "");
                }}
                header={
                  <tr>
                    <th className="text-nowrap text-center">ລຳດັບ</th>
                    <th className="text-nowrap text-center">ເບີລົດ</th>
                    <th className="text-nowrap text-center">ສາຍທີ່ເເລ່ນ</th>
                    <th className="text-nowrap text-center">ລາຄາຕໍ່ຖ້ຽວໃນສາຍ</th>
                    <th className="text-nowrap text-center">ປະເພດສາຍ</th>
                    <th className="text-nowrap text-center">ກະອອກລົດ</th>
                    <th className="text-nowrap text-center">ປະເພດຖ້ຽວ</th>
                    <th className="text-nowrap text-center">ເວລາອອກຕົ້ນທາງ</th>
                    <th className="text-nowrap text-center">ເວລາຮອດປາຍທາງ</th>
                    <th className="text-nowrap text-center">ໃຊ້ເວລາເດີນທາງ</th>
                    <th className="text-nowrap text-center">ຢືນຢັນອອກລົດ</th>
                    <th className="text-nowrap text-center">ຢືນຢັນຮອດປາຍທາງ</th>
                    <th className="text-nowrap text-center">ວັນທີຈັດຖ້ຽວ</th>
                    <th className="text-nowrap text-center">ເຫດຜົນ</th>
                    <th className="text-nowrap text-center">ຜູ້ຈັດຖ້ຽວ</th>
                    <th className="text-nowrap text-center">ລົບໂດຍ</th>
                    <th className="text-nowrap text-center">ວັນທີ່ລົບ</th>
                  </tr>
                }
                body={
                  <>
                    {items?.map((value, index) => (
                      <tr className={value?.isDif === "1" ? "selectRow" : ""} key={index}>
                        <td className="text-center" style={{ backgroundColor: "#F97070" }}>
                          {index + 1}
                        </td>
                        <td className="text-nowrap" style={{ backgroundColor: "#F97070" }}>
                          {value?.vehicleID?.vehicleName || "----"}
                        </td>
                        <td className="text-nowrap" style={{ backgroundColor: "#F97070" }}>
                          {value?.routeLocationID?.title || "----"}
                        </td>
                        <td className="text-center" style={{ backgroundColor: "#F97070" }}>
                          {currency(value?.amountValue || "----")} ກີບ
                        </td>
                        <td className="text-nowrap" style={{ backgroundColor: "#F97070" }}>
                          {value?.checkRoute === "IN" ? "ສາຍໃນ" : value?.checkRoute === "OUT" ? "ສາຍນອກ" : "ສາຍສຳຮອງ"}
                        </td>
                        <td className="text-nowrap" style={{ backgroundColor: "#F97070" }}>
                          {value?.isDay === "0" ? "ພາກເຊົ້າ" : "ພາກເເລງ"}
                        </td>
                        <td className="text-nowrap" style={{ backgroundColor: "#F97070" }}>
                          {value?.isDif === "1" ? "ຖ້ຽວເສີມ" : value?.isDif === "0" ? "ຖ້ຽວປົກກະຕິ" : "ຖ້ຽວເສີມພິເສດ"}
                        </td>
                        <td className="text-nowrap text-center" style={{ backgroundColor: "#F97070" }}>
                          {value?.startTime ? formatDateTime(value.startTime) : "----"}
                        </td>
                        <td className="text-nowrap text-center" style={{ backgroundColor: "#F97070" }}>
                          {value?.endTime ? formatDateTime(value.endTime) : "----"}
                        </td>
                        <td className="text-nowrap text-center" style={{ backgroundColor: "#F97070" }}>
                          {value?.startTime ? Calculator(value?.startTime, value?.endTime) : "-----"}
                        </td>
                        <td className="text-nowrap" style={{ backgroundColor: "#F97070" }}>
                          {" "}
                          {value?.acceptStatus === "UNACCEPTED" ? (
                            <>
                              <div className="btn btn-danger disable-cursor btn-sm">
                                {" "}
                                <Spinner animation="border" size="sm" variant="warning" /> ລໍຖ້າຢືນຢັນອອກລົດ..
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="btn btn-success disable-cursor btn-sm">
                                <i className="fas fa-check-circle"></i> ຢືນຢັນອອກລົດແລ້ວ
                              </div>
                            </>
                          )}
                        </td>
                        <td style={{ textAlign: "center", backgroundColor: "#F97070" }} className="text-nowrap">
                          {value?.acceptStatus === "UNACCEPTED" ? (
                            "----"
                          ) : (
                            <>
                              {value?.newLocation === "NO" ? (
                                <div className="btn btn-danger disable-cursor btn-sm">
                                  <Spinner animation="border" size="sm" variant="warning" /> ກຳລັງອອກເດີນທາງ
                                </div>
                              ) : (
                                <div className="btn btn-success disable-cursor btn-sm">
                                  <i className="fas fa-check-circle"></i> ຮອດປາຍທາງເເລ້ວ
                                </div>
                              )}
                            </>
                          )}
                        </td>
                        <td className="text-nowrap" style={{ backgroundColor: "#F97070" }}>
                          {formatDateTime(value?.createdAt || "----")}
                        </td>
                        <td className="text-nowrap" style={{ backgroundColor: "#F97070" }}>
                          {value?.difNote || "----"}
                        </td>
                        <td className="text-nowrap" style={{ backgroundColor: "#F97070" }}>
                          {value?.createdBy?.gender ? Gender(value?.createdBy?.gender) : ""}&nbsp;{value?.createdBy?.first_name || "----"}&nbsp;
                          {value?.createdBy?.last_name || "----"}
                        </td>
                        <td className="text-nowrap" style={{ backgroundColor: "#F97070" }}>
                          {value?.deleteBy?.gender ? Gender(value?.deleteBy?.gender) : ""}&nbsp;{value?.deleteBy?.first_name || "----"}&nbsp;
                          {value?.deleteBy?.last_name || "----"}
                        </td>
                        <td className="text-nowrap" style={{ backgroundColor: "#F97070" }}>
                          {formatDateTime(value?.deleteDate || "----")}
                        </td>
                      </tr>
                    ))}
                  </>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
