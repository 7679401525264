import { useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { getStaffLogin } from "..";

export default function ProfileInfo() {
  const [show, setShow] = useState(false);
  const user = getStaffLogin();


  return (
    <>
      <Dropdown.Item onClick={() => setShow(true)}>
        <i className="fas fa-info-circle" />
        ຂໍ້ມູນໂປຣໄຟລ໌
      </Dropdown.Item>

      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="fas fa-info-circle" />
            ຂໍ້ມູນໂປຣໄຟລ໌
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table">
            <tbody>
              <tr>
                <td className="text-nowrap" style={{ width: "1%" }}>
                  <i className="fas fa-user" />
                  ຊື່:
                </td>
                <td>{user?.first_name}</td>
              </tr>
              <tr>
                <td className="text-nowrap" style={{ width: "1%" }}>
                  <i className="fas fa-user" />
                  ນາມສະກຸນ:
                </td>
                <td>{user?.last_name}</td>
              </tr>
              <tr>
                <td className="text-nowrap" style={{ width: "1%" }}>
                  <i className="fas fa-phone" />
                  ເບີໂທລະສັບ:
                </td>
                <td>{user?.phoneNumber}</td>
              </tr>
              <tr>
                <td className="text-nowrap" style={{ width: "1%" }}>
                  <i className="fas fa-user-secret" />
                  ສິດການໃຊ້ງານ:
                </td>
                <td>{user?.role}</td>
              </tr>
              <tr>
                <td className="text-nowrap" style={{ width: "1%" }}>
                  <i className="fa-solid fa-building" /> ບ່ອນປະຈຳການ:
                </td>
                <td>{user?.center?.centerName}</td>
              </tr>
              <tr>
                <td className="text-nowrap" style={{ width: "1%" }}>
                  <i className="fas fa-address-book" />
                  ຂໍ້ມູນຕິດຕໍ່:
                </td>
                <td>{user?.phoneNumber}</td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    </>
  );
}
